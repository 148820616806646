<template>
  <div class="trust-logo-wrap">
    <carousel
      v-if="$local.clients.length"
      :autoplay="true"
      :nav="false"
      :loop="true"
      :dots="false"
      :autoWidth="true"
      :center="true"
      :margin="40"
      :slideTransition="'linear'"
      :autoplaySpeed="4000"
    >
      <div
        v-for="client in $local.clients.filter((c) => !c.hide)"
        :key="client._id"
        class="trust-logo"
      >
        <img :src="client.logo.imageURL" :alt="client.name" />
      </div>
    </carousel>
  </div>
</template>
<script>
import carousel from "owl-carousel-vuejs";

export default {
  name: "logoscar",
  components: { carousel },
};
</script>

<style>
.widthModify {
  width: 300px;
}
.trust-logo-wrap {
  height: 70px;
}
</style>
