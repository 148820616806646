<template>
  <div style="height: 120px" class="box">
    <div class="box__input">
      <input class="box__file" @change="changeImage" fileUpload type="file" />
      <img
        height="140"
        v-if="val && val.imageURL && isImage()"
        class="uploadedImage"
        :class="uploading ? 'uploading' : ''"
        :src="val.imageURL"
        alt
      />
      <div v-else-if="val.name">
        <svg
          width="60"
          viewBox="0 0 494 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="svg">
              <path
                d="M485.142,80.275 L417.066,9.301 C413.563,5.65 409.19,2.965 404.429,1.444 L404.429,92.13 L492.162,92.13 C490.784,87.734 488.408,83.681 485.142,80.275 Z"
                id="Path"
                fill="#868686"
                fill-rule="nonzero"
              ></path>
              <path
                d="M389.413,122.148 C381.127,122.148 374.402,115.433 374.402,107.137 C374.402,107.067 374.412,106.997 374.422,106.917 C374.412,106.847 374.402,106.777 374.402,106.697 L374.402,0 L125.882,0 C109.23,0 95.68,13.55 95.68,30.212 L95.68,99.488 L244.721,99.488 C273.231,99.488 296.437,122.682 296.437,151.204 L296.437,250.473 C296.437,278.983 273.23,302.189 244.721,302.189 L95.68,302.189 L95.68,481.798 C95.68,498.45 109.231,512 125.883,512 L463.347,512 C479.999,512 493.549,498.45 493.549,481.798 L493.549,122.148 L389.413,122.148 Z"
                id="Path"
                fill="#868686"
                fill-rule="nonzero"
              ></path>
              <path
                d="M229.287,124.74 L39.1,124.74 C17.785,124.74 0.452,142.073 0.452,163.388 L0.452,237.572 C0.452,258.877 17.785,276.22 39.1,276.22 L229.287,276.22 C250.592,276.22 267.935,258.877 267.935,237.572 L267.935,163.388 C267.935,142.073 250.593,124.74 229.287,124.74 Z"
                id="Path"
                fill="#868686"
                fill-rule="nonzero"
              ></path>
              <text
                id="SVG"
                font-family="ArialMT, Arial"
                font-size="80"
                font-weight="normal"
                fill="#FFFFFF"
              >
                <tspan x="40" y="224">{{ val.extension }}</tspan>
              </text>
            </g>
          </g>
        </svg>
        <p style="font-size: small; margin-top: -5px">{{ val.name }}</p>
      </div>
      <div v-else>
        <svg
          class="box__icon"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="43"
          viewBox="0 0 50 43"
        >
          <path
            d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"
          />
        </svg>

        <label>
          <strong>{{ placeolder || "Upload Image Here" }}</strong>
        </label>
      </div>
      <div v-if="uploading">Uploading...</div>
      <div class="box__success">Done!</div>
      <div class="box__error">
        Error!
        <span></span>.
      </div>
    </div>
    <div v-if="uploading" class="loader">
      <div class="bar"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageRef",
  props: {
    placeolder: String,
    slug: String,
    collection: String,
    item: String,
    value: Object,
    nestedCollection: String,
    nestedItem: String,
    db: Object,
  },
  data() {
    return {
      uploading: false,
      val: this.value,
      image: {
        selectedImage: {},
      },
    };
  },
  watch: {
    value() {
      // console.log(this.value);
      this.val = this.value || {};
    },
  },
  mounted() {
    if (!Object.keys(this.val) && this.item) {
      this.fetchCurrentImage();
    }
  },
  methods: {
    isImage() {
      if (this.val.extension.match(/jpg|jpeg|png/)) return true;
      return false;
    },
    async fetchCurrentImage() {
      let { data } = await this.db
        .collection(this.collection)
        .item(this.item)
        .collection(this.nestedCollection)
        .item(this.nestedItem)
        .get();
      this.$emit("input", data[this.slug]);
      this.val = data[this.slug];
    },
    async uploadNewfile() {
      if (
        this.image.selectedImage &&
        this.collection &&
        this.slug &&
        this.item
      ) {
        this.uploading = true;
        try {
          await this.db
            .collection(this.collection)
            .item(this.item)
            .collection(this.nestedCollection)
            .item(this.nestedItem)
            .uploadImage(this.slug, this.image);
          await this.fetchCurrentImage();
          this.uploading = false;
        } catch (err) {
          this.uploading = false;
        }
      }
    },
    changeImage(event) {
      let file = event.target.files[0];

      if (file) {
        this.image.selectedImage = file;
        this.image.name = file.name;
        this.image.extension = file.name.split(".")[1];
        this.val = {};
        setTimeout(() => {
          this.val = this.image;
        }, 100);
        this.$emit("input", this.image);

        if (this.item) this.uploadNewfile();
      }
    },
  },
};
</script>

<style scoped>
.eraseimg {
  color: red;
  margin-top: -23px;
  font-size: 15px;
}

@keyframes loader-animation {
  0% {
    width: 0%;
    left: 0%;
    right: 0%;
  }
  40% {
    width: 100%;
    left: 100%;
    right: 0%;
  }
  85% {
    width: 0%;
    left: 0%;
    right: 100%;
  }
  100% {
    width: 0%;
    right: 0%;
    left: 0%;
  }
}
.loader {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.loader .bar {
  position: relative;
  height: 5px;
  background-color: #ff7272;
  animation-name: loader-animation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.imageUploader {
  padding: 10px;
  background-color: white;
  max-width: 500px;
  height: 100;
  text-align: center;
}

.uploadedImage {
  max-height: 200px;
  max-width: 100%;
  object-fit: scale-down;
}

.uploadedImage.uploading {
  opacity: 0.6;
}

input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}
.box {
  margin-bottom: 10px;
  overflow: hidden;
  outline: 1px dashed #a7a7a7;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}
.box {
  font-size: 1.25rem;
  position: relative;
  padding: 30px 20px;
  margin: 0;
  text-align: center;
}
.box__icon {
  width: 100%;
  height: 40px;
  fill: #bbbbbb;
  display: block;
  margin-bottom: 10px;
}
.box__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.box label {
  max-width: 80%;
  color: #bbbbbb;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}
</style>
