<template>
  <div class="input-wrapper">
    <label :class="{ hasFocus: focus || val }">
      {{ label }}{{ required ? "*" : "" }}
    </label>
    <input @input="handleInput" @blur="validateForm" @focus="focus = true" @focusout="focus = false"
      :type="type || 'text'" :class="className" v-model="val" />
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
import ValidationTypeEnum from "@/views/Contact.vue";
export default {
  name: "input-field",
  props: {
    className: String,
    label: String,
    value: String,
    required: Boolean,
    type: String,
    validationType: ValidationTypeEnum,
    errorText: String
  },

  methods: {
    handleInput() {
      this.$emit("input", this.val);
      if (this.required)
        this.validateForm();
    },
    validateForm() {
      if (!this.required)
        return;

      
      if (!this.val) {
        let labelForValidation = this.label[0] + this.label.toLowerCase().slice(1)
        this.error = `${labelForValidation} is required`;
      }
      else if (!this.validationType.test(this.val)) {
        this.error = this.errorText
      } else {
        this.error = "";
      }
      const retValue = {
        label: this.label,
        validation: this.error === "",
      }
      this.$emit("error-message", retValue);
    },
    clearInput() {
      this.val = "";
    }
  },
  data() {
    return {
      focus: false,
      val: this.value,
      error: '',
    };
  },
  mounted() {
    const retValue = {
      label: this.label,
      validation: false,
    }
    this.$emit("error-message", retValue);
  }

};
</script>

<style>
.input-wrapper {
  position: relative;
  display: inline-block;

}

.input-wrapper label,
.input-wrapper input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.input-wrapper label {
  user-select: none;
  pointer-events: none;
  z-index: 2;
  transition: all ease 0.2s;
  top: 10px;
  left: 15px;
  opacity: 0.6;
}

.input-wrapper label.hasFocus {
  font-size: x-small;
  opacity: 1;
  top: 4px;
}

input {
  padding-left: 15px;
  font-size: 16px;
  border: none;
}


.error {
  color: #f37975;
  background-color: transparent;
  padding-top: 28px;
  margin-left: -15px;
  font-size: 12px;
  display: inline-block;
  border: none;
  text-align: left;
  width: 100%;
  height: 100%;

}

@media (max-width: 912px) {
  .error {
    font-size: 12px;
    padding-top: 27px;
    margin-bottom: 35px;
    /*  white-space: nowrap; */
  }

  .input-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
  }
}
</style>
