<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="gray-back top main-container">
      <div class="tech-top-wrap">
        <h1 class="txt54 mobile-only mobile-title">{{ bobPage.title }}</h1>
        <div class="tech-top-img">
          <img v-if="bobPage.cover && bobPage.cover.imageURL" :src="bobPage.cover.imageURL" />
          <img v-else src="../assets/tech8.jpg" />
        </div>
        <div class='tech-top-frame light-blue-line'>
          <h1 class="txt54 desktop-only">{{ bobPage.title }}</h1>
          <div class="lighter" v-html="bobPage.blurb"></div>
          <br />
          <a class="btn lowcase" href="#requestdemo">Request a Demo</a>
        </div>
      </div>
      <div class="tech-top-wrap tech-bottom-wrap">
        <div class="tech-bottom-img">
          <h2>What is Bob?</h2>
          Please see the Instructions and demo.
        </div>

        <videoEmbed class="know-page-img video video-shadow" :src="bobPage.video"></videoEmbed>

      </div>

      <div id="requestdemo" class="demo-title">
        <img src="../assets/bobIcon.svg" />
        <h1>Request a Demo / Trial</h1>
      </div>


      <div class="form-wrap">
        <form class="form" @submit.prevent="sendForm" v-if="formStatus != 'success'">
          <div class="form-bg">
            <div class="form-side-by">
              <input-field class="input" label="Full Name" :required="true" v-model="form.fullName"
                @form-errors="handleFormErrors" @error-message="onError" :validationType="ValidationTypeEnum.AlphaBet"
                errorText="First name can only contain letters" />
              <input-field class="input" type="email" label="Work Email Address" :required="true" v-model="form.email"
                @error-message="onError" :validationType="ValidationTypeEnum.Email" errorText="Invalid Email address" />
            </div>
            <div class="form-side-by">
              <input-field class="input" label="Company" :required="true" v-model="form.company" @error-message="onError"
                :validationType="ValidationTypeEnum.Required" errorText="Company is required" />
              <input-field class="input" label="Job Title" :required="true" v-model="form.jobtitle"
                @error-message="onError" :validationType="ValidationTypeEnum.Required"
                errorText="Job Title is required" />
            </div>
            <div class="form-side-by button-container">
              <select class="input error-margin" name="cloud" id="cloud" v-model="form.cloud">
                <option :value="null" disabled>Cloud *</option>
                <option v-for="c in this.clouds" :key="c" :value="c">{{ c }}</option>
              </select>
              <select class="input error-margin" name="requestType" id="requestType" v-model="form.requestType">
                <option :value="null" disabled>Request *</option>
                <option key="Demo" value="Demo"> Demo </option>
                <option key="Trial" value="Trial"> Trial </option>
              </select>
            </div>
            <div class="center mt30px">
              <input :disabled="formStatus === 'sending' || this.hasFalseProp || cloudNotSelected() || requestNotSelected()" type="submit"
                value="Submit" class="btn forum lowcase" />
            </div>
            <div class="error" v-if="formStatus == 'fail'">
              <p>An error occurred while submitting the form</p>
            </div>
          </div>
        </form>
        <div class="form-bg tnx" v-if="formStatus == 'success'">
          <p>
            <span class="new-line">Thank you!</span> Your submission has
            been received!
          </p>
        </div>
      </div>
      <div class="center">
        <router-link :to="buildPath('solutions/cloud-ai')" type="button" class="btn lowcase mb60px">More AI Solutions >
        </router-link>
      </div>

    </div>


    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import videoEmbed from "@/components/VideoEmbed.vue";
import axios from 'axios';
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.bobPage.metaTitle || this.bobPage.title,
        description: this.bobPage.metaDescription || this.bobPage.subTitle,
        image: this.bobPage.cover.imageURL,
      }),
    };
  },
  components: {
    Topnav,
    Botfooter,
    videoEmbed
  },
  computed: {
    bobPage() {
      return this.$local.bobTheBot;
    },
  },
  methods: {
    buildPath,
    onError(errorObj) {
      this.formErrors[errorObj.label] = errorObj.validation;
      this.setFalseProp();
    },
    setFalseProp() { // also called when the input is modified
      this.hasFalseProp = Object.values(this.formErrors).includes(false);
    },
    cloudNotSelected() {
      return this.form.cloud == null;
    },
    requestNotSelected() {
      return this.form.requestType == null;
    },
    async sendForm() {
      try {
        this.setFalseProp();
        if (this.hasFalseProp) {
          return;
        }
        this.formStatus = "sending";
        await axios.post(
          '/.netlify/functions/submitBobDetails',
          this.form
        );
        this.formStatus = "success";
      } catch (err) {
        console.log(err);
        this.formStatus = "fail";
      }
    },
  },
  mounted() {
  },
  data: () => ({
    formStatus: "",
    form: {
      fullName: "",
      email: "",
      company: "",
      jobtitle: "",
      cloud: null,
      requestType: null
    },
    ValidationTypeEnum: {
      AlphaBet: /^[a-zA-Z ]+$/,
      Email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      Phone: /^(\+)?(?:[0-9-()/.\s?]){6,15}[0-9]{1}$/,
      Required: /.+/
    },
    clouds: [
      'Google Cloud',
      'AWS',
      'Microsoft Azure',
      'Alibaba Cloud',
      'Other'
    ],
    hasFalseProp: false,
    formErrors: {},
  }),

};
</script>

<style scoped>
.form-wrap {
  margin-bottom: 40px;
}

.tech-top-wrap {
  margin-bottom: 120px;
}

.tech-bottom-img {
  margin: 60px -80px 60px 0;
  position: relative;
  z-index: 2;
  flex-basis: 46%;
}

#requestdemo {
  scroll-margin-top: 100px;
}

.tech-bottom-wrap {
  justify-content: space-around;
  margin-top: 100px;
}

.input-wrapper.input label {
  top: 16px;
}

.video {
  margin-right: 34px;
}

.video-shadow {
  box-shadow: rgb(0, 219, 232) 25px 25px;
  width: 675px;
}

.form,
.tnx {
  max-width: 675px;
  margin: auto;
}

.tnx {
  margin-top: 30px;
}

.demo-title {
  display: flex;
  margin: auto;
  margin-top: 20px;
  gap: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.button-container input {
  height: 54px;
  margin: 15px;
}

.demo-title img {
  max-width: 120px;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}


@media screen and (max-width: 479px) {

  .tech-top-frame.light-blue-line {
    border: 0;
  }

  .mobile-title {
    margin: 20px auto;
    border: 10px solid var(--light-blue);
    padding: 14px;
    width: 90;
    text-align: center;
  }

  .tech-top-frame {
    transform: translateY(0);
  }

  .tech-top-wrap {
    margin-bottom: 50px;
  }

  .tech-top-img {
    margin: 40px auto;
    flex-basis: 90%;
  }

  .tech-top-img img {
    object-fit: cover;
    width: 100%;
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .tech-top-frame {
    text-align: center;
  }

  .tech-bottom-wrap {
    margin-top: 20px;
  }

  .demo-title {
    flex-direction: column;
    align-items: center;
    gap:0;
    margin-top: 100px;
  }

  .form-bg {
    padding: 20px 0;
    margin-left: 0;
    margin-right: 0;
  }

  .button-container {
    flex-direction: column;
  }

  .button-container input {
    margin-bottom: 0;
    margin-top: 0;
    margin: auto;
  }

  .btn.forum {
    width: 90%;
  }

  .form .input-wrapper,
  .form select {
    display: flex;
    margin-bottom: 25px;
  }

  .input {
    width: 90%;
    margin: auto;
    padding: 30px;
  }

  select.input {
    padding: 0 0 0 10px;
  }

}

@media screen and (max-width: 1200px) {
  .tech-bottom-img {
    margin: 60px auto 60px auto;
    text-align: center;
  }
}
</style>
