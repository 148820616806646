<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="top-loc-frame">
      <div class="loc-top-frame-txt">
        <h1 class="txt54">{{ branche.name }}</h1>
        <p class="txt26 lighter">{{ branche.blurb }}</p>
      </div>
      <img :src="branche.mainImage.imageURL" class="top-loc-img" />
    </div>
    <div class="team-loc-sec">
      <div class="team-img-wrap">
        <img
          class="team-img"
          v-for="teamImage in branche.teamImages"
          :key="teamImage.imageURL"
          :src="teamImage.imageURL"
          :alt="teamImage.altText"
        />

        <div class="team-frame"></div>
      </div>
      <div class="team-txt-wrap">
        <h2 class="txt54 team-title">Our {{ branche.city }} Team</h2>
        <div class="team-small-txt-wrap">
          <div class="lighter" v-html="branche.aboutTeam"></div>
        </div>
      </div>
    </div>

    <logoscar></logoscar>

    <div class="loc-case-sec">
      <carousel
        :autoplay="true"
        :nav="true"
        :dots="false"
        :slideTransition="'fade'"
        :autoplaySpeed="4000"
        :items="1"
        :navText="navText"
      >
        <div
          class="point-wrap list"
          v-for="client in $local.customers"
          :key="client._id"
        >
          <div class="point-img-wrap">
            <img
              :src="client.logo.imageURL"
              :alt="client.name"
              class="point-img"
            />
            <div class="point-frame"></div>
          </div>
          <div class="point-txt-wrap">
            <h3 class="point-head">{{ client.name }}</h3>
            <div class="lighter nomarg" v-html="client.blurb"></div>
            <router-link
              v-if="client.tech"
              :to="buildPath(`/${client.tech}/customer-story/${client.slug}`)"
              class="read-more"
            >
              Read More
            </router-link>
          </div>
        </div>
      </carousel>

      <!-- <div class="arrows-wrap"><img src="../assets/arrow-left.svg" /><img src="../assets/arrow-right.svg" /></div> -->
    </div>

    <div class="events-sec">
      <div class="event-sec-line"></div>
      <h4 class="txt54 center mb60px">Upcoming Events</h4>
      <div class="events-wrap">
        <router-link
          v-for="event in futureEvents"
          :key="event._id"
          :to="buildPath(`/event/${event.slug}`)"
          class="event-box"
          ><img
            :src="event.thumbnail.imageURL || event.cover.imageURL"
            class="event-box-img"
          />
          <div class="event-box-txt-wrap">
            <div class="event-box-date">
              <p class="event-box-day">{{ new Date(event.date).getDate() }}</p>
              <p class="event-box-mon">
                {{
                  new Date(event.date).toLocaleString("en-US", {
                    month: "short",
                  })
                }}
              </p>
              <p class="event-box-year">
                {{ new Date(event.date).getFullYear() }}
              </p>
            </div>
            <p class="event-box-item">{{ event.title }}</p>
            <div class="event-info-box">
              <p>{{ event.blurb }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import carousel from "sh-v-owl-carousel";
import arrowLeft from "../assets/arrow-left.svg";
import arrowRight from "../assets/arrow-right.svg";
import Logoscar from "@/components/Logoscar.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  methods : {
    buildPath
  },
  components: {
    Topnav,
    Botfooter,
    carousel,
    Logoscar,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.branche.metaTitle,
        description : this.branche.metaDescription
      })
    };
  },  
  data: () => ({
    navText: [
      `<img class="arrow-owl" src="${arrowLeft}" />`,
      `<img class="arrow-owl" src="${arrowRight}" />`,
    ],
  }),
  computed: {
    futureEvents() {
      return this.$local.events.filter((e) => new Date(e.date) > new Date());
    },
    branche() {
      const { id } = this.$route.params;
      return this.$local.branches?.find((c) => c._id == id) || {};
    },
  },
  name: "International Location",
};
</script>
