<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="gray-back top">
      <div class="tech-top-wrap">
        <div class="tech-top-img">
          <img v-if="solution.cover && solution.cover.imageURL" :src="solution.cover.imageURL" />
          <img v-else src="../assets/tech8.jpg" />
        </div>
        <div :class="solution.title == 'Delivery-Professional Services' ? 'tech-top-frame blue-line' : 'tech-top-frame'">
          <h1 class="txt45">{{ solution.title }}</h1>
          <div class="lighter">{{ solution.subtitle }}</div>
        </div>

        <div style="margin-top: 40px" class="know-page-content" v-html="solution.p2"></div>
        <div class="solution-content-richtxt" v-html="solution.body"></div>

        <div class="solutions-schedule-call-wrapper">
          <h2 v-if="solution.footerParagraph" v-html="solution.body"></h2>
          <a v-if="solution.footerButton" class="btn lowcase" href="/contact"> {{ solution.footerButton }}</a>
        </div>

      </div>
    </div>

    <div class="articles-section">
      <h3 class="point-head center">Our AWS DevOps Insights</h3>
      <div class="article-wrapper">
        <div @click="redirectToArticle(article)" class="article-container" v-for="article in solution.articles"
          :key="article.id">
          <img :src="article.thumbnail.imageURL" alt="">
          <div>{{ article.title }}</div>
          <a v-if="article.articleURL.includes('http')" :href="article.articleURL" class="articles-read-more">Read More
            >></a>
          <router-link v-else :to="buildPath(`insights/${article.articleURL}`)" class="articles-read-more">
            Read More >>
          </router-link>
        </div>
      </div>
    </div>

    <h3 class="point-head center">Customer Stories</h3>

    <carousel :autoplay="true" :nav="true" :dots="false" :slideTransition="'fade'" :smartSpeed="4000" :items="1"
      :navText="navText" :loop="true">
      <div class="point-wrap list" v-for="client in $local.customers.filter(
        (c) => c.technology
          && c.technology.value === 'AWS'
          && !c.hide
          && c.servicesUsed.length > 0
          && c.servicesUsed.some(item => item.value === 'DevOps')
      )" :key="client._id">
        <div class="point-img-wrap">
          <img :src="client.logo.imageURL" :alt="client.name" class="point-img" />
          <div class="point-frame"></div>
        </div>
        <div class="point-txt-wrap">
          <h3 class="point-head">{{ client.name }}</h3>
          <div class="lighter nomarg" v-html="client.blurb"></div>
          <router-link :to="buildPath(`customer-story/${client.slug}`)" class="read-more">
            Read More
          </router-link>
        </div>
      </div>
    </carousel>

    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import carousel from "sh-v-owl-carousel";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country: String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.solution.metaTitle || this.solution.title,
        description: this.solution.metaDescription || this.solution.subTitle,
        image: this.solution.cover.imageURL,
      }),
    };
  },
  components: {
    Topnav,
    Botfooter,
    carousel
  },
  name: "CloudDevops",
  computed: {
    solution() {
      const item = this.$local.vendors.find(v => v.name === "AWS");
      return item.solutions[0];
    },
  },
  methods: {
    buildPath,
    redirectToArticle(article) {
      if (!article.articleURL.includes('http')) {
        this.$router.push(this.buildPath(`insights/${article.articleURL}`));
      }
      else window.open(article.articleURL, '_blank');
    },
  },
};
</script>

<style scoped>
.solution-content-richtxt {
  max-width: 1000px;
  margin: 50px auto 70px;
  text-align: center;
}

.solution-content-richtxt blockquote h2 {
  max-width: 700px;
  margin: 50px auto 20px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.3;
}

.solution-content-richtxt h3 {
  text-align: left;
  clip-path: polygon(0 100%,
      100% 100%,
      100% 85%,
      95% 85%,
      95% 15%,
      100% 15%,
      100% 0,
      0 0);
  border: var(--yellow) 14px solid;
  padding: 30px;
  font-weight: 600;
  font-size: 40px;
  width: 380px;
  line-height: 1.3;
  float: left;
  margin-top: 0;
}

.solution-content-richtxt h4 {
  text-align: left;
  margin: 0px 0 10px 420px;
  line-height: 1.3;
  font-size: 24px;
}

.solution-content-richtxt blockquote {
  max-width: 700px;
  margin: 0px auto -20px;
  font-weight: 400;
}

.solution-content-richtxt p {
  text-align: left;
  margin: 0 0 0 420px;
  padding-bottom: 34px;
}

.solutions-schedule-call-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 6rem auto;
}

.solutions-schedule-call-wrapper h2 {
  text-align: center;
}

.solutions-schedule-call-wrapper .btn {
  max-width: 300px;
  margin-top: 20px;
}

.articles-section {
  display: flex;
  flex-direction: column;
  margin-top: -40px;
  margin-bottom: 98px;
  gap: 4rem;
}

.article-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4rem;
}

.article-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  gap: 1rem;
  padding: 5px;
  cursor: pointer;
}

.article-container:hover {
  background-color: #e5e6e9;
  transform: translateY(-3px);
}

.articles-read-more {
  color: var(--light-blue);
  text-decoration: none;
  margin-top: auto;
}

.articles-read-more:hover {
  filter: brightness(90%);
}

.articles-read-more:active {
  filter: brightness(80%);
}

@media screen and (max-width: 991px) {
  .solution-content-richtxt blockquote h2 {
    font-size: 30px;
  }

  .solution-content-richtxt h4 {
    margin: 30px 0 10px 0px;
  }

  .solution-content-richtxt h5 {
    margin: 0px auto 50px;
  }

  .solution-content-richtxt h3 {
    float: unset;
    text-align: center;
    padding: 25px 0;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    clip-path: polygon(100% 85%,
        95% 85%,
        95% 15%,
        100% 15%,
        100% 0%,
        0% 0%,
        0% 15%,
        5% 15%,
        5% 85%,
        0% 85%,
        0% 100%,
        100% 100%)
  }

  .solution-content-richtxt p {
    margin: 0px;
    display: block;
  }

  .article-container {
    width: 90%;
    gap: 1rem;
    margin-bottom: 20px;
  }

  .article-wrapper {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
</style>