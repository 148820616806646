<template>
    <div
    v-if="$local.popup._id"
        class="side-pop"
        :class="{ sideclose: sideClose }"
    ><button
            @click="sideClose = true"
            type="button"
            title="Close"
            class="close-side-pop"
        >
            <img src="../assets/white-x.svg" alt=""></button><a
            :href="$local.popup.link"
            target="_blank"
            class="side-pop-link"
        >
            <img :src="$local.popup.image.imageURL" :alt="$local.popup.image.altText">
            <div class="side-pop-txt-wrap" v-html="$local.popup.text">
            </div>
        </a></div>
</template>
<script>
export default {
    data: () => ({
        sideClose: false,
    }),
    name: "sidepop"

}
</script>

@click="opennavbar = true"