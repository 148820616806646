<template>
    <div>
        <div v-if="lp.title" class="tech-top-wrap">
            <h1 class="txt54 mobile-only mobile-title">{{ lp.title }}</h1>
            <div class="tech-top-img">
                <img :src="lp.cover.imageURL" />
            </div>
            <div class='tech-top-frame light-blue-line'>
                <h1 class="txt54 desktop-only">{{ lp.title }}</h1>
                <div v-html="lp.blurb"></div>
            </div>
        </div>


        <div id="details" class="landing-body" v-html="lp.body"></div>

        <div v-if="lp.formSectionContent" class="land-box-container">
            <div class="land-box tab-cont-txt--box lightblue" v-html="lp.formSectionContent">
            </div>

            <div class="land-contact-form">
                <div v-if="formStatus != 'success'" class="land-form-title">Fill in the form to download the
                    {{ lp.descriptionOfTheFile || "guide" }}</div>
                <form class="form land-form-wrap land-form-bg" @submit.prevent="sendForm" v-if="formStatus != 'success'"
                    ref="contactForm" id="contactFormId">
                    <input-field class="input land-input" label="First Name" :required="true" v-model="form.fullName"
                        @form-errors="handleFormErrors" @error-message="onError"
                        :validationType="ValidationTypeEnum.AlphaBet" errorText="First name can only contain letters" />
                    <input-field class="input land-input" type="email" label="Email Address" :required="true"
                        v-model="form.email" @error-message="onError" :validationType="ValidationTypeEnum.Email"
                        errorText="Invalid Email address" />
                    <input-field class="input land-input" label="Phone Number" :required="true" v-model="form.phone"
                        @error-message="onError" :validationType="ValidationTypeEnum.Phone"
                        errorText="Invalid phone number" />
                    <input-field class="input land-input" label="Job Title" :required="true" v-model="form.jobTitle"
                        @error-message="onError" :validationType="ValidationTypeEnum.Required"
                        errorText="Company website is required" />
                    <input-field class="input land-input" label="Company" :required="true" v-model="form.company"
                        @error-message="onError" :validationType="ValidationTypeEnum.Required"
                        errorText="Company is required" />
                    <select class="input land-input" name="region" id="region" v-model="form.region">
                        <option :value="null" disabled>Geographic Region</option>
                        <option v-for="region in $local.websiteSettings.region" :key="region._id" :value="region.name">
                            {{ region.name }}</option>
                    </select>

                    <label class="bgl-checkbox-label">
                        <input @error-message="onError" errorText="You have to agree to the privacy policy"
                            type="checkbox" class="bgl-checkbox" :required="true" v-model="checkboxChecked" />
                        <span class="checkbox">✔</span>
                        <span class="land-checkbox-txt">
                            I have read and agree to the <a href="https://selacloud.com/legal/privacy-policy"
                                target="_blank">Privacy Policy</a></span>
                    </label>
                    <button :disabled="formStatus === 'sending' || this.hasFalseProp || !checkboxChecked" type="submit"
                        class="btn forum land-btn-form">
                        Download {{ lp.descriptionOfTheFile || "guide" }}
                    </button>
                    <div class="error" v-if="formStatus == 'fail'">
                        <p>An error occurred while submitting the form</p>
                    </div>
                </form>
                <div class="form-bg tnx land-form-thanks" v-if="formStatus == 'success'">
                    <!-- show a customized thank you text. if it includes @FILE_URL@ then replace it with the URL of the PDF-->
                    <div v-if="lp.formThankyouContent && lp.formThankyouContent != ''"
                        v-html="replaceUrlInThankyouText(lp.formThankyouContent)" />

                    <p v-else> <!-- show a default thank you text-->
                        <span class="new-line">Thank you!</span>
                        <a v-if="lp.pDFForDownload" :href="lp.pDFForDownload.imageURL"
                            class="mt30px btn land-btn-form lowcase white-btn" type="button">Click
                            here to download the guide</a>
                    </p>
                </div>
            </div>
        </div>


        <div v-if="lp.secondSectionContent" class="land-box-container">
            <img v-if="lp.secondSectionImage" class="land-side-img-left" :src="lp.secondSectionImage.imageURL" />
            <div class="land-box land-box-right tab-cont-txt--box yellow" v-html="lp.secondSectionContent">
            </div>
        </div>

        <div v-if="lp.calendly" class="calendly-inline-widget" id="requestdemo" :data-url="lp.calendly"></div>

        <div v-if="lp.thirdSectionContent" v-html="lp.thirdSectionContent">

        </div>
        <div v-if="lp.footer" class="land-footer" v-html="lp.footer">
        </div>


        <SocialMenu pagename="landing" :phoneForWhatsapp="lp.phoneForWhatsapp" whatsappOnly="true" :vendor="``" :title="`landing`" :color="'white'" :size="20"
            class="whatsapp-only social-menu small" />

        <botfooter v-if="!lp.footer"></botfooter>


    </div>
</template>


<script>
import Botfooter from "@/components/Botfooter.vue";
import axios from 'axios';
import SocialMenu from "../components/SocialMenu.vue";

export default {
    metaInfo() {
        return {
            ssrAppId: "sela",
            ...this.seo({
                title: this.lp.title,
                description: this.lp.blurb,
                image: this.event?.thumbnail?.imageURL || this.lp.cover?.imageURL,
            }),
        };
    },
    components: {
        Botfooter,
        SocialMenu
    },
    data: () => ({
        now: new Date(),
        formStatus: "",
        form: {
            pageName: "",
            pageId: "",
            fullName: "",
            email: "",
            company: "",
            phone: "",
            jobTitle: "",
            region: null
        },
        checkboxChecked: false,
        ValidationTypeEnum: {
            AlphaBet: /^[a-zA-Z ]+$/,
            Email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
            Phone: /^(\+)?(?:[0-9-()/.\s?]){6,15}[0-9]{1}$/,
            Required: /.+/
        },
        hasFalseProp: false,
        formErrors: {},
    }),

    watch: {

    },
    computed: {
        lp() {
            const { id } = this.$route.params;
            const lp = this.$local.landingPages?.find((c) => c.slug == id);

            return lp
        },
    },
    mounted() {
        const recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
        document.head.appendChild(recaptchaScript)
        console.log("ver 9.4");
    },
    methods: {
        onError(errorObj) {
            this.formErrors[errorObj.label] = errorObj.validation;

            this.setFalseProp();
        },
        async sendForm() {
            try {
                this.setFalseProp();
                if (this.hasFalseProp) {
                    return; // Stop the form submission if there are errors
                }
                this.formStatus = "sending";
                this.form.pageName = this.lp.name;
                this.form.pageId = this.lp._id;

                await axios.post(
                    '/.netlify/functions/submitLandingDetails',
                    this.form
                );
                this.formStatus = "success";
            } catch (err) {
                console.log(err.response.data);
                this.formStatus = "fail";
            }
        },
        setFalseProp() {
            this.hasFalseProp = Object.values(this.formErrors).includes(false);
        },
        replaceUrlInThankyouText(htmlContent) {
            htmlContent = htmlContent.replace(/@FILE_URL@/g, this.lp.pDFForDownload.imageURL);
            console.log(htmlContent);
            return htmlContent;
        }
    },
    name: "Landing",
};

</script>

<style scoped>
.land-form-thanks {
    max-width: 90%;
    margin: auto;
    background: transparent;
    color: white;
    margin-top: 100px;
}

::v-deep .landing-body h1 {
    font-weight: 300;
}

::v-deep .landing-sections {
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    justify-content: center;
}

::v-deep .landing-item img {
    max-width: 100px;
    max-height: 75px;
    object-fit: contain;
}

::v-deep .landing-sections>div.landing-item {
    flex: 0 0 25%;
    gap: 18px;
}

::v-deep .landing-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

::v-deep .footer-ad {
    margin-top: 100px;
    display: flex;
    max-width: 1170px;
    border: 14px solid var(--light-blue);
    padding: 50px;
    text-align: left;
    gap: 50px;
    align-items: center;
}

::v-deep .footer-ad img {
    max-width: 30%;
}

#requestdemo {
    max-width: 1000px;
    height: 1000px;
    margin: auto;
}

.tech-top-wrap {
    margin-bottom: 120px;
}

.tech-bottom-img {
    margin: 60px -80px 60px 0;
    position: relative;
    z-index: 2;
    flex-basis: 46%;
}

#requestdemo {
    scroll-margin-top: 100px;
}

.tech-bottom-wrap {
    justify-content: space-around;
    margin-top: 100px;
}

.video {
    margin-right: 34px;
}

.video-shadow {
    box-shadow: rgb(0, 219, 232) 25px 25px;
    width: 675px;
}


.demo-title {
    display: flex;
    margin: auto;
    margin-top: 20px;
    gap: 20px;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.button-container input {
    height: 54px;
    margin: 15px;
}

.demo-title img {
    max-width: 120px;
}

.main-container {
    display: flex;
    flex-direction: column;
}

.mobile-only {
    display: none;
}

.desktop-only {
    display: block;
}

@media screen and (max-width: 767px) {
    ::v-deep .landing-sections>div.landing-item {
        flex: 0 0 100%;
    }

    ::v-deep .footer-ad {
        flex-direction: column;
        padding: 35px;
    }

    ::v-deep .footer-ad img {
        max-width: 100%;
    }

}

@media screen and (max-width: 479px) {

    .tech-top-frame.light-blue-line {
        border: 0;
    }

    .mobile-title {
        margin: 20px auto;
        border: 10px solid var(--light-blue);
        padding: 14px;
        width: 90;
        text-align: center;
    }

    .tech-top-frame {
        transform: translateY(0);
    }

    .tech-top-wrap {
        margin-bottom: 50px;
    }

    .tech-top-img {
        margin: 40px auto;
        flex-basis: 90%;
    }

    .tech-top-img img {
        object-fit: cover;
        width: 100%;
    }

    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none;
    }

    .tech-top-frame {
        text-align: center;
    }

    .tech-bottom-wrap {
        margin-top: 20px;
    }

    ::v-deep .btn {
        padding: 12px 22px;
    }
}

@media screen and (max-width: 1200px) {
    .tech-bottom-img {
        margin: 60px auto 60px auto;
        text-align: center;
    }

    .landing-body {
        max-width: 90%;
    }
}
</style>