<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="blogs-header">
      <div class="blogs-top-wrap">
        <h1 class="txt80">Sela's Blog</h1>
        <h2 class="blogs-subtitle">Explore & Learn</h2>
      </div>
    </div>


    
    <!-- *** Filter *** -->
    <FilterForm dataType="insights" showFreeSearch showAllVendors showCategories showSortBy filterPath="filterInsights" @refresh-results="refreshResults"></FilterForm>




    <div class="know-list-wrap">
      <router-link v-for="blog in blogs" :key="blog._id" :to="buildPath(`/insights/${blog.slug}`)"
        class="know-item blog-item">
        <img :src="blog.cover.imageURL" class="know-item-image" />
        <p class="know-item-head">{{ blog.title }}</p>
        <p class="know-item-txt">{{ blog.blurb }}</p>
        <p class="know-item-date">{{ parseAuthor(blog.author) }} <span v-if="parseAuthor(blog.author)" class="blog-pipe">|</span> {{
          formatDate(blog._createdDate) }}</p>

      </router-link>
    </div>
    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";
import FilterForm from "../components/FilterForm.vue";

export default {
  props: {
    country: String
  },
  components: {
    Topnav,
    Botfooter,
    FilterForm
  },
  name: "Knowledge",
  metaInfo() {
    return {
      ssrAppId: "sela",
      link: [{ rel: "canonical", href: "https://selacloud.com/insights" }],
      ...this.seo({
        title: getMetatitle("insights"),
        description: getMetadesc("insights")
      })
    };
  },
  data: () => ({
    blogs: [],
  }),
  created() {
    this.initData()
  },
  methods: {
    buildPath,
    initData() {
      this.blogs = this.$local.blogPosts.filter( b=>!b.isPodcast );
    },
    refreshResults(results) {
      if (results)
        this.blogs = results;
      else
        this.initData();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const day = date.getUTCDate();
      const month = monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      return `${month} ${day}, ${year}`;
    },
    parseAuthor(authorString) {
      authorString = authorString || "Sela";
      return authorString?.split(",")[0]?.split('|')[0];
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>
.blogs-header {
  background-image: url(../assets/laptopreflect.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1920px;
  min-height: 380px;
  margin: auto;
  display: flex;
  margin-bottom: 80px;
}


.blogs-top-wrap {
  max-width: 1170px;
  margin: auto;
  text-align: center;
  border: 8px solid white;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 50px;
}

.blogs-subtitle {
  font-size: 40px;
  margin: 0 0 15px 0;
  font-weight: normal;
}

.filter-container {
  margin-bottom: 80px;
}


.blog-item {
  border-radius: 25px;
  padding: 20px;
  box-shadow: 3px 3px 8px #d3d3d3;
}

.blog-item {
  flex-basis: 29.33%;
  margin: 0 2% 40px;
}

.blog-item .know-item-head {
  border-bottom: 1px solid #2D3034;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.blog-pipe {
  padding: 10px;
}

@media screen and (max-width: 991px) {
  .blog-item {
    flex-basis: 44%;
  }

}

@media screen and (max-width: 767px) {
  .blogs-header {
    min-height: 220px;
  }

  .blogs-top-wrap {
    max-width: 90%;
    padding: 5px 40px;
  }

  .blogs-subtitle {
    font-size: 25px;
  }

  .blog-item {
    flex-basis: 100%;
  }
}
</style>
