<template>
    <div>
      <topnav :country="country"></topnav>
      <div :dir="isHebrew ? 'rtl' : 'ltr'" class="course-page-wrap">
        <div class="course">
          <div class="course-txt">

           <div dir="ltr" v-if="isPopupOpen.value" :key="componentKey" class="popup">
            <div class="popup-content">
               <div @click="showConfirmation" class="close-icon"></div>
              <div class="popup-wrapper">
                <div class="form-title-wrapper">
                  <div class="popup-form-title">
                    <h1>Course registration form</h1>
                    <h2>{{ courseDetails.Title }}</h2>
                  </div>
                  <div class="pop-up-coursecode">
                    <span>Course Code: <strong>{{ courseDetails.CourseCode }}</strong></span>
                  </div>
                </div>
                <div v-if="isClose" class="pop-up-closed-course-wrapper">
                  <h2>Thank you for your interest in the course</h2>
                  <h4>To register for the course, please contact the cloud administrator at your office.</h4>
                </div>
                <div v-else id="form-wrapper" class="popup-form-section-wrapper">
                  <h3 class="pink-border"> Course Details </h3>
                  <p v-if="courseDetails.Courses.length > 1">Select the desired course cycle:</p>

                <div class="table-headers">
                  <div class="table-headers-container">
                    <div class="table-header cycle-header">Cycle</div>
                    <div class="table-header openingDate-header">Opening Date</div>
                    <div class="table-header dates-header">Course Dates</div>
                    <div class="table-header hours-header">Hours</div>
                    <div class="table-header price-header">Price</div>
                  </div>
                </div>

                <div
                 v-for="(item, index) in courseDetails.Courses"
                 :key="index"
                 :class="{'table-row clickable': courseDetails.Courses.length != 1, 'table-row': courseDetails.Courses.length == 1}"
                @click="setCycle(item.Cycle)"
                >
                <div class="input-wrapper-table">
                <input
                  class="radio-input"
                  type="radio"
                 v-model="registrationForm.courseDetails.cycle"
                 :value="item.Cycle"
                 required
                 name="course"
                 style="width: 15px; margin-left: 10px;"
                :checked="true"
                 />
              </div>
        <div class="course-details-wrapper">
          <div class="table-column  cycle-cell"> 
           <span class="table-cell-content">
            {{ item.Cycle }}
           </span>
          </div>
          <div class="table-column openingDate-cell">
           <span class="table-cell-content">{{ item.Days[0] }}</span>
          </div>
          <div class="table-column dates-cell">
           <p class="table-cell-content dates-p">{{ item.Days.map((d) => d.slice(0, -5).substring(0, d.length - 1)).join(',\n') }}</p>
          </div>
          <div class="table-column hours-cell">
           <span class="table-cell-content">{{ item.Hours }}</span>
          </div>
          <div class="table-column price-cell">
           <span class="price-currency table-cell-content">{{ parseFloat(item.Price).toLocaleString() }}</span>
          </div>
        </div>
      </div>
                 
                  <div class="popup-form-fields">
                    <h3 class="pink-border">Participant details</h3>
                    <form  @submit.prevent="addParticipantToRegister"
                     v-if="formStatus != 'success'">
                    <div class="fields-wrapper">
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="firstName" 
                         minlength="2"
                         maxlength="20"
                         v-model="person.firstName"
                         required
                         >
                        <span class="registration-input-span">First Name*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="lastName" 
                         minlength="2"
                         maxlength="20"
                         required
                         v-model="person.lastName"
                         >
                        <span class="registration-input-span">Last Name*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="job" 
                         v-model="person.job"
                         >
                        <span class="registration-input-span">Job Title</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="email"
                         name="email"
                         v-model="person.email"
                         required
                         >
                        <span class="registration-input-span">Participant's Email*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="number"
                         name="phone" 
                         v-model="person.phone"
                         required
                         >
                        <span class="registration-input-span">Participant's Mobile Phone*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="number"
                         name="workPhone" 
                         v-model="person.workPhone"
                         >
                        <span class="registration-input-span">Participant's Work Phone</span>
                      </label>
                    </div>
                      <div class="participants">
                        <div class="participants-container">
                       <label class="add-participant-label" for="particpant">Number of Participants: {{ registrationForm.personalDetails.length + 1 }}</label>
                       <div class="add-participant-card" > 
                         <div class="participant-name" v-for="p, in registrationForm.personalDetails" :key="p.id">
                          <span class="participant-name-span">
                            {{ p.firstName }}
                            {{ p.lastName }}
                          </span>
                          <div @click="editParticipant(p)" class="edit-participant-icon" title="Edit participant details">&#x270E;</div>
                          <div @click="showConfirmationForDeleting(p)" class="cancel-participant-icon" title="Delete participant"></div>
                        </div>

                        <div class="participant-name" v-if="person.firstName">
                          <span class="participant-name-span">
                            {{ person.firstName }}
                            {{ person.lastName }}
                          </span>
                        </div>
                        </div>
                       </div>
                       <input type="submit" value="Add Another Participant" class="btn lowcase add-participant" />
                      </div>
                    </form>
                    <div class="fields-wrapper">
                      
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="company" 
                         v-model="registrationForm.companyDetails.company"
                         required
                         >
                        <span class="registration-input-span">Company Name*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="email"
                         name="invoiceEmail" 
                         v-model="registrationForm.companyDetails.invoiceAddress"
                         >
                        <span class="registration-input-span">Address for Invoice</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="notes" 
                         v-model="registrationForm.companyDetails.comments"
                         >
                        <span class="registration-input-span">Comments</span>
                      </label>
                    </div>
                    
                    <div class="selaNotes">
                      <span class="notes-details">
                        By submitting this form, you agree to receive updates, news, and marketing information from Sela from time to time.
                      </span>
                      <span class="notes-details">
                        By providing the specified contact details as outlined above, you consent to receiving information and updates from Sela via the email address and/or mobile phone number provided.</span>
                    </div>
                  </div>
                  <div class="conditions-wrapper">
                    <strong>Coursepack</strong>
                    <span>Course participation certificate</span>
                    <strong>Conditions:</strong>
                    <span>All prices are in NIS and do not include VAT.</span>
                    <span>Prerequisites for the course - prior knowledge that is required for the course is detailed in the course syllabus and is a condition for participation in the course.</span>
                    <span>The pace of learning in the course is contingent upon the participants' abilities.</span>
                    <span>Course availability is subject to a minimum number of participant registrations.</span>
                    <span>Sela reserves the right to reduce the number of course meetings if the number of participants in the course is small, without affecting the contents of the course.</span>
                    <span>Notification of cancellation of registration is possible up to 8 business days before the beginning of the course by sending a written message to Sela. A fee of 50% of the course price will be applied for any cancellation made after that date.</span>
                    <span>Cancellation made a day before the beginning of the course or during the course will result in a full charge of the course price.</span>
                    <span>Late payments will be charged with interest and index link differences.</span>
                    <span>The authorized signatory declares that he/she has the necessary authorization to commit to paying the full cost of the course on behalf of the company, and his/her signature fully binds the company.</span>
                    <span>In a course delivered by a lecturer coming from abroad, a cancellation notice may be submitted up to 30 days prior to the beginning of the course, by sending a written message to Sela. A fee of 50% of the course price will be applied for any cancellation made after that date. Any non-participation or postponement (to another date) up to 10 days prior to the beginning of the course will incurre a full payment of the course price.</span>
                    <span>I consent to receive information from Sela.</span>
                  </div>
                  <div class="popup-form-section-wrapper">
                    
                    <h3 class="pink-border">Payment Commitment</h3>
                    <div class="fields-wrapper">
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="company" 
                         v-model="registrationForm.commitmentDetails.companyName"
                         required
                         >
                        <span class="registration-input-span">Company Name*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="companyNumber" 
                         v-model="registrationForm.commitmentDetails.companyNumber"
                         required
                         >
                        <span class="registration-input-span">Company Business Number*</span>
                      </label>
                      <div class="course-commitment">
                        <span>
                          <span>
                            Undertakes to pay a total of
                            <strong class="price-currency" v-if="registrationForm.personalDetails.length">{{parseFloat(courseDetails.Courses[0].Price *(registrationForm.personalDetails.length + 1 + participantCountAdjustment)).toLocaleString() }}
                            </strong>
                            <strong class="price-currency" v-else>{{ parseFloat(courseDetails.Courses[0].Price).toLocaleString() }}
                            </strong>
                            <span> + VAT</span>
                          </span>
                          to Sela Software Labs Ltd. within 30 days of the end of the course
                        </span>
                      </div>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="fullName" 
                         v-model="registrationForm.commitmentDetails.undertakesName"
                         required
                         >
                        <span class="registration-input-span">Full Name*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="text"
                         name="jobTitle" 
                         v-model="registrationForm.commitmentDetails.undertakesJob"
                         required
                         >
                        <span class="registration-input-span">Job Title*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="number"
                         name="jobligeePhone" 
                         v-model="registrationForm.commitmentDetails.undertakesPhone"
                         required
                         >
                        <span class="registration-input-span">Phone Number of Authorized Signatory*</span>
                      </label>
                      <label class="field-label">
                        <input 
                         class="registration-input-field"
                         type="email"
                         name="obligeeEmail"
                         v-model="registrationForm.commitmentDetails.undertakesEmail" 
                         required
                         >
                        <span class="registration-input-span">Email of Authorized Signatory*</span>
                      </label>
                      <strong>Undertakes on behalf of the company</strong>
                    </div>
                  </div>
                </div>
                <form @submit.prevent="sendRegistrationRequest"
                     v-if="formStatus != 'success'" class="popup-form">
                <div class="popup-btn-wrapper">
                  <input type="button" value="Close" class="btn mt30px lowcase close" @click="showConfirmation" />
                  <input type="submit" value="Submit" class="btn mt30px lowcase" :disabled="isButtonDisabled">
                </div>
              </form>
              <div class="event-tnx" v-if="formStatus == 'success'">
              <p class="course-tnx-head">Thank you!</p>
              <p>Your submission has been received!</p>
              <input type="button" value="Close" class="btn mt30px lowcase close" @click="showConfirmation" />
            </div>
              </div>
           </div>
          </div>
           <div class="titleSide-wrapper">
            <div class="title-wrapper">
                <h1 v-if="isDataLoaded" :class="isHebrew ? 'title-rtl' : 'title'" >{{ courseDetails.Title }}</h1>
            </div>
          </div>
            <div class="course-duration-box">
              <span v-if="isDataLoaded" dir="ltr" class="duration-number">
               {{ courseDetails.Duration }} {{ courseDetails.Duration === 1 ? 'meeting' : 'meetings' }}
              </span>
              <span class="pdf-icon" >
              <a target="_blank" :href="pdfUrl">
                <svg width="40px" class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium CourseInfo_pdf__AbfrT css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PictureAsPdfRoundedIcon">
                  <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v1.25c0 .41-.34.75-.75.75s-.75-.34-.75-.75V8c0-.55.45-1 1-1H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5h2c.83 0 1.5.67 1.5 1.5v3zm4-3.75c0 .41-.34.75-.75.75H19v1h.75c.41 0 .75.34.75.75s-.34.75-.75.75H19v1.25c0 .41-.34.75-.75.75s-.75-.34-.75-.75V8c0-.55.45-1 1-1h1.25c.41 0 .75.34.75.75zM9 9.5h1v-1H9v1zM3 6c-.55 0-1 .45-1 1v13c0 1.1.9 2 2 2h13c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1-.45-1-1V7c0-.55-.45-1-1-1zm11 5.5h1v-3h-1v3z">
                  </path>
                </svg>
              </a>
            </span>
              <a v-if="isDataLoaded && courseDetails.Courses.length" class="btn-enroll-duration" @click="openPopup">Enroll on this course</a>
            </div>
            <div class="details-wrapper">
              <div class="description-title">
                Description
              </div>
              <div v-if="isDataLoaded" class="details-p">
                  {{ courseDetails.Description }}
              </div>
            </div>
            <div class="details-wrapper" v-if="isDataLoaded && courseDetails.IntendedAudience">
              <div class="audience-title">
                Intended audience
              </div>
              <div class="details-p" >
                {{ courseDetails.IntendedAudience }}
              </div>
            </div>
            <div class="tabs-wrapper">
                <div class="tabs-title" v-if="isDataLoaded">
                    <h3 
                    v-for="(header,index) in Object.keys(courseDetails).filter(key => headersArray.includes(key) && Array.isArray(courseDetails[key]) && courseDetails[key].length > 0)" 
                    :class="{ 'tab-header': true, 'selected-heading': selectedHeading === header }" 
                    @click="toggleSelectedHeading(header)"
                    :key="index">
                    {{ header }}
                   </h3>
                </div>
                <div v-if="selectedHeading === 'Topics'">

                <v-row justify="center" v-if="isDataLoaded">
                       <v-expansion-panels accordion hover light>
                        <v-expansion-panel v-for="(item,index) in courseDetails.Topics" :key="index"  hover light>
                          <div class="item-topic-header-wrapper">
                           <v-expansion-panel-header :class="{'topic-header expandable': item.topics.length > 0, 'topic-header non-expandable': item.topics.length == 0}">{{ item.Description }} </v-expansion-panel-header>
                           <span v-if="item.topics.length > 0">&#x25BE;</span>
                          </div>
                            <v-expansion-panel-content class="topic-content" v-for="topic in item.topics" :key="topic.index">{{ topic.Description }}</v-expansion-panel-content>
                        </v-expansion-panel>
                       </v-expansion-panels>
                </v-row> 
                </div>
                <div v-if="selectedHeading === 'Prerequisites'">
                  <div v-for="item in courseDetails.Prerequisites" :key="item.id"> 
                     <p class="description-deatils"> {{ item.Description }} </p>
                  </div>
                </div>
                <div v-if="selectedHeading === 'Objectives'">
                  <div v-for="item in courseDetails.Objectives" :key="item.id"> 
                     <p class="description-deatils"> {{ item.Description }} </p>
                  </div>
                </div>
            </div>
          </div>
          <div v-if="isDataLoaded && courseDetails.Courses.length" dir="ltr" class="course-info-signup">
              <div class="course-info-enroll">
                <div class="courseInfo">
                  <span>Cycle No.:</span>
                  <strong v-if="isDataLoaded">{{ courseDetails.Courses[0].Cycle }}</strong>
                </div>
                <div class="courseInfo">
                  <span>Opening Date:</span>
                  <strong v-if="isDataLoaded">{{ courseDetails.Courses[0].Days[0] }}</strong>
                </div>
                <div class="courseInfo">
                  <span>Course Dates:</span>
                  <div class="courseInfo-dates" v-if="isDataLoaded">
                  <strong v-for="date in courseDetails.Courses[0].Days.slice(0,5)" :key="date.Id">{{ date }}</strong>
                  <div class="courseInfo-dates" v-if="isDataLoaded && extended == true" >
                   <strong v-for="date in courseDetails.Courses[0].Days.slice(5)" :key="date.Id">{{ date }}</strong>
                  </div>
                  <div @click="extendDates" class="show-more-button" v-if="isDataLoaded && courseDetails.Courses[0].Days.length > 5">
                    <div>
                      <span class="show-more-button-txt">
                        {{ extended ? 'Show Less »' : 'Show More »' }}
                     </span>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="courseInfo">
                  <span>Hours:</span>
                  <strong v-if="isDataLoaded">{{ courseDetails.Courses[0].Hours }}</strong>
                </div>
                <div v-if="isDataLoaded && parseFloat(courseDetails.Courses[0].Price).toLocaleString() != 0" class="courseInfo">
                  <span>Price:</span>
                  <strong v-if="isDataLoaded" class="price-currency">{{parseFloat(courseDetails.Courses[0].Price).toLocaleString()}}</strong>
                </div>
                <div class="courseInfo-btn">
                  <button class="btn-enroll" @click="openPopup">Enroll on this course</button>
                </div>
              </div>
            </div>
          <div v-if="isDataLoaded && !courseDetails.Courses.length" dir="ltr" class="course-signup">
            <form
              @submit.prevent="sendDetailsRequest"
              v-if="
                formStatus != 'success'  
              "
            >
              <h3 class="signup-title">Tell Me More...</h3>
              <input
                placeholder="Full Name*"
                required="required"
                v-model="form.name"
                type="text"
                class="event-input"
              />  
              <input
                placeholder="Job Title*"
                required="required"
                v-model="form.job"
                type="text"
                class="event-input"
              />
              <input
                placeholder="Company*"
                required="required"
                v-model="form.company"
                type="text"
                minlength="3"
                class="event-input"
              />
              <input
                placeholder="Email Address*"
                required="required"
                v-model="form.email"
                type="email"
                class="event-input"
              />
              <input
                placeholder="Phone Number *"
                v-model="form.phone"
                required
                type="tel"
                pattern="[0-9]+"
                title="Only numbers"
                class="event-input"
              /> 
              <label class="bgl-checkbox-label">
                <input
                  type="checkbox"
                  class="bgl-checkbox"
                  v-model="form.approveEmails"
                />
                <span class="checkbox">✔</span>
                <span class="checkbox-txt">
                  I agree to receive marketing materials from Sela</span
                >
              </label>
  
              <input type="submit" value="Submit" class="btn event-btn lowcase" />
              <div class="error" v-if="formStatus == 'fail'">
                <p>An error occurred while submitting the form</p>
              </div>
            </form>
            <div class="event-tnx" v-if="formStatus == 'success'">
              <p class="tnx-head">Thank you!</p>
  
              <p>Your submission has been received!</p>
            </div>
          </div>
        </div>
      </div>
      <botfooter  :country="country"></botfooter>
    </div>
  </template>
  
  <script>
  import Botfooter from "@/components/Botfooter.vue";
  import Topnav from "@/components/Topnav.vue";
  import exceptionsEnrolls from "@/data/exceptionsEnrolls";
  import axios from 'axios';
  export default {
    props: {
    country:String
  },
    metaInfo() {
    const {slug} = this.$route.params;
    const topic = this.$local.courses.find((c) => {return c.syllabus.some((item => item.Code === slug))});
    const pageTitle = topic.syllabus.find(item => item.Code === slug);
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: pageTitle.Title,
        description:
        pageTitle.Title,
      }),
     };
    },
    components: {
      Topnav,
      Botfooter,
    },
    data() {
     return {
      isDataLoaded: false,
      headersArray: ['Topics','Prerequisites','Objectives'],
      selectedHeading: 'Topics',
      showDiv: false,
      itemTopics: null,
      courseDetails: null,
      formStatus: "",
      participantCountAdjustment: 0,
      isPopupOpen: {  // currently not in use,the page reloads when the form is close
        value: false,
        componentKey: 0
      },
      componentKey: 0,
      isHebrew: false,
      isClose: false,
      isException: false,
      extended: false,
      person: {
        firstName: "",
        lastName: "",
        job: "",
        email: "",
        phone: "",
        workPhone: "",
      },
      registrationForm: {
        courseDetails: {
        courseName: "",
        courseCode: "",
        cycle: "",
        hours: "",
        days: "",
        price: "", 
      },
      personalDetails: [],
      commitmentDetails: {
        companyName: "",
        companyNumber: "",
        undertakesName: "",
        undertakesJob: "",
        undertakesPhone: "",
        undertakesEmail: "",
      },
      companyDetails: {
        company: "",
        fax: "",
        invoiceAddress: "",
        comments: "",
      },
      isSendToUndertakes: true,
      sendTo: "",

    },
      form: {
        name: "",
        email: "",
        phone: "",
        company: "",
        job: "",
        courseName: "",
        approveEmails: true,
        sendTo: ""
      },
    };
   },
    computed: {
    pdfUrl() {
      const { slug } = this.$route.params;
      const url = `https://sela.co.il/coursePDF?courseCode=${slug}`
      return url;
     },
    dates() {
      return this.courseDetails.Courses[0].Days.slice(0, 5);
     },
    isButtonDisabled() {
      if(this.person.email && this.person.firstName && this.person.lastName && this.person.phone &&
         this.registrationForm.companyDetails.company && this.registrationForm.commitmentDetails.companyName
         && this.registrationForm.commitmentDetails.companyNumber && this.registrationForm.commitmentDetails.undertakesEmail
         && this.registrationForm.commitmentDetails.undertakesJob && this.registrationForm.commitmentDetails.undertakesName
         && this.registrationForm.commitmentDetails.undertakesPhone)
      return false;
      
      return true;
     },
    },
    updated() {
      const radioInputs = document.querySelectorAll('input[type="radio"][name="course"]');
      if (radioInputs.length === 1) {
      const radioInput = this.$el.querySelector('input[type="radio"]');
       if (radioInput) {
         radioInput.checked = true;
         this.registrationForm.courseDetails.cycle = this.courseDetails.Courses[0].Cycle;
        }
      }
    },
    created() {
      this.fetchData();
      this.foundCloseCourse();
      this.foundException();
    },
    methods: {
    extendDates() {
        this.extended = !this.extended;
    },
    setCycle(value) {
        this.registrationForm.courseDetails.cycle = value;
    },
    addParticipantToRegister() {
      const copiedPerson = JSON.parse(JSON.stringify(this.person));
      this.registrationForm.personalDetails.push(copiedPerson); 
      this.person.firstName = "";
      this.person.lastName = "";
      this.person.job = "";
      this.person.phone = "";
      this.person.email = "";
      this.person.workPhone = "";
    },
    fetchData() {
      const { slug } = this.$route.params;
      const apiUrl = `https://sol.sela.co.il/api/controllers/courses/syllabusByCourseCode?courseCode=${slug}`;
      axios
        .get(apiUrl)
        .then(response => {
          this.courseDetails = response.data;
          this.isDataLoaded = true;
          this.containsHebrew(this.courseDetails.Title);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },


    containsHebrew(text) {
     if (text.search(/[\u0590-\u05FF]/) >= 0){
      this.isHebrew=true;       
       return true;
     }
      return false;
    },

    toggleSelectedHeading(item) {
      this.selectedHeading = item;
    },

    toggleDivVisibility(item) {
      this.showDiv = !this.showDiv;
      this.itemTopics = item;
    },

    openPopup() {
      this.isPopupOpen.value = !this.isPopupOpen.value;
    },

    closePopup() {
      this.isPopupOpen.value = !this.isPopupOpen.value;
      this.person = {};
      this.registrationForm.personalDetails = [];
    }, 

    showConfirmation() {
      if(this.formStatus == 'success'){
        this.closePopup();
        return;
      }
      const confirmExit = window.confirm("Are you sure you want to close the form and lose all changes?");
      if (confirmExit) {
        this.closePopup();
      }
    },

    showConfirmationForDeleting(person) {
      const confirmExit = window.confirm("Are you sure you want to delete the particpant and lose all changes?");
      if (confirmExit) {
        this.cancelParticipant(person);
      }
    },

    activateButton() {
      if(this.person.email && this.person.firstName && this.person.lastName && this.person.phone && this.registrationForm.courseDetails.cycle &&
         this.registrationForm.companyDetails.company && this.registrationForm.commitmentDetails.companyName
         && this.registrationForm.commitmentDetails.companyNumber && this.registrationForm.commitmentDetails.undertakesEmail
         && this.registrationForm.commitmentDetails.undertakesJob && this.registrationForm.commitmentDetails.undertakesName
         && this.registrationForm.commitmentDetails.undertakesPhone)
      this.isButtonDisabled = false;
      if(!this.registrationForm.courseDetails.cycle)
       this.isButtonDisabled = true;
    },

    cancelParticipant(person) {
      if(this.registrationForm.personalDetails.length > 0) {
       const newPersons = this.registrationForm.personalDetails.filter((p) => p.firstName != person.firstName && p.lastName != person.lastName);
       this.registrationForm.personalDetails = newPersons;
      }
      else {
        this.person.firstName = "";
        this.person.lastName = "";
        this.person.job = "";
        this.person.phone = "";
        this.person.email = "";
        this.person.workPhone = "";
      }
    },

    editParticipant(person) {
      if(this.person.firstName && this.person.lastName && this.person.email && this.person.phone){
        const copiedPerson = JSON.parse(JSON.stringify(this.person));
        this.registrationForm.personalDetails.push(copiedPerson); 
        const newPersons = this.registrationForm.personalDetails.filter(p => p.firstName != person.firstName);
        this.registrationForm.personalDetails = newPersons;
        this.person.firstName = person.firstName;
        this.person.lastName = person.lastName;
        this.person.job = person.job;
        this.person.phone = person.phone;
        this.person.email = person.email;
        this.person.workPhone = person.workPhone;
      }
      else {
        alert('Finish adding the new participant before editing an existing one');
      }
    },

    foundCloseCourse() {
      const { slug } = this.$route.params;
      const existsInArray = exceptionsEnrolls.closeCourse.some(obj => Object.values(obj).some(value => value.includes(slug)));
      if(existsInArray){
        this.isClose =true;
      }
    },
    foundException() {
      const { slug } = this.$route.params;
      const existsInArray = exceptionsEnrolls.HideCommitmentText.some(obj => Object.values(obj).some(value => value.includes(slug)));
      if(existsInArray){
        this.isException =true;
      }
    },

    async sendRegistrationRequest() {
      const { slug } = this.$route.params;
      let data = null;
      const apiUrl = `https://sol.sela.co.il/api/controllers/courses/syllabusByCourseCode?courseCode=${slug}`;
      axios
        .get(apiUrl)
        .then(async response => {
          data = response.data;
         if(!this.registrationForm.courseDetails.cycle)
          window.alert("Please select a course cycle");
         const courseItem =  data.Courses.find(c => c.Cycle === this.registrationForm.courseDetails.cycle)
         this.registrationForm.courseDetails.courseName = data.Title;
         this.registrationForm.courseDetails.courseCode = data.CourseCode;
         this.registrationForm.courseDetails.days= courseItem.Days;
         this.registrationForm.courseDetails.hours = courseItem.Hours;
         this.registrationForm.courseDetails.price = courseItem.Price;
         if(!this.person) 
           return;
         else
           this.registrationForm.personalDetails.push(this.person);

         this.participantCountAdjustment = -1;  
         const formToRegister = this.registrationForm;
         
       try {
         await axios.post(
          '/.netlify/functions/registerToCourse',
          formToRegister
        );
        this.formStatus = "success";
      } catch (error) {
        this.formStatus = "fail";
        console.error(error);
      }
    });
    },
    
    async sendDetailsRequest() {
      this.form.courseName = this.courseDetails.Title;
      const requestData = this.form;
   
      try {
         await axios.post(
          '/.netlify/functions/courseContactUs',
          requestData
        );
        this.formStatus = "success";

      } catch (error) {
        this.formStatus = "fail";
        console.error(error);
      }
    },
  },
  name: "CourseInfo",
};
  </script>
  
<style>
  
.noresults {
    font-style: italic;
  }

.course-page-wrap {
  max-width: 1170px;
  margin: 0 auto 30px;
  width: 90%;
}

.course {
  margin: 40px 0;
  position: relative;
}

.course-txt {
  display: inline-block;
  width: 56%;
  margin: 0 3% 30px 7%;
  justify-content: flex-end;
}  

.course-info-signup {
  position: sticky;
  top: 100px;
  padding: 20px 30px;
  display: inline-block;
  width: 34%;
  vertical-align: top;
}
.course-info-enroll {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 14px solid;
  border-color: var(--pink);
}

.courseInfo {
  display: flex;
  justify-content: space-between;
}

.courseInfo-btn {
  display: flex;
  justify-content: center;
}

.courseInfo span {
  font-weight: 200;
}

.courseInfo-dates {
  display: flex;
  flex-direction: column;
}

.show-more-button {
  display: flex;
  justify-content: flex-start;
  background-color: var(--black);
  cursor: pointer;
}

.show-more-button div {
  padding: 0.2rem 0.4rem;
  color: #ffffff;
}

.show-more-button-txt {
  font-size: 15px;
  font-weight: 700;
}

.price-currency::before {
  content: "\20AA";
  margin-right: 5px;
}

.titleSide-wrapper {
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 2rem);
  align-items: flex-start;
}

.title-wrapper {
  direction: ltr;
  border: 14px solid;
  border-color: var(--pink);
  max-width: calc(100vw - 2rem);
  padding: 1rem;

}

.title {
  width: 100%;
  max-width: calc(100vw - 8rem);
  position: relative;
  left: 2rem;
  font-weight: 700;
  padding: 1rem;
  background-color: #fff;
  text-align: end;
}

.title-rtl {
  width: 100%;
  max-width: calc(100vw - 8rem);
  position: relative;
  right: 2rem;
  font-weight: 700;
  padding: 1rem;
  background-color: #fff;
  text-align: end;
}

.course-duration-box {
  padding: 30px 0px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-size: 25px;
  gap: 10px;
}

.pdf-icon {
 padding-left: 15px;
}

.pdf-icon svg {
  vertical-align: bottom;
}

.duration-number{
    padding-left: 10px;
    padding-right: 10px;
}


.btn-enroll {
  background: var(--black);
  text-decoration: none;
  color: White;
  padding: 5px 30px;
  transition: 200ms all ease;
  border: none;
  cursor: pointer;
}

.btn-enroll-duration {
  background: var(--black);
  text-decoration: none;
  color: White;
  font-size: 20px;
  padding: 8px 15px;
  margin-left: 20px;
  transition: 200ms all ease;
  border: none;
  cursor: pointer;
}

.btn-enroll:hover {
  filter: brightness(80%);
}

.btn-enroll-duration:hover {
  filter: brightness(80%);
}

.details-wrapper {
  padding: 50px 0px;
}

.description-title {
  font-size: 1.5rem;
  font-family: Noto Sans Hebrew,sans-serif!important;
}

.audience-title {
  font-size: 1.5rem;
  font-family: Noto Sans Hebrew,sans-serif!important;
}

.details-p {
  font-size: 20px;
  font-weight: 100;
}

.tabs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tabs-title {
  width: fit-content;
  padding-left: -50px;
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.tab-header {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-bottom: 14px solid #e5e5e5;
  transition: all .2s;
  margin: 0;
  font-weight: 400;
  transition: max-height 0.3s ease-in-out;
}

.selected-heading {
  border-color: #00dd95!important;
  border-width: 18px;
  margin-top: -4px; 
}

.item-topic-header-wrapper {
  display: flex;
  width: auto;
  background-color:var(--smoke);
  margin-bottom: 3px;
  align-items: center;
}

.item-topic-header-wrapper span {
  padding-right: 15px;
  font-size: 28px;
}


.topic-header {
  background-color:var(--smoke);
  width: 100%;
  text-align: start;
  transition: all .2s;
  padding:10px;
  font-weight: 300;    
}

.non-expandable {
  cursor: text;
}

.expandable{
  cursor: pointer;
}

.topic-content {
  transition: all .2s;
  font-weight: 200;
  padding: 5px;
  padding-left: 20px;
}

.topic-content div::before {
  content:"\2022  ";
}

.course-signup {
  position: sticky;
  top: 100px;
  border: 14px solid var(--pink);
  padding: 20px 30px;
  display: inline-block;
  width: 34%;
  vertical-align: top;
  height: auto;
}
.signup-title {
  text-align: center;
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.popup-form {
  align-self: center;
  display: flex;
  gap: 2rem;
}

.form-title-wrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.popup-form-title {
  width: fit-content;
  padding: 1rem 1rem 0;
  border: 5px solid;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border-color: var(--pink);
}

.pop-up-closed-course-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
}

.popup-form-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.pink-border {
  font-weight: 700;
  background-color: #f4f3f3;
  max-width: 280px;
  padding: 1rem;
  border-left: 5px solid;
  border-color: var(--pink);
}

.pop-up-coursecode {
  align-items: center;
}

.table-header {
  font-weight: 700;
  text-align: center;
}

.popup-form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
}

.fields-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  max-width: calc(100vw - 4rem);
  gap: 1rem 2rem;
}

.field-label {
  display: flex;
  flex-direction: column;
}

.registration-input-field {
  max-width: 100%;
  padding: 1rem;
  outline: none;
  border-radius: 0;
  border: none;
  background-color: var(--smoke);
}

.registration-input-span {
  position: relative;
  z-index: 1;
  top: -4.8rem;
  font-size: 16px;
  right: -1rem;
  font-weight: 300;
  color: #606265;
  transition: all .2s;
  width: -moz-fit-content;
  width: fit-content;
  height: 0;
  background-color: #f4f3f3;
  font-family: Noto Sans hebrew,sans-serif!important;
}


.participants input.btn {
  padding: 0px 16px;
}


.selaNotes {
  grid-area: 6/1/7/3;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notes-details{
  font-size: .9rem;
  font-style: italic;
}

.participants {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-participant {
  float: inline-end;
}

.add-participant-card {
  display: flex;
  flex-direction: row;
}

.cancel-participant-icon {
  background-image: url(../assets/close.svg);
  z-index: 1000;
  transition: all .2s;
  position: relative;
  border-radius: 50%;
  font-size: 0.5rem;
  margin-right: 2px;
  padding-right: 5px;
  transform: translateY(5%);
  cursor: pointer;
  width: 2em;
  height: 2em;
}

.cancel-participant-icon:hover {
  background-color:#aaa
}

.edit-participant-icon {
  height: 80%;
}

.edit-participant-icon:hover {
  cursor: pointer;
  filter: brightness(10%);
}


.participant-name {
  padding: 2px 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: #545454;
  height: 40px;
}

.participant-name-span {
  margin-right: 10px;
}

.conditions-wrapper{
  text-align: flex-start;
  width: 900px;
  max-width: calc(100vw - 2rem);
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 70px;
  gap: 0.5rem;
}

.conditions-wrapper span {
  display: flex;
  align-items: center;
}

.conditions-wrapper span::before{
  content: '\2022';
  font-size: 1rem;
  align-self: flex-start;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.course-commitment {
  grid-area: 2/1/3/3;
  margin-bottom: 1rem;
}

.popup-btn-wrapper{
  align-self: center;
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}


.course-tnx-head {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: var(--pink);
}

input[type="button"].close {
  background-color: #ffff;
  border: 2px solid black;
  color: black;
}

.course-details-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-grow: 1;
}

.table-headers {
  display: flex; 
  flex-direction: column;
}

.table-headers-container {
  display: flex;
  justify-content: space-around; 
  border-right: 4px solid #ffffff;
}

.table-row {
  background-color:#e3e3e3 ;
  display: flex;
  justify-content: space-between;
}

.clickable {
  cursor: pointer;
}

.table-column {
  border-right: 4px solid #ffffff;
  text-align: center;
  padding-top: 20px;

}  

.table-cell-content {
  width: auto;
}

.dates-p {
  margin-top: 0px;
}

.input-wrapper-table {
  width: 50px;
  display: flex;
}

.cycle-header {
  width: 12%;
}


@media screen and (max-width: 768px ) { 
  .popup-form-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (min-width: 768px ) { 
  

  .cycle-cell { 
    width: 7%;
  }

  .openingDate-cell, .openingDate-header { 
    width: 20%;
  }

  .dates-cell, .dates-header { 
    width: 40%;
  }

  .hours-cell, .hours-header { 
    width: 20%;
  }

  .price-cell, .price-header { 
    width: 14%;
  }
}

@media screen and (max-width: 768px) {

:root {
  --cyclestr: "Cycle:";
  --openingDatestr: "Opening Date:";
  --courseDatesstr: "Course Dates:";
  --hoursstr: "Hours:";
  --pricestr: "Price:";
}

.course-details-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.table-headers {
  display: none;
}

.table-row {
  flex-direction: row; 
  align-items: stretch; 
  background-color:#e3e3e3 ;
}

.table-column {
  margin-bottom: 10px; 
  border: none;
  text-align: end;
}  

.input-wrapper-table {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 35px;
}

.radio-input {
  margin-top: 10px;
}

.table-column::before {
  margin-left: 20px;
  font-weight: 600;
  float: left; 
}  

.cycle-cell::before { 
  content: var(--cyclestr);
}

.openingDate-cell::before { 
  content: var(--openingDatestr);
}

.dates-cell::before { 
  content: var(--courseDatesstr);
}

.hours-cell::before { 
  content: var(--hoursstr);
}

.price-cell::before { 
  content: var(--pricestr);
}

.table-cell-content {
  padding-right: 20px;
  padding-left: 10px;
  justify-content: center;
  white-space: normal;
}

.dates-p {
  margin-top: -10px;
}

.popup-wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 1000px;
  max-width: calc(100vw - 0rem);
  overflow: auto;
}

.course {
  margin: 40px 0;
  margin-left: -20px;
  position: relative;
}

.course-txt {
  display: inline-block;
  width: 90%;
  margin: 0 3% 30px 7%;
  justify-content: flex-end;
}  

.titleSide-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
}

.title-wrapper {
  direction: ltr;
  border: 14px solid;
  border-color: var(--pink);
  max-width: 180%;
  padding: 1rem;
}

.title {
  width: 100%;
  max-width: calc(200vw - 8rem);
  position: relative;
  left: 2rem;
  font-weight: 700;
  padding: 1rem;
  background-color: #fff;
  text-align: start;
}

.course-info-signup {
  position: sticky;
  top: 100px;
  padding: 20px 14px;
  display: inline-block;
  width: 100%; 
  vertical-align: top;
  margin: 0 -2% 30px 7%;
}

.course-info-enroll {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  border: 14px solid;
  border-color: var(--pink);
  max-width: 500px;
}

.course-duration-box {
  padding: 20px 0px; 
  margin: 10px 0; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  font-size: 18px; 
}

.duration-number {
  padding: 5px 10px; 
}

.btn-enroll-duration {
  background: var(--black);
  text-decoration: none;
  color: White;
  padding: 10px 5px;
  margin-top: 20px;
  margin-left: 25px;
  width: 70%;
  text-align: center;
  transition: 200ms all ease;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.details-wrapper {
  padding: 50px 0px; 
}

.description-title,
.audience-title {
  font-size: 1.2rem; 
  font-family: Noto Sans Hebrew, sans-serif!important;
}

.details-p {
  font-size: 18px; 
  font-weight: 100;
  width: 100%;
}

.tabs-wrapper {
  padding: 30px 0px;
  justify-content: flex-start;
}

.tabs-title {
  display: flex;
  flex-direction: row; 
  width: fit-content;
}

.tab-header {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-bottom: 6px solid #e5e5e5;
  transition: all 0.2s;
  margin: 0;
  font-weight: 400;
  font-size: 0.9rem;

}

.selected-heading {
  border-color: #00dd95!important;
  border-width: 8px;
  margin-top: -2px; 
}


.course-signup {
  position: sticky;
  top: 100px;
  border: 8px solid var(--pink);
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.popup-form {
  align-self: center;
  display: flex;
  gap: 2rem;
}

.participants {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.add-participant {
    float: none; 
    text-align: center; 
    justify-content: center;
    max-width: 100%;
    padding: 1rem;
}

.add-participant-card {
    flex-direction: column; 
    width: 100%;
}

.cancel-participant-icon {
    margin: 5px auto; 
    float: none; 
    margin-right: 0px;
}

.edit-participant-icon {
    height: 80%;
    margin-top: 20px;  
    margin-right: -200px;
}


.participant-name {
    margin: 5px 0px 15px;
    padding-left: -30px;
    min-height: 60px; 
}

.form-title-wrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.popup-form-title {
  width: fit-content;
  padding: 1rem 1rem 0;
  border: 5px solid;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border-color: var(--pink);
}

.popup-form-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fields-wrapper {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: flex-start;
  max-width: calc(100vw - 4rem);
  gap: 1rem;
}

.field-label {
  display: flex;
  flex-direction: column;
  width:100%;
}

.registration-input-field {
  max-width: 100%;
  padding: 1rem;
  outline: none;
  border-radius: 0;
  border: none;
  background-color: #f4f3f3;
}
.registration-input-span {
  position: relative;
  z-index: 1;
  top: -5rem;
  right: -0.5rem;
  font-size: 14px;
  font-weight: 300;
  color: #606265;
  transition: all .2s;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  height: 0;
  background-color: #f4f3f3;
  font-family: Noto Sans Hebrew, sans-serif!important;
}

.selaNotes {
  grid-area: 6/1/7/3;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notes-details {
  font-size: .9rem;
  font-style: italic;
}

.conditions-wrapper {
  text-align: flex-start;
  width: 100%; 
  max-width: 100%; 
  display: flex;
  flex-direction: column;
  padding-top: 50px; 
  padding-bottom: 50px; 
  gap: 0.5rem;
}

.conditions-wrapper span {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.conditions-wrapper span::before {
  content: '\2022';
  font-size: 1rem;
  margin-left: 0.5rem;
}

.course-commitment {
  grid-area: 2/1/3/3;
  margin-bottom: 1rem;
}

.popup-btn-wrapper {
  align-self: center;
  margin-top: 2rem;
  display: flex;
  gap: 1rem; 
}

.close {
  background-color: #ffff;
  color: black;
}

}
 
</style>