<template>
   <carousel
    v-if="$local.certs.length"
      :autoplay="true"
      :nav="false"
      :loop="true"
      :dots="false"
      :autoWidth="true"
      :center="true"
      :margin="40"
      :slideTransition="'linear'"
      :autoplaySpeed="4000"
      class="award-slide"
    >
    <div class="award-box" v-for="cert of $local.certs" :key="cert._cert">
      <img :src="cert.image.imageURL" :alt="cert.name"/>
  </div>
    </carousel>
</template>




<script>
import carousel from 'sh-v-owl-carousel'

export default {
  name: "Awards",
  components: { carousel },
  data() {
    return {
      load: true,
    };
  },
  mounted() {
    setTimeout(() => (this.load = false), 10);
    setTimeout(() => (this.load = true), 1200);
  },
};
</script>

<style scoped>
.award-slide{
  margin-bottom: 50px;
}
</style>