<template>
  <div class="testimonials-comp">
    <carousel
      :nav="false"
      :dots=showDots
      :items="1"
      :autoplaySpeed="1000"
      :singleItem="true"
      :autoplayTimeout="10000"
      :autoplay="true"
      :loop="true"
    >
      <div
        :class="showLogo? 'slide' : 'slide slide-no-logo'"
        v-for="testimonial in $local.testimonials"
        :key="testimonial._id"
      >
        <img
          v-if="showLogo && testimonial.company.length && testimonial.company[0].item.logo.imageURL"
          :src="testimonial.company[0].item.logo.imageURL"
          :alt="testimonial.company[0].item.name"
          class="slide-image"
        >

        <div
           :class="showVertLine ? 'slide-txt vert-line' : 'slide-txt'" 
          v-html="testimonial.testimonial"
        >
        </div>
        <p class="slide-name">{{ testimonial.name }}</p>
        <p class="slide-title">{{ testimonial.title }} | {{ testimonial.company[0].item.name }}</p>
      </div>
    </carousel>


  </div>
</template>


<script>
import carousel from 'sh-v-owl-carousel'

export default {
  name: "testimonials",
  components: { carousel },
  props: {
    showDots: Boolean,
    showLogo: Boolean,
    showVertLine: Boolean
  }
}
</script>




