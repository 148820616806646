<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="services-wrap">
      <p class="service-huge-num">02</p>
      <div class="services-top-wrap">
        <div class="service-frame pink">
          <div class="service-frame-txt-wrap">
            <h1 class="txt54">Cloud(s) Skilling</h1>
            <h2 class="service-sub">
              {{ skillingData.blurb }}
            </h2>
            <p v-html="skillingData.details"></p>
            <a href="coursecatalog" class="btn mt30px lowcase">Explore and Enroll in our Courses</a>            
          </div>
          <img :src="skillingData.cover.imageURL" class="service-image" />
        </div>
      </div>
      <div class="service-list border-pink">
        <div>
          <h3 class="skilling-small-head">
            Cloud(s) Skilling<br />for Clients
          </h3>
       <div  class="service-item" v-for="item in $local.skilling[0].skillingList.filter(s => s.anchorId && !s.menuOnly)" :key="item.title">
           <p class="bold nomarg scrollMargin" :id="item.anchorId">{{ item.title }}</p>
           <p class="lighter nomarg" v-html="item.body"></p>
       </div>
       <div class="skill-gray-box">
        <div class="service-item" v-for="item in $local.skilling[0].skillingList.filter(s => !s.anchorId && !s.menuOnly)" :key="item.title">
            <p class="bold nomarg">{{ item.title }}</p>
            <p class="lighter nomarg" v-html="item.body"></p>
        </div>
         </div>
        </div>
       
      </div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.skillingData.metaTitle,
        description: this.skillingData.metaDescription,
      }),
    };
  },  
  computed:{
    skillingData() {
      return this.$local.skilling[0]; 
    },
  },
  name: "Skilling",
};
</script>