<template>


    <div class="form-wrap">
        <form class="form" @submit.prevent="sendForm" v-if="formStatus != 'success'">
            <div class="form-bg">
                <p class="form-head">Let's connect</p>
                <div class="form-side-by">
                    <input-field class="input" label="First Name" :required="true" v-model="form.fullName"
                        @form-errors="handleFormErrors" @error-message="onError"
                        :validationType="ValidationTypeEnum.AlphaBet" errorText="First name can only contain letters" />
                    <input-field class="input" label="Last Name" :required="true" v-model="form.lastName"
                        @form-errors="handleFormErrors" @error-message="onError"
                        :validationType="ValidationTypeEnum.AlphaBet" errorText="Last name can only contain letters" />
                </div>
                <div class="form-side-by">
                    <input-field class="input" type="email" label="Email Address" :required="true" v-model="form.email"
                        @error-message="onError" :validationType="ValidationTypeEnum.Email"
                        errorText="Invalid Email address" />
                    <input-field class="input" label="Phone Number" :required="true" v-model="form.phone"
                        @error-message="onError" :validationType="ValidationTypeEnum.Phone"
                        errorText="Invalid phone number" />
                </div>
                <div class="form-side-by">
                    <input-field class="input" label="Company" :required="true" v-model="form.company"
                        @error-message="onError" :validationType="ValidationTypeEnum.Required"
                        errorText="Company is required" />
                    <input-field v-if="shortForm" class="input" label="Company Website" v-model="form.website" />
                    <input-field v-else class="input" label="Company Website" :required="true" v-model="form.website"
                        @error-message="onError" :validationType="ValidationTypeEnum.Required"
                        errorText="Company website is required" />
                </div>
                <div class="form-side-by">
                    <select class="input error-margin" name="cloud" id="cloud" v-model="form.vendor">
                        <option :value="null" disabled>Cloud</option>
                        <option v-for="vendor in $local.vendors.filter(c => c.internal)" :key="vendor._id"
                            :value="vendor.name">{{ vendor.name
                            }}</option>
                    </select>
                    <select class="input error-margin" name="region" id="region" v-model="form.region">
                        <option :value="null" disabled>Geographic Region</option>
                        <option v-for="region in $local.websiteSettings.region" :key="region._id" :value="region.name">
                            {{ region.name }}</option>
                    </select>
                </div>
                <div class="form-side-by">
                    <select class="input big error-margin" name="interested" id="interested" v-model="form.interested">
                        <option :value="null" disabled>Interested In</option>
                        <option v-for="interest in $local.websiteSettings.interested" :key="interest._id"
                            :value="interest.name">{{ interest.name }}</option>
                    </select>
                </div>

                <textarea placeholder="Comments" class="input big tall error-margin" v-model="form.comments"></textarea>
                <div class="form-btn-wrap">
                    <input :disabled="formStatus === 'sending' || this.hasFalseProp" type="submit" value="Submit"
                        class="btn forum lowcase" />
                </div>
                <div class="error" v-if="formStatus == 'fail'">
                    <p>An error occurred while submitting the form</p>
                </div>
            </div>
        </form>
        <div class="form-bg tnx" :class="extraClass || ''" v-if="formStatus == 'success'">
            <p>
                <span class="new-line">Thank you!</span> Your submission has
                been received!
            </p>
        </div>
    </div>

</template>

<script>
import { buildPath } from "@/utils/buildPath";
import axios from 'axios';

export default {
    props: {
        country: String,
        template: String,
        extraClass: String,
        thankYou: String,
        shortForm: Boolean,
        sourcePage: String
    },
    components: {

    },
    data: () => ({
        formStatus: "",
        form: {
            fullName: "",
            lastName: "",
            email: "",
            company: "",
            phone: "",
            website: "",
            interested: null,
            vendor: null,
            region: null,
            comments: "",
            sourcePage: ""
        },
        ValidationTypeEnum: {
            AlphaBet: /^[a-zA-Z ]+$/,
            Email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
            Phone: /^(\+)?(?:[0-9-()/.\s?]){6,15}[0-9]{1}$/,
            Required: /.+/
        },
        hasFalseProp: false,
        formErrors: {},
    }),
    mounted() {
        if (this.$route.path.split("/").pop() === "thank-you") {
            this.formStatus = "success";
        }

        this.setFalseProp();
    },
    methods: {
        buildPath,
        onError(errorObj) {
            this.formErrors[errorObj.label] = errorObj.validation;

            this.setFalseProp();
        },
        setFalseProp() {
            this.hasFalseProp = Object.values(this.formErrors).includes(false);
        },
        async sendForm() {
            try {
                this.setFalseProp();
                if (this.hasFalseProp) {
                    return; // Stop the form submission if there are errors
                }

                if (this.form.vendor) {
                    let v = this.$local.vendors.find((v) => v.name === this.form.vendor);
                    this.form.vendor = [{ itemRefID: v._id }];
                } else {
                    delete this.form.vendor;
                }

                if (!this.template) { // contact us page etc.(not delivery). 
                    this.template = '63f34bf297e6f78495e10b19';  // default template  *** TODO: send this from the relevant pages
                }

                this.form.sourcePage = this.sourcePage; // if sent
                this.formStatus = "sending";

                await this.$db.collection("contact").post(this.form);

                await axios.post(
                    '/.netlify/functions/send-email?template=' + this.template,
                    { item: this.form }
                );


                this.formStatus = "success";
                if (!this.template)
                    this.$router.push(this.buildPath(`/contact/thank-you`));
                else {
                    if (this.thankYou)
                        this.$router.push(this.buildPath('/' + this.thankYou));
                }

            } catch (err) {
                console.log(err);
                this.formStatus = "fail";
            }
        },
    },
    name: "ContactForm",
};
</script>
<style>
.error-margin {
    margin-bottom: 17px;
}
</style>