<template>
  <span class="number-roll" ref="number"></span>
</template>

<script>
export default {
  name: "rollingNumbers",
  props: {
    value: Number,
    delay: {
      type: Number,
      default: () => 800,
    },
  },
  data() {
    return {
      stat: null,
      currNum: null,
    };
  },
  mounted() {
    this.stat = this.$refs.number;
    this.setup(this.value);
  },
  methods: {
    scrollNumber(digits) {
      this.stat.querySelectorAll("span[data-value]").forEach((tick, i) => {
        tick.style.transform = `translateY(-${100 * parseInt(digits[i])}%)`;
      });
    },
    removeDigit() {
      const firstDigit = this.stat.lastElementChild;
      firstDigit.classList.remove("visible");
      setTimeout(() => firstDigit.remove(), 2000);
    },
    addDigit(digit, fresh) {
      const spanList = Array(10)
        .join(0)
        .split(0)
        .map((x, j) => `<span>${j}</span>`)
        .join("");

      this.stat.insertAdjacentHTML(
        "beforeend",
        `<span style="transform: translateY(-1000%)" data-value="${digit}">
        ${spanList}
      </span>`
      );

      const firstDigit = this.stat.lastElementChild;
      setTimeout(
        () => {
          firstDigit.className = "visible";
        },
        fresh ? 0 : 2000
      );
    },
    setup(startNum) {
      const digits = startNum.toString().split("");
      const overshoot = (startNum + 5).toString().split("");
      for (let i = 0; i < digits.length; i++) this.addDigit("0", true);

      this.scrollNumber(["0"]);
      setTimeout(() => {
        setTimeout(() => {
          this.scrollNumber(overshoot);
        }, 400);
        setTimeout(() => this.scrollNumber(digits), 1400);
        this.currNum = startNum;
      }, this.delay);
    },
  },
};
</script>

<style>
.number-roll {
  position: relative;
  display: flex;
  margin-right: 0.2em;
  width: auto;
  overflow: hidden;
  height: 50px;
  transition: width 1.8s ease;
}

.number-roll > span {
  display: flex;
  text-align: center;
  flex-direction: column;
  opacity: 0;
  flex-shrink: 2;
  position: absolute;
  right: 0;
  line-height: 50px;
  transition: all 2s ease;
}

.number-roll > span.visible {
  position: static;
  width: auto;
  opacity: 1;
  flex-shrink: 1;
}
</style>
