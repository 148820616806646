<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="services-wrap">
      <!-- <p class="service-huge-num">02</p> -->
      <div class="services-top-wrap">
        <div class="service-frame pink">
          <div class="service-frame-txt-wrap">
            <h1 class="txt54">Course Catalog</h1>
            <h2 class="service-sub">
              As the leading technology skilling institute for decades, Sela
              skilling as a service provides our clients with continuously
              skilled and guided talent on premise, and upskills our clients’
              team to strengthen their capabilities.
            </h2>
            <!--               <p class="bold nomarg">
                Sela cloud-skills talent for the industry, providing thousands of
                people with their leap into the coveted cloud industry.
              </p>
 -->
          </div>
          <img src="../assets/courseCatalogCover.jpg" class="service-image" />
        </div>
      </div>
      <div class="service-list border-pink">
        <div class="courses-and-title-wrapper">
          <h3 class="courses-small-head">
            Our Fields of Expertise
          </h3>
          <h3>
            Search for a Course
          </h3>
          <div class="search-bar">
            <input v-model="searchTerm" type="text" placeholder="Type keywords" />
            <button class="btn" @click="performSearch">
              <p>Search</p>
            </button>
          </div>

          <div v-if="noResults" class="popup">
            <div class="popup-content">
              <div @click="closeSearchResults" class="close-icon"></div>
              <div class="popup-wrapper">
                <h2 class="center">No results found</h2>
              </div>
            </div>
          </div>

          <div v-if="showAll" class="popup">
            <div class="popup-content">
              <div @click="closeSearchResults" class="close-icon"></div>
              <div class="popup-wrapper">
                <h2 class="center">Search results for <div>"{{ searchTerm }}"</div>
                </h2>
                <div class="results-wrapper">
                  <div class="results-column">
                    <div v-for="(result, index) in searchResults.slice(0, Math.ceil(this.searchResults.length / 2))"
                      :key="index">
                      <routerLink :to="buildPath(`/coursecatalog/c/${getCode(result.link)}`)">
                        <strong>{{ result.title }}</strong>
                        <p>{{ result.snippet }}</p>
                      </routerLink>
                    </div>
                  </div>
                  <div class="results-column">
                    <div v-for="(result, index) in searchResults.slice(Math.ceil(this.searchResults.length / 2))"
                      :key="index">
                      <routerLink :to="buildPath(`/coursecatalog/c/${getCode(result.link)}`)">
                        <strong>{{ result.title }}</strong>
                        <p>{{ result.snippet }}</p>
                      </routerLink>
                    </div>
                  </div>
                </div>
                <div class="popup-btn-wrapper btn-padding">
                  <input type="button" value="Previous Page"
                    :class="{ 'btn mt30px lowcase': isPrevPage, 'visibility-hidden btn mt30px lowcase': !isPrevPage }"
                    @click="prevRequest" />
                  <p class="page-number">{{ currentPage }}</p>
                  <input type="submit" value="Next Page"
                    :class="{ 'btn mt30px lowcase': isNextPage, 'visibility-hidden btn mt30px lowcase': !isNextPage }"
                    @click="nextRequest">
                </div>
              </div>
            </div>
          </div>

          <br>
          <br>
          <h3>Or pick a topic from the following list</h3>
          <div class="courses-list-wrapper">
            <div v-for="course in orderedTopic" :key="course.title">
              <router-link class="btn-course" :to="buildPath('/coursecatalog/' + course.slug)">
                <p class="bold nomarg scrollMargin" :id="course.Id">{{ course.Name }}</p>
              </router-link>
            </div>
          </div>

          <div class="sdp-banner">
            <h4>Are you a client of Sela Cloud services? <br />Great, contact us for a special rate!</h4>

            <a class="btn  lowcase" href="contact">Contact Us</a>
            <br /><br /><br /><br />
            <h4>It's time to register to the upcoming <a href="https://seladeveloperpractice.com/" target="_blank"
                class="read-more">SELA TECHNOLOGY
                PRACTICE</a>!</h4>
            <a href="https://seladeveloperpractice.com/" target="_blank"><img class="btn-img"
                src="../assets/banners/sdp.jpg" /></a>



          </div>

        </div>

      </div>
    </div>
    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import topicsOrder from "@/data/topicsOrder.json";
import axios from "axios";
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

const netlifyFunction = '/.netlify/functions/googleSearch';
export default {
  props: {
    country: String
  },
  data() {
    return {
      searchTerm: "",
      searchResults: [],
      isDropdownOpen: false,
      showAll: false,
      googleSearchTerm: {
        q: "",
        page: 1
      },
      isNextPage: false,
      isPrevPage: false,
      currentPage: 1,
      noResults: false,
    }
  },
  components: {
    Topnav,
    Botfooter,
  },

  name: "Courses",
  computed: {
    orderedTopic() {
      return this.setOrder();
    },
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("coursecatalog"),
        description : getMetadesc("coursecatalog")
      })
    };
  },  
  methods: {
    buildPath,
    setOrder() {
      this.$local.courses.forEach((x) => {
        let tmp = topicsOrder.find((y) => y.Id === x.Id);
        if (tmp) x.order = tmp.order;
        else x.order = this.$local.courses.length;
      });
      return this.$local.courses.sort((a, b) => a.order - b.order);
    },
    getCode(link) {
      const urlParts = link.split('Info?');
      const urlParams = new URLSearchParams(urlParts[urlParts.length - 1]);
      return urlParams.get('courseCode') || urlParams.get('CourseCode');
    },
    async performSearch() {
      if (!this.searchTerm) return;
      this.googleSearchTerm.q = this.searchTerm;
      this.isDropdownOpen = true;
      const response = await axios.get(
        netlifyFunction,
        {
          params: {
            q: this.googleSearchTerm.q,
            page: this.googleSearchTerm.page
          }
        }
      );
      if (response.data.searchInformation.totalResults == 0) {
        this.noResults = true;
        return;
      }
      this.showAll = true;
      if (response.data.queries.nextPage)
        this.isNextPage = true;

      this.searchResults = response.data.items;
    },
    async nextRequest() {
      const response = await axios.get(
        netlifyFunction,
        {
          params: {
            q: this.googleSearchTerm.q,
            page: this.googleSearchTerm.page += 10
          }
        }
      );
      this.currentPage += 1;
      this.isPrevPage = true;
      if (response.data.queries.nextPage)
        this.isNextPage = true;
      else this.isNextPage = false;

      this.searchResults = response.data.items;
    },
    async prevRequest() {
      const response = await axios.get(
        netlifyFunction,
        {
          params: {
            q: this.googleSearchTerm.q,
            page: this.googleSearchTerm.page -= 10
          }
        }
      );
      this.currentPage -= 1;
      this.isNextPage = true;
      if (response.data.queries.previousPage)
        this.isPrevPage = true;
      else this.isPrevPage = false;

      this.searchResults = response.data.items;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    showAllResults() {
      this.showAll = !this.showAll;
    },
    handleBlur() {
      this.isDropdownOpen = false;
    },
    closeSearchResults() {
      this.showAll = false;
      this.noResults = false;
    }
  }
};
</script>

<style>
.sdp-banner {
  text-align: center;
  margin: 100px auto;
}

.btn-img {
  border-radius: 10px;
  transition: all 200ms ease;
  border: 1px solid black;
}

.btn-img:hover {
  filter: brightness(120%);
}

.courses-list-wrapper {
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
  direction: ltr;
  justify-items: center;
  gap: 3rem;
  font-size: 18px;
}

.courses-and-title-wrapper {
  margin-top: -3rem;
  margin-bottom: 6rem;
}

.courses-small-head {
  color: var(--pink);
  font-size: 28px;
  line-height: 1.4;
  margin-top: 0px;
  font-size: 2.5rem;
  text-align: center;
}

.search-bar {
  display: flex;
  justify-content: space-around;
  background-color: #f4f3f3;
  margin-bottom: 5px;
  line-height: 1.5rem;
}

.search-bar input {
  width: 100%;
  border: none;
  background-color: #f4f3f3;
}

.search-bar button {
  padding: 0px;
}

.btn p {
  text-transform: capitalize;
  padding: 5px;
}

.course-link {
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: var(--black);
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 50px;
  margin-top: 5px;
  border: 1px solid var(--black);
}

.course-link span {
  text-align: center;
}

.result-title:hover {
  filter: brightness(80%);
}

.course-link button {
  background: var(--black);
  max-width: 20%;
  padding: 0px 0px;
  color: White;
}

.pop-up-results-content {
  display: flex;
  flex-direction: column;
}

.results-wrapper {
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}

.results-column {
  flex: 1;
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
  gap: 2rem;
}

.results-column div:hover {
  background-color: #f4f3f3;
}

.results-column div {
  padding: 10px;
}

.results-wrapper div a {
  color: var(--black);
  text-decoration: none;
}

.page-number {
  text-align: end;
  margin-top: 50px;
}

.visibility-hidden {
  visibility: hidden;
}

.btn-course {
  background: var(--black);
  text-decoration: none;
  color: White;
  padding: 12px 30px;
  transition: 200ms all ease;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
  width: 370px;

}

.btn-course:hover {
  filter: brightness(80%);
}

@media (max-width: 912px) {
  .courses-list-wrapper {
    display: flex;
    flex-direction: column;
    direction: ltr;
    justify-items: center;
    gap: 3rem;
    font-family: Noto Sans Hebrew, sans-serif !important;
    font-size: 18px;
    margin-bottom: 40px;
  }

  .btn-padding {
    padding: inherit;
  }

  .btn-padding input {
    padding: 5px;
  }

  .courses-small-head {
    color: var(--pink);
    font-size: 28px;
    line-height: 1.4;
    margin-top: 50px;
    font-size: 2.5rem;
    text-align: center;
  }

  .btn-course {
    background: var(--black);
    text-decoration: none;
    color: White;
    padding: 12px 30px;
    text-transform: uppercase;
    transition: 200ms all ease;
    border: none;
    cursor: pointer;
    margin-top: 5px;
    width: 95%;
  }

  .btn-course:hover {
    background: var(--pink);
    text-decoration: none;
    color: White;
    padding: 12px 30px;
    text-transform: uppercase;
    transition: 200ms all ease;
    border: none;
    cursor: pointer;
    margin-top: 30px;

  }
}
</style>