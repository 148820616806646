<template >
  <div>
    <topnav :country="country"></topnav>
    <div class="event-page-wrap" :class="job.type.color">
      <div class="event">
        <p class="job-type">{{ job.type.value }}</p>
        <h1 class="job-name career-page">{{ job.name }}</h1>
        <p class="job-loc career-page">{{ job.location }}</p>
        <div class="event-txt career-page">
          <div
            class="Box_TitlePer"
            v-html="job.details"
          >
          </div>
        </div>
        <div class="event-signup">
          <form
            @submit.prevent="sendForm"
            v-if="formStatus != 'success'"
          >
            <p class="signup-title">Apply to this position
            </p>

            <input
              placeholder="Full Name*"
              required="required"
              type="text"
              class="event-input"
              v-model="form.name"
            />
            <input
              placeholder="Email Address*"
              required="required"
              type="email"
              class="event-input"
              v-model="form.email"
            />

            <input
              placeholder="Phone Number*"
              required="required"
              type="tel"
              class="event-input"
              v-model="form.phone"
            />
            <input
              placeholder="Comment"
              required="required"
              type="text"
              class="event-input"
              v-model="form.comment"
            />
            <image-ref v-model="cv" placeolder="CV" />
            <!-- <input
              name="cv"
              type="file"
              class="cv-input"
              @input="setFile"
            /> -->
              <br>
            <input
              type="submit"
              value="Submit"
              class="btn event-btn lowcase"
            />
            <div
              class="error"
              v-if="formStatus == 'fail'"
            >
              <p>An error occurred while submitting the form</p>
            </div>
          </form>
          <div
            class="event-tnx"
            v-if="formStatus == 'success'"
          >
            <p class="tnx-head">
              Thank you!</p>
            <p>
              Your submission has been
              received!
            </p>
          </div>
        </div>
      </div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>


<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import ImageRef from '../components/imageRef.vue';
export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
    ImageRef,
  },
  data: () => ({
    formStatus: "",
    form: {
      name: "",
      email: "",
      phone: "",
      comment: "",
      company: "",
    },
    cv: {},
  }),
  computed: {
    job() {
      const { id } = this.$route.params
      return this.$local.careers?.find(c => c._id == id) || {}
    }
  },
  methods: {
    async sendForm() {
      try {
        const { data } = await this.$db.collection("jobApplicants").post({ ...this.form, job: [{ itemRefID: this.job._id }], location: this.job.location });
        if (this.cv?.selectedImage) await this.$db.collection("jobApplicants").item(data.id).uploadImage("cV", this.cv);
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.job.metaTitle,
        description : this.job.metaDescription
      }),
    };
  },  
  
  name: "Career Page"
};
</script>