<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="tech-head">
      <h1>{{ service.title }}</h1>
      <img
        v-if="service.badge && service.badge.imageURL"
        class="cert-logo"
        :src="service.badge.imageURL"
        :alt="service.title"
      />
    </div>
    <div class="w700 lighter" v-html="service.body"></div>

    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";

export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.service.metaTitle,
        description : this.service.metaDescription
      })
    };
  },    
  computed: {
    service() {
      const pathArr = this.$route.path.split("/");
      const slug = pathArr.pop();
      const vendorSlug = pathArr.pop();
      const vendor = this.$local.vendors.find((v) => v.slug === vendorSlug);
      const service = vendor.pages.find((p) => p.slug === slug);
      return service;
    },
  },
  name: "GoogleCloud",
};
</script>
 
<style>

/* for AWS Well Architected Review page */
 .setup_inner {
    max-width: 830px;
    margin: 50px auto 0px;
}

.setup_inner .setup_item {
    align-items: center;
    padding: 30px 0px;
    position: relative;
}

.setup_img {
    text-align: center;
}

.setup_item .round {
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 10px 30px 0px rgba(1, 1, 64, 0.08);
    width: 80px;
    height: 80px;
    text-align: center;
    margin: 0 auto;
    font-size: 30px;
    color: #29657F;
    font-weight: 500;
    line-height: 80px;
}

.setup_item:first-child .line {
    top: 50%;
    height: 50%;
}

.setup_item .line {
    position: absolute;
    left: 50%;
    top: 0;
    width: 0;
    height: 100%;
    border-right: 2px dashed #94a2ce;
    opacity: 0.3;
    margin-left: -1px;
    z-index: -1;
}


.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.itemrow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-2, .col-md-5 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


@media screen and (max-width: 768px) {
  .setup_inner .setup_item .line {
      display: none;
  }

  .setup_inner .setup_item .round {
      margin-top: 50px;
      margin-bottom: 30px;
  }

  .setup_inner .setup_content {
    text-align: center;
    padding-left: 0;
    max-width: 350px;
    margin: 0 auto;
  }  
}


@media screen and (min-width: 768px) {
  .col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
  }
  .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
  }
}
/* end of: for AWS Well Architected Review page */
</style>
