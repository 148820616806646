<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="w1170">
      <div class="contact-box">
        <h1 class="txt54">Contact Us</h1>

        <div class="cont-info-form-wrap">
          <div class="cont-info">
            <div
              class="cont-item"
              v-for="branch in $local.branches"
              :key="branch._id"
            >
              <h3>
                <span v-if="branch._id === '63760c9a5c17be0033d7d88c'">HQ</span>
                {{ branch.name }}
              </h3>
              <div v-html="branch.address"></div>
            </div>
          </div>
            <ContactForm></ContactForm>
        </div>
      </div>
    </div>

    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";
import ContactForm from "@/components/ContactForm.vue";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
    ContactForm
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("contact"),
        description : getMetadesc("contact")
      })
    };
  }, 
  data: () => ({
 
  }),
  mounted() {

  },
  methods: {
    buildPath,
  },
  name: "Contact",
};
</script>
<style>

.error-margin{
  margin-bottom: 17px;
}
</style>
