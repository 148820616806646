var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('topnav',{attrs:{"country":_vm.country}}),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"val-sec w1170"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"val-frame-bot"}),_c('router-link',{staticClass:"btn",attrs:{"to":_vm.buildPath('contact')}},[_vm._v("Sounds good? Join us!")])],1),_c('div',{staticClass:"clients"},[_c('div',{staticClass:"client-gray-box"},[_c('h3',{staticClass:"txt54 mb60px"},[_vm._v("What Clients Say")]),_c('testimonials',{attrs:{"showDots":true,"showLogo":true}})],1)]),_vm._m(5),_c('Awards'),_c('botfooter',{attrs:{"country":_vm.country}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-hero"},[_c('h1',{staticClass:"txt45 lighter"},[_vm._v(" We empower companies to best leverage their clouds with tailored managed services, skilling, consulting, and empowering 360° support ")]),_c('div',{staticClass:"about-top-frame"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-sec"},[_c('div',{staticClass:"w1170"},[_c('div',{staticClass:"why-txt-box"},[_c('div',{staticClass:"why-line"}),_c('h2',{staticClass:"txt54"},[_vm._v("Why We’re Here")]),_c('p',{staticClass:"why-bigger-txt"},[_c('b',[_vm._v("Cloud")]),_vm._v(" done well can be any company’s competitive advantage. ")]),_c('p',{staticClass:"why-bigger-txt"},[_c('b',[_vm._v("Cloud")]),_vm._v(" done poorly can be any company’s downfall. ")]),_c('p',{staticClass:"lighter"},[_vm._v(" The cloud created new competitive paradigms for service, speed, cost and more. This is why cloud done well can be any company’s competitive advantage, and cloud done poorly can be any company’s downfall. ")]),_c('p',{staticClass:"lighter"},[_vm._v(" Cloud also brought great challenges beyond any company’s core competency - with dynamic complex devops, secops and finops. ")]),_c('p',{staticClass:"lighter"},[_vm._v(" Sela deeply understands clouds to best leverage how they behave, interact and integrate, continuously skilling cloud talent for our clients and inside our client teams, empowering companies with agile, tailored, unbiased multi-cloud services and skilling, to cloud better and win. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"what-sec"},[_c('h3',{staticClass:"txt54"},[_vm._v("What We Do Best")]),_c('div',{staticClass:"side-dot-wrap"},[_c('div',{staticClass:"side-dots-box"},[_c('div',{staticClass:"side-dot yellow"}),_c('p',{staticClass:"txt30 lighter"},[_vm._v("Managed Services")])]),_c('div',{staticClass:"side-dots-box"},[_c('div',{staticClass:"side-dot pink"}),_c('p',{staticClass:"txt30 lighter"},[_vm._v("Cloud(s) Skilling")])]),_c('div',{staticClass:"side-dots-box"},[_c('div',{staticClass:"side-dot"}),_c('p',{staticClass:"txt30 lighter"},[_vm._v("360 Support")])])]),_c('div',{staticClass:"what-box"},[_c('div',{staticClass:"what-side-bar"},[_c('h3',{staticClass:"txt54"},[_vm._v("Sela Understands Clouds… Deeply")]),_c('div',{staticClass:"what-txt lighter"},[_c('p',[_vm._v(" The cloud is a complex character. In fact, several complex characters. Continuously changing, growing, adapting. Interacting. Communicating. ")]),_c('p',[_vm._v(" It – they - can be volatile and unpredictable. They can be influenced by factors beyond their initial intention. They can be influenced by each other. Just like people and communities. ")]),_c('p',[_vm._v(" Some clouds are good for some purposes, and others for others. Just like people. ")]),_c('p',[_vm._v(" We understand their advantages and limitations, and how to work with them. ")]),_c('p',[_vm._v(" We appreciate them and their incredible magical strengths. And understand their limitations. ")]),_c('p',[_vm._v(" We build, nurture, optimize clouds and hold them accountable. So you don’t need to. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"val-frame"},[_c('p',{staticClass:"txt40 lighter"},[_vm._v("Our Team & Services "),_c('br'),_vm._v("Represent Our")]),_c('h3',{staticClass:"txt54 nomarg"},[_vm._v("VALUES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"val-list"},[_c('div',{staticClass:"val-box"},[_c('div',{staticClass:"val-icon"},[_c('img',{attrs:{"src":require("../assets/val-icon-1.svg")}})]),_c('p',{staticClass:"val-head"},[_vm._v("Customer driven")]),_c('p',{staticClass:"val-txt"},[_vm._v(" unbiased solutions, priorities & 360 support ")])]),_c('div',{staticClass:"val-box"},[_c('div',{staticClass:"val-icon"},[_c('img',{attrs:{"src":require("../assets/val-icon-2.svg")}})]),_c('p',{staticClass:"val-head"},[_vm._v("Professional")]),_c('p',{staticClass:"val-txt"},[_vm._v(" consulting, skilling, managed services & support ")])]),_c('div',{staticClass:"val-box"},[_c('div',{staticClass:"val-icon"},[_c('img',{attrs:{"src":require("../assets/val-icon-3.svg")}})]),_c('p',{staticClass:"val-head"},[_vm._v("Guiding")]),_c('p',{staticClass:"val-txt"},[_vm._v("trusted advisors with knowledge-sharing DNA")])]),_c('div',{staticClass:"val-box"},[_c('img',{attrs:{"src":require("../assets/val-icon-4.svg")}}),_c('p',{staticClass:"val-head"},[_vm._v("Agile")]),_c('p',{staticClass:"val-txt"},[_vm._v("pace & adaptability")])]),_c('div',{staticClass:"val-box"},[_c('div',{staticClass:"val-icon"},[_c('img',{attrs:{"src":require("../assets/val-icon-5.svg")}})]),_c('p',{staticClass:"val-head"},[_vm._v("Data-driven")]),_c('p',{staticClass:"val-txt"},[_vm._v("cloud economics & accountability")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pride-sec"},[_c('div',{staticClass:"pride-frame"},[_c('div',{staticClass:"pride-head-wrap"},[_c('h4',{staticClass:"txt54 nomarg mb60px mt30px"},[_vm._v(" Decades of Pride & Legacy in Our New Brand ")]),_c('img',{attrs:{"src":require("../assets/tech1.jpg")}})]),_c('div',{staticClass:"pride-txt-wrap"},[_c('p',[_vm._v(" Sela has pioneered technology industries for decades, always seeing and filling the next need. ")]),_c('p',[_vm._v(" We continue to capitalize on this legacy where the industry needs us most – enabling the companies building our future to focus on their magic, knowing that we got their cloud. ")]),_c('p',[_vm._v(" Our new brand conveys our customer-centric commitment to provide the agile, tailored, solutions that best leverage cloud technologies to achieve every customers’ unique business goals. ")]),_c('p',[_vm._v(" We are here to bring unbiased, innovative multi-cloud services, and to empower our customers and the industry at large with cloud-skilled who will enable companies to best leverage their cloud(s) to grow, improve and win in their markets. ")]),_c('p',[_c('b',[_vm._v("Cloud better with Sela.")])])])])])
}]

export { render, staticRenderFns }