<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="services-wrap">
      <p class="service-huge-num">03</p>
      <!--<div class="services-top-wrap">
        <div class="service-frame">
          <div class="delivery-txt-wrap">
            <h1 class="txt54">{{ deliveryPage.title }}</h1>
            <p class="service-sub">
              {{ deliveryPage.blurb }}
            </p>
          </div>
          <img :src="deliveryPage.fixedCover.imageURL" class="delivery-image" />
          <div class="partial-frame">
            <a class="btn mt30px lowcase lets-connect" href="#contactForm" type="button">Let's connect</a>
          </div>
        </div>
      </div> -->

      <div class="services-top-wrap">
        <div class="service-frame border-blue">
          <div class="service-frame-txt-wrap">
            <h1 class="txt54">{{ deliveryPage.title }}</h1>
            <p class="service-sub">
              <!-- {{ deliveryPage.blurb }} -->
              <!-- The Delivery division provides consulting services to top global companies utilizing leading-edge
              technologies to develop multi-discipline projects. Sela can manage all or part of a client's product on or
              off-premise, offshore or near-shore. For customers who prefer on-premise services, Sela provides staff
              augmentation services using highly experienced development staff. -->
              Sela can enhance your team with top-tier talent!
            </p>
          </div>
          <img alt="Let's connect" title="Let's connect" @mouseover="changeHover" @mouseleave="revertHover"
            :src="currentSrc" class="service-image clickable" @click="$router.push('#contactForm')" />
        </div>

        <div class="connect-frame">
          <a class="btn mt30px lowcase lets-connect" href="#contactForm" type="button">Let's connect</a>
        </div>
      </div>

      <div class="service-list border-blue">
        <div class="tabbed-content">
          <p>
            The Delivery division provides consulting services to top global companies utilizing leading-edge
            technologies to develop multi-discipline projects. Sela can manage all or part of a client's product on or
            off-premise, offshore or near-shore. For customers who prefer on-premise services, Sela provides staff
            augmentation services using highly experienced development staff.
          </p>
          <div class="w1000">
            <div class="tab-big">
              <div class="blue-frame" v-for="deliveryItem in deliveryPage.deliveryList.filter(item => item.blueFrame)"
                :key="deliveryItem.title">
                <h4 class="diamond-title scrollMargin" :id="deliveryItem.anchorID" :key="deliveryItem.title">{{
                  deliveryItem.title }}</h4>
                <div v-html="deliveryItem.body" :key="deliveryItem.title"></div>
              </div>

              <div class="tabbed-content"
                v-for="deliveryItem in deliveryPage.deliveryList.filter(item => !item.blueFrame)"
                :key="deliveryItem.title">
                <div class="service-item">
                  <h4 class="txt30">&nbsp;</h4>
                  <h4 class="txt30 scrollMargin" :id="deliveryItem.anchorID" :key="deliveryItem.title">{{
                    deliveryItem.title }}</h4>
                  <div v-html="deliveryItem.body" :key="deliveryItem.title"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sol-end-sec" id="contactForm">
          <ContactForm shortForm="true" sourcePage="Delivery" template="667c103ca2e82b4469265914"
            thankYou="delivery/thank-you" extraClass="dark-blue">
          </ContactForm>
        </div>

      </div>
    </div>


    <!-- <div class="sol-end-sec">
      <div class="sol-frame border-blue"></div>
      <p class="txt40 bold">Leaders Cloud Better</p>
      <p class="txt40">want to get started?</p>
      <router-link :to="buildPath('contact')" type="button" class="btn mt30px">Talk to an expert
      </router-link>
      <div class="sol-frame bot border-blue"></div>
    </div> -->

    <SocialMenu pagename="delivery" whatsappOnly="true" :phoneForWhatsapp="deliveryPage.phoneForWhatsapp"  :vendor="``" :title="`Delivery`" :color="'white'" :size="20"
      class="whatsapp-only social-menu" />

    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";
import ContactForm from "@/components/ContactForm.vue";
import SocialMenu from "../components/SocialMenu.vue";

export default {
  props: {
    country: String
  },
  created() {
    const img = new Image();
    img.src = this.hoverSrc;
  },
  mounted() {
    if (this.$route.path.split("/").pop() === "thank-you") {
      this.formStatus = "success";

      let contactElement = document.getElementById("thankYouDiv");
      console.log(`scrollToForm - old school, contactElement=` + contactElement)

      if (contactElement) {
        const y = contactElement.getBoundingClientRect().top + window.scrollY - 100;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }

      this.setFalseProp();
    }
  },
  methods: {
    buildPath,
    changeHover() {
      this.currentSrc = this.hoverSrc;
    },
    revertHover() {
      this.currentSrc = this.originalSrc;
    }
  },
  data: () => ({
    originalSrc: require("@/assets/delivery/heroPlain.png"),
    hoverSrc: require("@/assets/delivery/heroPlainHover.png"),
    currentSrc: require("@/assets/delivery/heroPlain.png")
  }),
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.deliveryPage.metaTitle,
        description:
          this.deliveryPage.metaDescription || this.deliveryPage.blurb,
        image: this.deliveryPage.cover.imageURL,
      }),
    };
  },
  computed: {
    deliveryPage() {
      return this.$local.deliveryPage;
    },
  },
  components: {
    Topnav,
    Botfooter,
    ContactForm,
    SocialMenu
  },
  name: "Delivery",
};
</script>


<style scoped>
.service-sub {
  font-size: 32px;
  line-height: 1.6;
}

.lets-connect {
  position: relative;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%) scale(1.5);
}

.services-top-wrap {
  margin-bottom: 100px;
}

.connect-frame {
  border: 0 !important;
  margin-top: 50px;
  padding-bottom: 0;
  margin-right: 230px;
}

.blue-frame {
  margin-bottom: 50px;
}

.solution-content-richtxt {
  max-width: 1000px;
  margin: 50px auto 70px;
  text-align: center;
}

.solution-content-richtxt blockquote h2 {
  max-width: 700px;
  margin: 50px auto 20px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.3;
}

.solution-content-richtxt h3 {
  text-align: left;
  clip-path: polygon(0 100%,
      100% 100%,
      100% 85%,
      95% 85%,
      95% 15%,
      100% 15%,
      100% 0,
      0 0);
  border: var(--dark-blue) 14px solid;
  padding: 30px;
  font-weight: 600;
  font-size: 40px;
  width: 380px;
  line-height: 1.3;
  float: left;
  margin-top: 0;
}

.solution-content-richtxt h4 {
  text-align: left;
  margin: 0px 0 10px 420px;
  line-height: 1.3;
  font-size: 24px;
}

.solution-content-richtxt blockquote {
  max-width: 700px;
  margin: 0px auto -20px;
  font-weight: 400;
}

.solution-content-richtxt p {
  text-align: left;
  margin: 0 0 0 420px;
  padding-bottom: 34px;
}

.solutions-schedule-call-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 6rem auto;
}

.solutions-schedule-call-wrapper h2 {
  text-align: center;
}

.solutions-schedule-call-wrapper .btn {
  max-width: 200px;
  margin-top: 20px;
}

.service-image {
  margin-right: -322px;
  margin-top: 36px;
  margin-left: 84px;
  margin-bottom: -155px;
  position: relative;
  width: 580px;
  height: auto;
}

.service-list {
  padding-top: 10px;
}


.service-item.big-link {
  position: relative;
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  transition: all 200ms ease;
  cursor: pointer;
  min-height: 70px;
  text-align: center;
  margin: 25px 16px;
  flex-basis: calc(33.33% - 32px);
  padding: 10px;
}

.big-link .bold.nomarg {
  min-height: 52px;
  margin-bottom: -10px;
}

.service-list.for-links {
  max-width: 1100px;
  width: 90%;
}

.service-item.big-link img {
  height: 70px;
  margin: 10px auto;
}

.big-link-small-txt {
  font-size: 18px;
  line-height: 1.5;
}


.service-sub {
  margin: 35px 0 10px 0;
}

@media screen and (max-width: 1300px) {
  .connect-frame {
    margin-right: auto;
  }

  .service-image {
    margin-right: -322px;
    margin-top: 160px;
    margin-left: 84px;
    margin-bottom: -155px;
    position: relative;
    width: 580px;
    height: auto;
  }

  .service-list {
    margin-top: 120px;
    padding: 0 10px 10px 30px;
  }

  .delivery-image {
    width: 300px;
  }


  .partial-frame {
    height: 700px;
    margin-right: 0;
    width: 90%;
    border: 0;
    position: inherit;
    height: auto;
  }

  .partial-frame::before,
  .partial-frame::after {
    width: 0;
  }


  .service-sub {
    font-size: 25px;
    max-width: 100%;
  }



  .delivery-txt-wrap {
    align-content: center;
    margin-bottom: 50px;
  }


}



@media screen and (max-width: 991px) {

  .txt54 {
    font-size: 40px;
  }

  .service-frame-txt-wrap {
    order: 2;
    padding: 30px 30px;
  }

  .service-frame {
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  }

  .service-image {
    order: 1;
  }

  .service-frame-txt-wrap {
    padding-top: 10px;
  }

  .lets-connect {
    transform: translateX(-50%) scale(1.3);
  }

  .solution-content-richtxt blockquote h2 {
    font-size: 30px;
  }

  .solution-content-richtxt h4 {
    margin: 30px 0 10px 0px;
  }

  .solution-content-richtxt h5 {
    margin: 0px auto 50px;
  }

  .solution-content-richtxt h3 {
    float: unset;
    text-align: center;
    padding: 25px 0;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    clip-path: polygon(100% 85%,
        95% 85%,
        95% 15%,
        100% 15%,
        100% 0%,
        0% 0%,
        0% 15%,
        5% 15%,
        5% 85%,
        0% 85%,
        0% 100%,
        100% 100%)
  }

  .solution-content-richtxt p {
    margin: 0px;
    display: block;
  }

  .service-image {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
</style>
