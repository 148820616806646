<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="services-wrap">
      <p class="service-huge-num">04</p>
      <div class="services-top-wrap">
        <div class="service-frame border-blue">
          <div class="service-frame-txt-wrap">
            <h1 class="txt54">{{ supportPage.title }}</h1>
            <h2 class="service-sub">
              {{ supportPage.blurb }}
            </h2>
            <div v-html="supportPage.details"></div>
          </div>
          <img :src="supportPage.cover.imageURL" class="service-image" />
        </div>
      </div>
      <div class="side-border">
        <router-link to="stats" class="num-link" title="Click here to see our support numbers">
          <div class="num-link-box">
            <div>
              <p class="num-link-head">Our Support Numbers</p>
              <p class="num-link-txt">
                Sela's unmatched expertise on demand<br/><strong>Click to see the numbers</strong>
              </p>
            </div>
            <img class="num-link-num" src="../assets/num.png" />
          </div>
        </router-link>

        <div class="service-list no-border">
           <div class="service-item" v-for="service in $local.supportServices" :key="service.title">
               <p class="bold nomarg">{{ service.title }}</p>
               <p class="lighter nomarg" v-html="service.body" :key="service.title"></p>
           </div>
        </div>

       
      </div>
    </div>
    <div class="sol-end-sec">
      <div class="sol-frame border-blue"></div>
      <p class="txt40 bold">Leaders Cloud Better</p>
      <p class="txt40">want to get started?</p>
      <router-link :to="buildPath('contact')" type="button" class="btn mt30px"
        >Talk to an expert
      </router-link>
      <div class="sol-frame border-blue bot"></div>
    </div>    
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  methods: {
    buildPath
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.supportPage.metaTitle,
        description:
          this.supportPage.metaDescription
      }),
    };
  },
  computed: {
    supportPage() {
      return this.$local.supportPage;
    },
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Support",
};
</script>
