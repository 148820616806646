<template >
  <div>
    <topnav :country="country"></topnav>

    <div class="privacy">
      <h1>{{page.title}}</h1>
      <div v-html="page.body"></div>
    </div>
    <div></div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Logal",
  computed:{
    page(){
      const {slug} = this.$route.params;
      return this.$local.legal?.find(l=>l.slug === slug) || {}
    }
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.page.metaTitle || "Cloud Better.",
        description: this.page.metaDescription,
      }),
    };
  },
};
</script>
