<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="inter-top">
      <div class="inter-top-frame"></div>
      <h1 class="txt54">International Presence</h1>
      <p>Serving the global market with local talent
      </p>
    </div>
    <div class="inter-location-wrap">
      <router-link v-for="branch in $local.branches" :key="branch._id" :to="buildPath(`international/${branch._id}`)"
        class="inter-loc-box">
        <img :src="branch.cover.imageURL" class="inter-loc-box-img" />
        <div class="inter-box-frame">
          <p class="inter-box-loc-head">{{ branch.name }} </p>
          <p class="lighter">{{ branch.blurb }}</p><a class="btn-box-loc">View More</a>
        </div>
      </router-link>

    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

export default {
  props: {
    country: String
  },
  methods: {
    buildPath
  },
  components: {
    Topnav,
    Botfooter,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("international"),
        description : getMetadesc("international"),
        noindex: true
      })
    };
  }, 
  name: "International"
};
</script>
