<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="blogs-header">
      <div class="blogs-top-wrap">
        <h1 class="txt80">Sela's Podcasts</h1>
        <h2 class="blogs-subtitle">Let's Talk Cloud</h2>
      </div>
    </div>

    <div class="know-list-wrap">
      <router-link v-for="blog in blogs" :key="blog._id" :to="buildPath(`/insights/${blog.slug}`)"
        class="know-item blog-item">
        <img :src="blog.cover.imageURL" class="know-item-image" />
        <p class="know-item-head">{{ blog.title }}</p>
        <p class="know-item-txt">{{ blog.blurb }}</p>
        <p class="know-item-date">{{ parseAuthor(blog.author) }} <span v-if="parseAuthor(blog.author)" class="blog-pipe">|</span> {{
          formatDate(blog._createdDate) }}</p>

      </router-link>
    </div>
    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import axios from 'axios';
//import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

export default {
  props: {
    country: String
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Knowledge",
  mounted() {
    this.form.sortBy = this.sortBy[0];

  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      link: [{ rel: "canonical", href: "https://selacloud.com/insights" }],
      ...this.seo({
        title: getMetatitle("insights"),
        description: getMetadesc("insights")
      })
    };
  },
  data: () => ({
    form: {
      sortBy: "",
      searchBy: "",
      filterByCategory: [],
      filterByVendor: []
    },
    filteredData: [],
    sortBy: [{ label: "Date (Latest first)", value: "_lastUpdatedDate", order: "desc" }, { label: "Date (Oldest first)", value: "_lastUpdatedDate_", order: "asc" }, { label: "Title (A->Z)", value: "title", order: "asc" }, { label: "Title (Z->A)", value: "title_", order: "desc" }],
    noResults: false,
    showResultsTotal: false
  }),
  methods: {
    buildPath,
    formatDate(dateString) {
      const date = new Date(dateString);
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const day = date.getUTCDate();
      const month = monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      return `${month} ${day}, ${year}`;
    },
    parseAuthor(authorString) {
      authorString = authorString || "Sela";
      return authorString?.split(",")[0]?.split('|')[0];
    },
    submitForm() {
      this.searchInsights(true);
    },
    filterBlogs(categorySlug) {
      const categoryID = this.$local.blogCategories.find(
        (c) => c.slug == categorySlug
      )._id;
      return this.$local.blogPosts.filter((b) =>
        b.category.find((c) => c.itemRefID === categoryID)
      );
    },
    async searchInsights(showResultsTotal) {
      var payload = JSON.parse(JSON.stringify(this.form));
      payload.filterByCategory = this.form.filterByCategory.map(c => c.title);

      await axios.post(
        '/.netlify/functions/filterInsights',
        payload
      ).then((response) => {
        if (response.data.length > 0) {
          this.filteredData = response.data;
          this.noResults = false;
        }
        else {
          this.noResults = true;
          this.filteredData = [];
        }
        this.showResultsTotal = showResultsTotal;
      }).catch(error => {
        console.error('An error occurred calling the filter service:', error);
      });
    },
    async clearFilter() {
      this.filteredData = [];
      this.form.filterByCategory = [];
      this.form.filterByVendor = [];
      this.form.sortBy = this.sortBy[0];
      this.form.searchBy = "";
      this.$refs.searchByInput.clearInput();
      await this.searchInsights(false);
    },
    async removeFilter(title) { // TODO: better change title to unique _id 
      if (this.form.searchBy == title) {
        this.form.searchBy = "";
        this.$refs.searchByInput.clearInput();
        return;
      }

      let i = this.form.filterByCategory.findIndex(c => c.title == title);
      if (i != -1) {
        this.form.filterByCategory.splice(i, 1);
        return;
      }
      i = this.form.filterByVendor.findIndex(c => c == title);
      if (i != -1) {
        this.form.filterByVendor.splice(i, 1);
        return;
      }
    },
    hideTotalResults() {
      this.showResultsTotal = false;
      this.noResults = false;
    }
  },
  computed: {
    blogs() {
      const { category } = this.$route.query;
      if (!category) {
        if (this.filteredData.length > 0)
          return this.filteredData;
        else if (!this.noResults)
        return this.$local.blogPosts.filter( b=>b.isPodcast);
        else
          return [];
      }
      return this.filterBlogs(category);
    },
    usedCategories() {
      var arr = this.$local.blogCategories.map((b) => {
        return {
          ...b,
          numOfPosts: this.filterBlogs(b.slug).length
        }
      });
      return arr.filter(
        (c) => this.filterBlogs(c.slug).length
      );
    },
    vendors() {
      return this.$local.vendors;
    },
    isFilterOn() {
      return this.form.searchBy || this.form.filterByCategory.length > 0 || this.form.filterByVendor.length > 0;
    },
    filteredTotal() {
      return this.filteredData.length;
    },
    activeFilters() {
      let res = [...this.form.filterByVendor, ...this.form.filterByCategory];
      if (this.form.searchBy)
        res.unshift(this.form.searchBy)
      return res;
    }
  },
  watch: {
    'form.filterByCategory': "hideTotalResults",
    'form.filterByVendor': "hideTotalResults",
    'form.sortBy': "hideTotalResults",
    'form.searchBy': "hideTotalResults"
  },
};
</script>

<style scoped>
.blogs-header {
  background-image: url(../assets/podcasts.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1920px;
  min-height: 380px;
  margin: auto;
  display: flex;
  margin-bottom: 80px;
}


.blogs-top-wrap {
  max-width: 1170px;
  margin: auto;
  text-align: center;
  border: 8px solid white;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 50px;
}

.blogs-subtitle {
  font-size: 40px;
  margin: 0 0 15px 0;
  font-weight: normal;
}

.filter-container {
  margin-bottom: 80px;
}


.blog-item {
  border-radius: 25px;
  padding: 20px;
  box-shadow: 3px 3px 8px #d3d3d3;
}

.blog-item {
  flex-basis: 29.33%;
  margin: 0 2% 40px;
}

.blog-item .know-item-head {
  border-bottom: 1px solid #2D3034;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.blog-pipe {
  padding: 10px;
}

@media screen and (max-width: 991px) {
  .blog-item {
    flex-basis: 44%;
  }

}

@media screen and (max-width: 767px) {
  .blogs-header {
    min-height: 220px;
  }

  .blogs-top-wrap {
    max-width: 90%;
    padding: 0 0;
  }

  .blogs-subtitle {
    font-size: 25px;
  }

  .blog-item {
    flex-basis: 100%;
  }
}
</style>
