<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="w1170 min">
      <h1 class="txt54 center">Site Map</h1>
      <div class="">
        <div class="map-box">
          <p class="map-small-title">Main Pages</p>
          <div class="map-item">
            <router-link :to="buildPath('')" class="map-link">Home</router-link>
            <router-link :to="buildPath('solutions')" class="map-link"
              >Solutions</router-link
            >
            <router-link :to="buildPath('skilling')" class="map-link">Skilling</router-link>
            <router-link :to="buildPath('delivery')" class="map-link">Delivery</router-link>
            <router-link :to="buildPath('support')" class="map-link">Support</router-link>
            <router-link :to="buildPath('contact')" class="map-link">Contact</router-link>
            <router-link :to="buildPath('careers')" class="map-link">Careers</router-link>
            <router-link :to="buildPath('about')" class="map-link"> About </router-link>
          </div>
        </div>
        <div class="map-box">
          <p class="map-small-title">Index Pages</p>
          <div class="map-item">
            <router-link :to="buildPath('insights')" class="map-link"
              >Cloud Insights
            </router-link>
            <router-link :to="buildPath('international')" class="map-link"
              >International</router-link
            >
            <router-link :to="buildPath('events')" class="map-link">Events</router-link>
          </div>
        </div>
        <div class="map-box">
          <p class="map-small-title">Other Pages</p>
          <div class="map-item">
            <router-link :to="buildPath('in-the-news')" class="map-link"
              >Sela In the News</router-link
            >
            <router-link :to="buildPath('team')" class="map-link">Team</router-link>
            <router-link :to="buildPath('customers')" class="map-link"
              >Customers</router-link
            >
          </div>
        </div>
        <div class="map-box">
          <p class="map-small-title">Legal</p>
          <div class="map-item">
            <router-link :to="buildPath('legal/privacy-policy')" class="map-link"
              >Privacy Policy</router-link
            >
            <router-link :to="buildPath('legal/accessibility')" class="map-link"
              >Accessibility</router-link
            >
            <router-link :to="buildPath('legal/esg')" class="map-link"
              >ESG Policy</router-link
            >            
            <router-link :to="buildPath('legal/ethical-code')" class="map-link"
              >Code of Ethics</router-link
            >                        
          </div>
        </div>
      </div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

export default {
  props: {
    country:String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("sitemap"),
        description : getMetadesc("sitemap")
      })
    };
  }, 
  methods: {
    buildPath,
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Site Map",
};
</script>
