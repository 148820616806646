<template>
  <div class="piechart">
    <div class="piechart-labels">
      <div @mouseover="activeItem = item.title" @mouseout="activeItem = -1" v-for="(item, i) in value"
        :key="'label' + i">
        <span class="piechart-dot" :style="{ background: colors[i] }"></span>
        <p class="piechart-item">{{ item.title || "General" }} <span class="pipe1">|</span> {{
        Math.round(relative(item.value)) }}% </p>
      </div>
    </div>
    <div class="prog-wrapper">
      <div v-for="(item, i) in value" :key="i" class="prog" role="progressbar"
        :aria-valuenow="cumulative(value.length - i - 1)" aria-valuemin="0" aria-valuemax="100" :style="{
        '--value': cumulative(value.length - i - 1),
        '--fg':
          activeItem === item.title
            ? colors[value.length - i - 1]
            : colors[value.length - i - 1],
      }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PieChart",
  props: {
    value: Array,
  },
  data() {
    return {
      activeItem: -1,
      colors: [
        "#0071F6",
        "#F191A1",
        "#FBDB35",
        "#00DD95",
        "#00DBE9",
        "#9d9c9c",
        "#f34d4d",
      ],
    };
  },
  methods: {
    cumulative(count) {
      let sum = 0;
      for (let i = 0; i <= count; i++) {
        sum += this.relative(this.value[i].value);
      }
      return sum;
    },
    relative(val) {
      return (val / this.totalValues) * 100;
    },
  },
  computed: {
    totalValues() {
      return this.value.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );
    },
  },
};
</script>

<style scoped>
.piechart-labels {
  flex-shrink: 0;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.piechart-item {
  display: inline;
  font-size: 20px;
  margin-left: 5px;
  font-weight: normal;
}

.prog.active {
  outline: 10px solid;
}

.piechart-dot {
  text-align: center;
  display: inline-block;
  background: black;
  color: white;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.pipe1 {
  margin: 0 5px;
}

.piechart-percentage {
  font-size: 8px;
}

.piechart {
  display: flex;
  height: 88%;
  width: 90%;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: auto;
}

.prog-wrapper {
  height: 100%;
  width: 100%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .prog::before {
  content: "";
  border: 2px solid black;
  position: absolute;
  top: -4px;
  left: -4px;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  transform: scale(0.7);
} */

.prog:after {
  content: "";
  border: 1px solid black;
  position: absolute;
  top: -4px;
  left: -4px;
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.prog {
  margin: 0 auto;
  position: absolute;
}

@keyframes growProgressBar {

  0%,
  33% {
    --pgPercentage: 0;
  }

  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 15rem;
  --bg: transparent;
  --pgPercentage: var(--value);
  animation: growProgressBar 2s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(closest-side,
      white 1%,
      transparent 0 99.9%,
      white 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
}




@media screen and (max-width: 1200px) {
  div[role="progressbar"] {
    --size: 15rem;
  }

  .piechart {
    display: flex;
    align-items: center;
    max-width: 390px;
  }

  .prog-wrapper {
    min-height: 60px;
    height: 100px;
    text-align: center;
    transform: scale(0.9);
  }
}



@media screen and (max-width: 600px) {
  div[role="progressbar"] {
    --size: 9rem;
  }

  .piechart-item {
    font-size: 14px;
  }

  .piechart-dot {
    width: 10px;
    height: 10px;
  }

  .piechart {
    width: 100%;
    max-width: 270px;
  }

  .prog-wrapper {
    padding-left: 0;
    margin-left: 10px;
  }
}

</style>
