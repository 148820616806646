import axios from 'axios';

//const getReqDataPath = 'http://localhost:8888/.netlify/functions/getReqData';
const getReqDataPath = 'https://www.sela.co.il/.netlify/functions/getReqData';
const countryMapping = [
  {
    countryShort: "US",
    countryFull: "United States"
  },
  {
    countryShort: "IL",
    countryFull: "Israel"
  },
  {
    countryShort: "IN",
    countryFull: "India"
  }
];

export async function getCountry() {
  if (currentCountry.value != "NA") {
    return currentCountry.value;
  }
  try {
    let res = await axios.get(getReqDataPath);
    console.log(res.data + " initialized");
    currentCountry.value = res.data;
    return res.data;
  }
  catch (error) {
    console.error('An error occurred calling the filter service:', error);
    return "NA";
  }
}

export async function getCountryFull() {
  const shortCountry = await getCountry();
  const countryObj = countryMapping.find( c => c.countryShort==shortCountry);
  let countryFull = null;
  if (countryObj)
    countryFull = countryObj.countryFull;
  return countryFull;
}


export function addDynamicStyle(country) {
  const style = document.createElement('style');

  if (country == "IL") {
    style.innerHTML = `
        .israelOnly {
          display:block;
        }

        .restOfWorld {
          display:none;
        }
      `;
  }
  else {
    style.innerHTML = `
        .israelOnly {
          display:none;
        }

        .restOfWorld {
          display:block;
        }
      `;
  }

  document.head.appendChild(style);
}

export let currentCountry = {
  value: "NA"
}