<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="main-hero w1170 not-found">
      <div class="main-txt-wrap">
        <img
          src="../assets/graphs-mobile-4.svg"
          class="mob-ani-1"
          alt="Sela Graphs"
        />
        <img
          src="../assets/graphs-mobile-2.svg"
          class="mob-ani-2"
          alt="Sela Graphs"
        />
        <h1 class="txt54">404 <br /></h1>
        <p class="txt20 mb30px">We can't find this page</p>
        <router-link :to="buildPath('/')" class="btn"
          >Go to homepage <img src="../assets/play.svg" alt="Play" />
        </router-link>
      </div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  methods: {
    buildPath
  },
  components: {
    Topnav,
    Botfooter,
  },
  computed: {
    futureEvents() {
      return this.$local.events.filter((e) => new Date(e.date) > new Date());
    },
  },
  data: () => ({
    tab: 1,
    bigTab: 1,
  }),
  name: "Home",
};
</script>

<style scoped>
.not-found {
  min-height: calc(100vh - 350px);
}
</style>
