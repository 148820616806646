<template>
  <p ref="observe">{{ num }}{{ suffix }}</p>
</template>

<script>
export default {
  name: "RollingNumber",
  props: {
    value: Number,
    observe: { default: () => true, type: Boolean },
    suffix: [Number, String],
  },
  data() {
    return {
      elObserve: null,
      num: Math.floor(this.value / 2),
    };
  },
  methods: {
    countUp() {
      const { value } = this;
      this.num++;
      if (this.num < value) setTimeout(this.countUp, (10 / value) * 400);
    },
    observeScroll(el) {
      this.elObserve = new IntersectionObserver(
        (entries, elObserver) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) return;
            else {
              this.countUp();
              elObserver.unobserve(entry.target);
            }
          });
        },
        { threshold: 0, rootMargin: "0px 0px 0px 0px" }
      );
      this.elObserve.observe(el);
    },
  },
  mounted() {
    if (this.observe) this.observeScroll(this.$refs.observe);
    else this.countUp();
  },
};
</script>
