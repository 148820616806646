<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="about-hero">
      <h1 class="txt45 lighter">
        We empower companies to best leverage their clouds with tailored managed services, skilling, consulting, and empowering 360&deg; support
      </h1>
      <div class="about-top-frame"></div>
    </div>
    <div class="why-sec">
      <div class="w1170">
        <div class="why-txt-box">
          <div class="why-line"></div>
          <h2 class="txt54">Why We’re Here</h2>
          <p class="why-bigger-txt">
            <b>Cloud</b> done well can be any company’s competitive advantage.
          </p>
          <p class="why-bigger-txt">
            <b>Cloud</b> done poorly can be any company’s downfall.
          </p>
          <p class="lighter">
            The cloud created new competitive paradigms for service, speed, cost
            and more. This is why cloud done well can be any company’s
            competitive advantage, and cloud done poorly can be any company’s
            downfall.
          </p>
          <p class="lighter">
            Cloud also brought great challenges beyond any company’s core
            competency - with dynamic complex devops, secops and finops.
          </p>
          <p class="lighter">
            Sela deeply understands clouds to best leverage how they behave,
            interact and integrate, continuously skilling cloud talent for our
            clients and inside our client teams, empowering companies with
            agile, tailored, unbiased multi-cloud services and skilling, to
            cloud better and win.
          </p>
        </div>
      </div>
    </div>
    <div class="what-sec">
      <h3 class="txt54">What We Do Best</h3>
      <div class="side-dot-wrap">
        <div class="side-dots-box">
          <div class="side-dot yellow"></div>
          <p class="txt30 lighter">Managed Services</p>
        </div>
        <div class="side-dots-box">
          <div class="side-dot pink"></div>
          <p class="txt30 lighter">Cloud(s) Skilling</p>
        </div>
        <div class="side-dots-box">
          <div class="side-dot"></div>
          <p class="txt30 lighter">360 Support</p>
        </div>
      </div>
      <div class="what-box">
        <div class="what-side-bar">
          <h3 class="txt54">Sela Understands Clouds… Deeply</h3>
          <div class="what-txt lighter">
            <p>
              The cloud is a complex character. In fact, several complex
              characters. Continuously changing, growing, adapting. Interacting.
              Communicating.
            </p>
            <p>
              It – they - can be volatile and unpredictable. They can be
              influenced by factors beyond their initial intention. They can be
              influenced by each other. Just like people and communities.
            </p>
            <p>
              Some clouds are good for some purposes, and others for others.
              Just like people.
            </p>
            <p>
              We understand their advantages and limitations, and how to work
              with them.
            </p>
            <p>
              We appreciate them and their incredible magical strengths. And
              understand their limitations.
            </p>
            <p>
              We build, nurture, optimize clouds and hold them accountable. So
              you don’t need to.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="val-sec w1170">
      <div class="val-frame">
        <p class="txt40 lighter">Our Team & Services <br />Represent Our</p>
        <h3 class="txt54 nomarg">VALUES</h3>
      </div>
      <div class="val-list">
        <div class="val-box">
          <div class="val-icon"><img src="../assets/val-icon-1.svg" /></div>
          <p class="val-head">Customer driven</p>
          <p class="val-txt">
            unbiased solutions, priorities &amp; 360 support
          </p>
        </div>
        <div class="val-box">
          <div class="val-icon"><img src="../assets/val-icon-2.svg" /></div>
          <p class="val-head">Professional</p>
          <p class="val-txt">
            consulting, skilling, managed services &amp; support
          </p>
        </div>
        <div class="val-box">
          <div class="val-icon"><img src="../assets/val-icon-3.svg" /></div>
          <p class="val-head">Guiding</p>
          <p class="val-txt">trusted advisors with knowledge-sharing DNA</p>
        </div>
        <div class="val-box">
          <img src="../assets/val-icon-4.svg" />
          <p class="val-head">Agile</p>
          <p class="val-txt">pace &amp; adaptability</p>
        </div>
        <div class="val-box">
          <div class="val-icon"><img src="../assets/val-icon-5.svg" /></div>
          <p class="val-head">Data-driven</p>
          <p class="val-txt">cloud economics &amp; accountability</p>
        </div>
      </div>
      <div class="val-frame-bot"></div>
      <router-link :to="buildPath('contact')" class="btn">Sounds good? Join us!</router-link>
    </div>
    <div class="clients">
      <div class="client-gray-box">
        <h3 class="txt54 mb60px">What Clients Say</h3>
        <testimonials :showDots=true :showLogo=true></testimonials>
      </div>
    </div>
    <div class="pride-sec">
      <div class="pride-frame">
        <div class="pride-head-wrap">
          <h4 class="txt54 nomarg mb60px mt30px">
            Decades of Pride &amp; Legacy in Our New Brand
          </h4>
          <img src="../assets/tech1.jpg" />
        </div>
        <div class="pride-txt-wrap">
          <p>
            Sela has pioneered technology industries for decades, always seeing
            and filling the next need.
          </p>
          <p>
            We continue to capitalize on this legacy where the industry needs us
            most – enabling the companies building our future to focus on their
            magic, knowing that we got their cloud.
          </p>
          <p>
            Our new brand conveys our customer-centric commitment to provide the
            agile, tailored, solutions that best leverage cloud technologies to
            achieve every customers’ unique business goals.
          </p>
          <p>
            We are here to bring unbiased, innovative multi-cloud services, and
            to empower our customers and the industry at large with
            cloud-skilled who will enable companies to best leverage their
            cloud(s) to grow, improve and win in their markets.
          </p>
          <p>
            <b>Cloud better with Sela.</b>
          </p>
        </div>
      </div>
    </div>
    <Awards></Awards>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Testimonials from "@/components/Testimonials.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import Awards from "@/components/Awards.vue";
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

export default {
  props: {
    country:String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("about"),
        description : getMetadesc("about")
      })
    };
  },
  components: {
    Topnav,
    Botfooter,
    Testimonials,
    Awards,
  },
  methods: {
    buildPath
  },
  data: () => ({
    tab: 1,
  }),
  name: "About",
};
</script>
