<template>
  <iframe :src="embedder(src)"></iframe>
</template>

<script>
export default {
  name: "VideoEmbed",
  props: {
    src: String,
  },
  methods: {
    embedder(url) {
      let embedUrl = url;
      if (url.match(/youtu/)) {
        let yid = url.trim().match(/([\w-]+)(&|$)/g)[0];
        yid = yid.replace(/^&|&$/, "");
        let time = "";
        if (url.match(/t=/)) {
          let tArray = url.split(/\?|&/);

          tArray.forEach((element) => {
            if (element.match(/^t/)) {
              time = "start=" + element.split("=")[1] + "&";
            }
          });
        }
        embedUrl =
          "https://www.youtube.com/embed/" + yid + "?" + time + "autoplay=1";
        console.log(embedUrl);
      } else if (url.match(/vimeo/)) {
        let regexp = /com\/(\w)*/gi;
        let vidId = url.match(regexp);
        if (vidId) {
          vidId = vidId[0].replace(/com/, "");
        }
        embedUrl = "//player.vimeo.com/video" + vidId;
        if (url.match(/player.vimeo/)) embedUrl = url;
      }
      return embedUrl;
    },
  },
};
</script>

<style scoped>
iframe {
  border: none;
}
</style>
