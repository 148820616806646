// function getMeta(vm) {
//   const { meta } = vm.$options;
//   if (meta) return typeof meta === "function" ? meta.call(vm) : meta;
//   else return {};
// }

export default {
  methods: {
    seo({ title, twitterHandle, image, description, imageCaption, noindex }) {
      const seo = {}
      const headers = []

      if (imageCaption) {
        headers.push({
          hid: "twitter:image:alt",
          name: "twitter:image:alt",
          content: imageCaption ? imageCaption.replace(/<[^>]*>/g, "") : "",
          id: "twitter:image:alt"
        });
        headers.push({
          hid: "og:image:alt",
          id: "og:image:alt",
          property: "og:image:alt",
          content: imageCaption ? imageCaption.replace(/<[^>]*>/g, "") : "",
        });
      }

      if (title) {
        seo.title = title;
        headers.push({
          hid: "twitter:title",
          id: "twitter:title",
          name: "twitter:title",
          content: title ? title.replace(/<[^>]*>/g, "") : "",
        });
        headers.push({
          hid: "og:title",
          id: "og:title",
          property: "og:title",
          content: title ? title.replace(/<[^>]*>/g, "") : "",
        });
      }
      if (twitterHandle)
        headers.push({ name: "twitter:site", content: twitterHandle, id: "twitter:site", });
      if (image) {
        headers.push({ id: "twitter:card", name: "twitter:card", content: "summary_large_image" });
        headers.push({
          hid: "twitter:image",
          id: "twitter:image",
          name: "twitter:image",
          content: image,
        });
        headers.push({
          hid: "og:image:secure_url",
          id: "og:image:secure_url",
          property: "og:image:secure_url",
          content: image,
        });
        headers.push({ id: "og:image", hid: "og:image", property: "og:image", content: image });
      }
      if (description) {
        headers.push({
          hid: "description",
          id: "description",
          name: "description",
          content: description ? description.replace(/<[^>]*>/g, "") : "",
        });
        headers.push({
          hid: "twitter:description",
          id: "twitter:description",
          name: "twitter:description",
          content: description ? description.replace(/<[^>]*>/g, "") : "",
        });
        headers.push({
          hid: "og:description",
          id: "og:description",
          property: "og:description",
          content: description ? description.replace(/<[^>]*>/g, "") : "",
        });
      }

      if (noindex) {
        headers.push({
          hid: "robots",
          id: "robots",
          name: "robots",
          content: "noindex",
        });
      }

      seo.meta = headers;
      return seo;
    }
  },
  // created() {
  //   const {defaultVals} = this.$options;
  //   let { title, twitterHandle, image, description, imageCaption } = getMeta(this);


  //   twitterHandle = twitterHandle || defaultVals.twitterHandle;
  //   image = image || defaultVals.image;

  //   const headers = [];
  //   if (title) {
  //     document.title = title;
  //     headers.push({
  //       hid: "twitter:title",
  //       name: "twitter:title",
  //       content: title ? title.replace(/<[^>]*>/g, "") : "",
  //     });
  //     headers.push({
  //       hid: "og:title",
  //       property: "og:title",
  //       content: title ? title.replace(/<[^>]*>/g, "") : "",
  //     });
  //   }
  //   if (imageCaption) {
  //     headers.push({
  //       hid: "twitter:image:alt",
  //       name: "twitter:image:alt",
  //       content: imageCaption ? imageCaption.replace(/<[^>]*>/g, "") : "",
  //     });
  //     headers.push({
  //       hid: "og:image:alt",
  //       property: "og:image:alt",
  //       content: imageCaption ? imageCaption.replace(/<[^>]*>/g, "") : "",
  //     });
  //   }
  //   if (twitterHandle)
  //     headers.push({ name: "twitter:site", content: twitterHandle });
  //   if (image) {
  //     headers.push({ name: "twitter:card", content: "summary_large_image" });
  //     headers.push({
  //       hid: "twitter:image",
  //       name: "twitter:image",
  //       content: image,
  //     });
  //     headers.push({
  //       hid: "og:image:secure_url",
  //       property: "og:image:secure_url",
  //       content: image,
  //     });
  //     headers.push({ hid: "og:image", property: "og:image", content: image });
  //   }
  //   if (description) {
  //     headers.push({
  //       hid: "description",
  //       name: "description",
  //       content: description ? description.replace(/<[^>]*>/g, "") : "",
  //     });
  //     headers.push({
  //       hid: "twitter:description",
  //       name: "twitter:description",
  //       content: description ? description.replace(/<[^>]*>/g, "") : "",
  //     });
  //     headers.push({
  //       hid: "og:description",
  //       property: "og:description",
  //       content: description ? description.replace(/<[^>]*>/g, "") : "",
  //     });
  //   }
  //   for (const header of headers){
  //     const metaEl = document.createElement('meta')
  //     for (const [key, value] of Object.entries(header)) {
  //         metaEl[key]= value;
  //       }
  //   }
  // },
};
