<template>
  <div class="bar-chart">

    <div class="bar-container">

      <div class="bar-wrap" v-for="(item, i) in topFive" :key="i">
        <div class="bar"
          :style="{ height: loaded ? `calc(${percent(item.value)}/100 * 200px)` : '0', backgroundColor: colors[i] }">
        </div>
        <p class="bar-value">{{ item.value }}</p>
      </div>

    </div>

    <div class="chart-labels">
      <div v-for="(item, i) in value" :key="'label' + i" class="label-cont">
        <span class="chart-dot" :style="{ background: colors[i] }"></span>
        <p class="chart-item">{{ item.title || "General" }}</p>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "BarChartVert",
  props: {
    value: Array,
  },
  data() {
    return {
      loaded: false,
      colors: [
        "#00DD95",
        "#FBDB35",
        "#F191A1",
        "#0071F6",
        "#00DBE9",
        "#9d9c9c",
        "#f34d4d",
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 400);
  },
  computed: {
    topFive() {
      return [...this.value].sort((a, b) => b.value - a.value).slice(0, 5);
    },
  },
  methods: {
    percent(val) {
      console.log(val);
      const max = Math.max(...this.value.map((v) => v.value));
      return (val / max) * 100;
    },
  },
};
</script>

<style scoped>
.bar-container {
  display: flex;
  min-height: 200px;
  gap: 20px;
}

.label-cont {
  display: flex;
  align-content: center;
  align-items: center;
}

.bar-txt {
  font-size: 13.5px;
  font-weight: 400;
  max-width: 95%;
  height: 20px;
  line-height: 1.2;
  margin: 5px auto 0;
}

.bar-wrap {
  text-align: center;
  margin: 0 5px;
  flex: 0 1 16.6%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: 230px;
}

.bar-wrap p {
  margin-bottom: 10px;
}

.bar {
  min-width: 10px;
  min-height: 5px;
  width: 35px;
  margin: 0 auto;
  transition: all ease-out 0.8s;
  border: 2px solid #2D3034;
}


.bar-value {
  font-size: 20px;
  margin-top: 10px;
}

.bar-chart {
  height: 85%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.chart-labels {
  display: flex;
  flex-shrink: 0;
  gap: 15px;
}

.chart-item {
  display: inline;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 400;
}

.chart-dot {
  display: inline-block;
  background: black;
  border-radius: 100%;
  height: 18px;
  width: 18px;
}

@media screen and (max-width: 767px) {
  .stats-wrap {
    display: block;
  }

  .stat-box {
    margin-bottom: 30px;
  }

  .bar-txt {
    font-size: 12px;
  }

  .bar {
    width: 32px;
  }

  .bar-chart {
    padding: 10px;
  }

  .chart-dot {
    height: 12px;
    width: 12px;
  }

  .chart-item {
    font-size: 14px;
  }

}
</style>
