<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="cloudy-sky">
      <div class="career-header">
        <h1 class="txt54">The Future Is Cloud.</h1>
        <h1 class="txt54 light">YOURS TOO.</h1>
        <a class="btn" href="#job-list">See Our Open Positions</a>
      </div>
    </div>
    <div class="about-careers">
      <div class="car-head-wrap">
        <h1 class="txt54" style="margin-top:0">
          Why Join Sela?
        </h1>
        <h2 class="sub-title light">Cloud is moving the world. We're moving the cloud.</h2>
      </div>
    </div>


    <div class="val-sec w1170">
      <div class="val-frame-careers">
        <h1 class="txt54 val-frame-header">Our Team & Services <br />Represent Our VALUES</h1>

        <div class="val-list val-list-contained">
          <div class="val-box">
            <div class="val-icon"><img src="../assets/val-icon-1.svg" /></div>
            <p class="val-head">Customer driven</p>
            <p class="val-txt">
              unbiased solutions, priorities &amp; 360 support
            </p>
          </div>
          <div class="val-box">
            <div class="val-icon"><img src="../assets/val-icon-2.svg" /></div>
            <p class="val-head">Professional</p>
            <p class="val-txt">
              consulting, skilling, managed services &amp; support
            </p>
          </div>
          <div class="val-box">
            <div class="val-icon"><img src="../assets/val-icon-3.svg" /></div>
            <p class="val-head">Guiding</p>
            <p class="val-txt">trusted advisors with knowledge-sharing DNA</p>
          </div>
          <div class="val-box">
            <img src="../assets/val-icon-4.svg" style="padding-bottom: 15px;" />
            <p class="val-head">Agile</p>
            <p class="val-txt">pace &amp; adaptability</p>
          </div>
          <div class="val-box">
            <div class="val-icon"><img src="../assets/val-icon-5.svg" /></div>
            <p class="val-head">Data-driven</p>
            <p class="val-txt">cloud economics &amp; accountability</p>
          </div>
        </div>
      </div>
    </div>

    <div class="alumni-sec w1000" v-if="$local.alumni.length">
      <p class="txt40 lighter">You'll Be Joining Good Company</p>

      <p class="txt40 lighter mt60px">
        We value creative minds, relentless problem solving, collaborative
        teamwork, exceptional customer service and an inclusive, rewarding work
        environment
      </p>
    </div>
    <div id="job-list" class="solvers-sec w1000">
      <h3 class="txt54 nomarg prob-title">Problem Solvers Needed</h3>
      <div class="fineprint">
        All positions are open to residents of the relevant country where the position is offered unless otherwise
          specified or offered as a remote opportunity.
        <br/>A valid work permit will be required for all non-citizens.
        </div>
      <div class="job-filter-wrap">
        <span>Filter by </span>
        <select name="Branch" id="Branch" class="drop-filter" v-model="selectedBranch">
          <option value="">All Locations</option>
          <option :value="branch" v-for="branch in branches" :key="branch">
            {{ branch }}
          </option>
        </select>


        <select name="Type" id="Type" class="drop-filter" v-model="selectedJobType">
          <option value="">All Departments</option>
          <option :value="jobType" v-for="jobType in jobTypes" :key="jobType">
            {{ jobType }}
          </option>
        </select>
      </div>
      <div class="no-jobs-found" v-if="!jobs.length">There are currently no open positions that match the selected
        criteria</div>

      <div id="jobs" class="jobs-list">
        <router-link :to="buildPath(`career/${job._id}`)" class="job-item" v-for="job in jobs" :key="job._id"
          :class="job.type.color">
          <p class="job-type">{{ job.type.value }}</p>
          <p class="job-name">{{ job.name }}</p>
          <p class="job-loc">{{ job.location }}</p>
        </router-link>
      </div>
    </div>
    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";
import { getCountryFull } from "../utils/geolocation";

export default {
  props: {
    country: String
  },
  components: {
    Topnav,
    Botfooter,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("careers"),
        description: getMetadesc("careers")
      })
    };
  },
  methods: {
    buildPath
  },
  data() {
    return {
      selectedJobType: "",
      selectedBranch: "",
      branches: ["United States", "Israel", "India"],
      jobTypes: [
        "General",
        "Development",
        "Engineering & Data Science",
        "Devops",
        "Cloud",
        "QAּ & Automation",
        "Technology lecturers",
        "Marketing",
        "Other",
      ],
    };
  },
  computed: {
    jobs() {
      let res = this.$local.careers;
      if (this.selectedJobType)
        res = res.filter((c) => c.type.value === this.selectedJobType);
      if (this.selectedBranch) {
        const strBranch = this.selectedBranch.toLowerCase();
        res = res.filter((c) => ("" + c.location).toLowerCase().includes(strBranch));
      }
      return res;
    },
  },
  async mounted() {
    const countryFull = await getCountryFull();

    if (countryFull)
      this.selectedBranch=countryFull;
  },
  name: "Careers",
};
</script>


<style scoped>
.fineprint {
  margin-top: 40px;
  font-weight: 300;
}

.val-list-contained {}

#job-list {
  scroll-margin-top: 100px;
}

.career-header .btn {
  background-color: unset;
  border: 5px solid white;
  margin-top: 30px;
}

.txt54 {
  font-size: 60px;
}

.career-header {
  margin: auto;
  color: white;
  width: fit-content;
  padding-top: 50px;
  text-align: center;
}

.career-header h1 {
  margin: 5px;
}

.cloudy-sky {
  background-image: url(../assets/cloudy-sky.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1080px;
  max-width: 1920px;
  margin: auto;
}


.prob-title {
  text-align: center;
}

@media screen and (max-width: 1400px) {
  .cloudy-sky {
    min-height: 900px;
    max-width: 1920px;
  }
}


@media screen and (max-width: 1024px) {
  .txt54 {
    font-size: 40px;
    line-height: 1.3;
  }

}

@media screen and (max-width: 990px) {
  .prob-title {
    text-align: left;
  }
}



@media screen and (max-width: 479px) {
  .txt54 {
    font-size: 30px;
    line-height: 1.3;
  }

  h1 {
    font-size: 32px;
  }

}
</style>
