<template>
  <div class="footer">
    <div class="w1170 footer-inner-wrap">
      <div class="footer-logo-wrap">
        <router-link :to="buildPath('/')">
          <img
            src="../assets/footer logo.svg"
            alt="Sela"
          />
        </router-link>

        <p class="footer-under-logo-txt lighter">Cloud Better.</p>
        <div class="newsletter-wrap">
          <p>Sign Up to our Newsletter</p>
          <form
            class="newsletter"
            @submit.prevent="sendForm"
            v-if="formStatus != 'success'"
          >
            <input
              placeholder="Email"
              required="required"
              type="email"
              class="newsletter-input"
              v-model="form.email"
            />
            <input
              :disabled="formStatus === 'sending'"
              type="submit"
              value=""
              class="newsletter-btn"
            />
            <div
              class="newsletter-error"
              v-if="formStatus == 'fail'"
            >
              <p>An error occurred while submitting the form</p>
            </div>
          </form>
          <div
            class="newsletter-tnx"
            v-if="formStatus == 'success'"
          >
            <p>Thank you!</p>
          </div>
        </div>
      </div>
      <div class="footer-links-wrap">
        <div
          class="footer-link-box"
          v-for="link in settings.footerLinks"
          :key="link._id"
        >
          <a
            :href="buildPath(link.link)"
            class="footer-link"
          >
            <img
              v-if="link.icon && link.icon.imageURL"
              class="accessability"
              :src="link.icon.imageURL"
              :alt="link.title"
            />
            {{ link.title }}</a>
        </div>
      </div>
      <div class="footer-soc-wrap">
        <a
          href="mailto:info@selacloud.com"
          class="footer-end-link"
        >info@selacloud.com</a>
        <a
          href="tel:+14843690439 "
          class="footer-end-link"
        >US: +1 484-369-0439</a>
        <a
          href="tel:03-6176666"
          class="footer-end-link"
        >Israel: +972 3-6176666</a>
        <div class="footer-soc-links">
          <a
            :href="social.link"
            target="_blank"
            class="soc-link"
            v-for="social in settings.socialLinks"
            :key="social._id"
          >
            <img
              :src="social.icon.imageURL"
              :alt="social.title"
            /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  data: () => ({
    formStatus: "",
    form: {
      email: "",
    },
  }),
  computed: {
    settings() {
      return this.$local.websiteSettings;
    },
  },
  methods: {
    buildPath,
    async sendForm() {
      try {
        this.formStatus = "sending";
        await this.$db.collection("newsletter").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
  name: "botfooter",
};
</script>
