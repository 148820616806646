import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import vueClickOutsideElement from 'vue-click-outside-element';
import Vuetify from 'vuetify';
//import 'vuetify/dist/vuetify.min.css'; commented because its overriding styles,breaks contact us form etc...
import Meta from 'vue-meta'
import seoMixin from './mixins/seomixin'

import InputField from './components/InputField.vue';

Vue.component('input-field', InputField);

Vue.mixin(seoMixin)
Vue.use(Meta, {ssrAppId: "sela"})
// seoMixin.defaultVals.title = 'Cloud Better.';
// seoMixin.defaultVals.titlePrefix = 'Sela.';
// seoMixin.defaultVals.image = 'https://sfo2.digitaloceanspaces.com/bagel/sela/og.jpg';


import data from '../localData.json';

Vue.use(vueClickOutsideElement);

Vue.use(VueRouter);

Vue.use(Vuetify);
import Bagel from '@bageldb/bagel-db';
const $db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);
const $dbDev = new Bagel(process.env.VUE_APP_BAGEL_TOKEN_DEV)

Vue.prototype.$db = $db;
Vue.prototype.$dbDev = $dbDev;
Vue.prototype.$local = data
//Vue.prototype.$topicId = 0; for topicList component

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');
