
/*
    add a country prefix to the given path if needed. Assuming that a "country" was defined in the page's props
*/
export function buildPath(path) {
    path = (path.indexOf("/")==0) ? path.slice(1) : path;
    if (path.indexOf("http")==0)    // external URL
        return path;
    const route = this.country ? `/${this.country}/${path}` : `/${path}`;
    return route;
}