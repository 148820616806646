<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="w1170 support-container">
      <h1 class="txt54 center">Sela Support Numbers</h1>
      <p class="txt20 center mb30px light">
        Last updated
        {{
          new Date(dashboardData._lastUpdatedDate).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "2-digit",
          })
        }}
        <br />Aggregate data from the past seven days
      </p>
      <div class="stats-first-row">
        <div class="stats-box stats-severity">
          <p class="box-text">Issues by Severity</p>
          <BarChartVert :value="dashboardData.issuesByPriority"></BarChartVert>
        </div>
        <div class="stats-totals">

          <div class="stats-box stat-time">
            <div class="time-to-container">
              <p class="box-text">Time to First Response</p>
              <p :class="isFirstResUp() ? 'pink' : 'green'" class="timeto-value">
                <img :src="require(isFirstResUp() ? '../assets/arrowUp.svg' : '../assets/arrowDown.svg')" />
                <span>
                  {{ dashboardData.timeToFirstResponse }}<br />Min
                </span>
              </p>
            </div>
          </div>

          <div class="stats-box stat-time">
            <div class="time-to-container">
              <p class="box-text">Average Time to Resolution</p>
              <p :class="isAverageUp() ? 'pink' : 'green'" class="timeto-value">
                <img :src="require(isAverageUp() ? '../assets/arrowUp.svg' : '../assets/arrowDown.svg')" />
                <span>
                  {{ dashboardData.averageTimeToResolution }}<br />Hrs
                </span>
              </p>
            </div>
          </div>

          <div class="stats-box">
            <p class="txt54 light-blue nomarg bold">
              <ScrollingNumbers :delay="0" :value="dashboardData.totalIssues" />
            </p>
            <p class="box-text">Total Tickets</p>
          </div>

          <div class="stats-box">
            <p class="txt54 light-blue nomarg bold">
              <ScrollingNumbers :delay="400" :value="dashboardData.issuesInProgress" />
            </p>
            <p class="box-text">Tickets in Progress</p>
          </div>

          <div class="stats-box">
            <p class="txt54 light-blue nomarg bold">
              <ScrollingNumbers :delay="800" :value="dashboardData.closedIssues" />
            </p>
            <p class="box-text">Resolved Tickets</p>
          </div>
        </div>
      </div>
      <div class="large-boxes">
        <div class="stats-box stat-graph">
          <p class="box-text">Issues by Service</p>
          <BarChartHoriz :value="dashboardData.ticketsByProduct" :total="dashboardData.totalIssues"></BarChartHoriz>
        </div>

        <div class="stats-box stat-pie">
          <p class="box-text">Issues by Vendor</p>
          <pieChart :value="dashboardData.issuesByCloudProvider"></pieChart>
        </div>

        <div class="stats-box quotes-cont">
          <p class="box-text">Satisfaction Feedback</p>
          <testimonials :showDots=false :showLogo="false" :showVertLine="true"></testimonials>

        </div>
        <div class="stats-box">
          <p class="box-text">Customer Satisfaction</p>
          <Guage :value="dashboardData.customerSatisfaction"></Guage>
        </div>
      </div>

      <br>

    </div>
    <div class="sol-end-sec mt60px">
      <div class="sol-frame border-lightblue"></div>
      <p class="txt40 bold">Leaders Cloud Better</p>
      <p class="txt40">want to get started?</p>
      <router-link :to="buildPath('contact')" type="button" class="btn mt30px">Talk to an expert
      </router-link>
      <div class="sol-frame border-lightblue bot"></div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "../components/Botfooter.vue";
import Topnav from "../components/Topnav.vue";
import BarChartVert from "../components/VanillaCharts/BarChartVert.vue";
import BarChartHoriz from "../components/VanillaCharts/BarChartHoriz.vue";
import Guage from "../components/VanillaCharts/Guage.vue";
import PieChart from "../components/VanillaCharts/PieChart.vue";
import ScrollingNumbers from "../components/VanillaCharts/ScrollingNumbers.vue";
import Testimonials from "@/components/Testimonials.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  name: "Stats",

  components: {
    Topnav,
    Botfooter,
    PieChart,
    Guage,
    BarChartVert,
    BarChartHoriz,
    ScrollingNumbers,
    Testimonials
  },
  computed: {
    dashboardData() {
      return this.$local.dashboardData;
    },
  },
  methods: {
    buildPath,
    isAverageUp() {
      const prev = parseFloat(this.$local.dashboardData.averageTimeToResolutionPrevious);
      const curr = parseFloat(this.$local.dashboardData.averageTimeToResolution);
      return curr > prev;
    },
    isFirstResUp() {
      const prev = parseFloat(this.$local.dashboardData.timeToFirstResponsePrevious);
      const curr = parseFloat(this.$local.dashboardData.timeToFirstResponse);
      return curr > prev;
    },
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.$local.dashboardData.metaTitle || "Cloud Better.",
        description: this.$local.dashboardData.metaDescription,
      }),
    };
  }, 
  data() {
    return {
      topIssues: [
        { title: "Kubernetis", value: 6 },
        { title: "AWS Glue", value: 1 },
        { title: "RDS", value: 7 },
        { title: "SQL", value: 3 },
        { title: "DevPOps", value: 20 },
        { title: "Azure App Service", value: 9 },
      ],
      cards: [
        {
          percent: 56,
        },
        {
          percent: 23,
        },
        {
          percent: 11,
        },
      ],
    };
  },
};
</script>

<style scoped>
.number-roll {
  width: fit-content;
  margin: auto;
  margin-top: 40px;
  color: var(--light-blue);
}

.support-container {
  --box-gap: 40px;
}

.stats-box p {
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.box-text {
  color: var(--black);
  font-size: 20px;
  text-align: center;
}

.time-to-container .box-text {
  margin: auto 0;
}


.pink {
  color: var(--pink);
}

.green {
  color: var(--green);
}

.timeto-value {
  text-align: center;
  font-size: 30px;
  margin: auto 0;
  display: flex;
}

.timeto-value span {
  width: 75px;
}

.timeto-value img {
  margin-right: 10px;
}

.stats-first-row,
.stats-totals,
.large-boxes {
  gap: var(--box-gap);
}

.stats-first-row {
  display: flex;
}

.stats-severity {
  width: calc(33.33% - (var(--box-gap))*2/3);
}

.stats-totals {
  width: calc(66.66%);
  display: flex;
  flex-wrap: wrap;
}

.stats-totals .stats-box {
  height: 180px;
}

.large-boxes {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--box-gap);
}

.stats-totals .stats-box {
  flex: 1;
}



.stats-totals>div:nth-child(-n+2) {
  /* first 2 boxes */
  flex-basis: calc(50% - (var(--box-gap)));
  display: flex;
}

.stats-box {
  border: 3px solid var(--dark-gray, #2D3034);
  background: var(--white);
  box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
}


.time-to-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 95%;
}

.time-to-container p.box-text {
  max-width: 150px;
}

.time-to-container p.timeto-value {
  max-width: 130px;
}


.stat-arrow {
  color: var(--green);
  transform: translateY(2px);
  display: inline-block;
}

.large-boxes .stats-box {
  flex: 1;
  flex-basis: calc(50% - (var(--box-gap)) - 40px);
  padding: 20px;
  padding-bottom: 50px;
}

.stat-box {
  border: 3px solid var(--dark-gray, #2D3034);
  background: var(--white);
  box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.15);

  padding: 15px 20px 30px 20px;
  position: relative;
  box-shadow: 10px 10px 0 var(--dark-blue);
}

.stat-graph {
  grid-area: 2 / 1 / 4 / 3;
  min-height: 300px;
}

.stat-pie {
  grid-area: 4 / 1 / 6 / 3;
}

.stat-speed {
  grid-area: 4 / 3 / 6 / 4;
}


.quotes-cont {
  max-width: calc(50% - (var(--box-gap)) - 26px);
}


@keyframes growProgressBar {

  0%,
  33% {
    --pgPercentage: 0;
  }

  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}



div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
}

.card {
  background: white;
  padding: 30px;
  margin: 10px;
  display: inline-block;
  border-bottom: 10px solid var(--dark-blue);
  border-right: 10px solid var(--dark-blue);
}

@media screen and (max-width: 1200px) {
  .large-boxes .stats-box {
    padding: 30px 15px;
  }

  .box-text {
    font-size: 18px;
  }

  .stats-box {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .stats-first-row {
    display: block;
  }

  .stats-wrap {
    display: block;
  }

  .stats-severity {
    width: 100%;
    display: block;
  }

  .stats-totals {
    width: 100%;
    display: block;
  }

  .large-boxes {
    display: block;
    margin-top: 0;
  }

  .quotes-cont {
    max-width: 100%;
  }

  .stat-box {
    margin-bottom: 30px;
  }

  .stats-box {
    box-sizing: inherit;
  }

  .stat-graph {
    min-height: 100px;
  }

  .large-boxes .stats-box.stat-pie {
    padding-bottom: 50px;
  }

  .txt54 {
    font-size: 50px;
  }
}
</style>
