<template>
  <div class="bar-chart">
    <div class="bar-wrap" v-for="(item, i) in topFive" :key="i">
      <p class="bar-txt">{{ item.title }}</p>
      <div class="bar-cont">
        <div class="bar" :style="{ width: loaded ? `${percent(item.value)}%` : '0' }"></div>
      </div>
      <p class="bar-value">{{ getValueFromPercent(item.value) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarChartHoriz",
  props: {
    value: Array,
    total: Number
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 400);
  },
  computed: {
    topFive() {
      return [...this.value].sort((a, b) => b.value - a.value).slice(0, 5);
    },
  },
  methods: {
    percent(val) {
      //const max = Math.max(...this.value.map((v) => v.value));
      return val;
      //return (val / max) * 100;
    },
    getValueFromPercent(percent) {
      return Math.round(percent * this.total / 100);
    }
  },
};
</script>

<style scoped>
.bar-txt {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  flex: 0 0 200px;
  text-align: left;
}

.bar-wrap {
  text-align: center;
  margin: 0 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 50px;
}



.bar-cont {
  width: 100%;
  height: 24px;
  background-color: #F4F4F4;
}

.bar {
  background: var(--light-blue);
  height: 100%;
  transition: all ease-out 0.8s;
}

.bar-value {
  font-size: 16px;
  width: 80px;
  margin-left: 10px;
}

.bar-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .stats-wrap {
    display: block;
  }

  .stat-box {
    margin-bottom: 30px;
  }

  .bar-txt {
    font-size: 12px;
    flex: 0 0 100px;
  }

  .bar {
    width: 16px;
  }

  .bar-chart {
    padding: 10px;
  }
}
</style>
