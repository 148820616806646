import Vue from 'vue';

export function getMetadata(pageName) {
    return Vue.prototype.$local.metadataSettings[0].pagesMetadata.find(p=>p.pageName==pageName);
}

export function getMetatitle(pageName) {
    return getMetadata(pageName).metaTitle;
}

export function getMetadesc(pageName) {
    return getMetadata(pageName).metaDescription;
}