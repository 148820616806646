<template>
  <div id="app">
    <Sidepop />
    <router-view />
  </div>
</template>

<script>
import Sidepop from "@/components/Sidepop.vue";
export default {
  name: "App",
  components: {
    Sidepop,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: "Cloud Better.",
        image: "https://sfo2.digitaloceanspaces.com/bagel/sela/og.jpg",
        //description: "Get the most out of your cloud(s) with tailored managed services & skilling, unbiased consulting, and empowering 360 support.",
        twitterHandle: "@selahq",
      }),
      titleTemplate: "%s | Sela.",
    };
  },
};
</script>

<style src="./main.css"></style>
<style src="./nati.css"></style>
