<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="services-wrap">
      <div class="services-top-wrap">
        <div class="service-frame blue-border">
          <div class="service-frame-txt-wrap">
            <h1 class="txt54">Nimbus Drive</h1>
            <h2 class="service-sub">
              Nimbus Drive: Secure Cloud Collaboration for Government Agencies and Regulatory Organizations
            </h2>

          </div>
          <img src="../../assets/pages-covers/NimbusDrive_Logo.png" class="cover-little-image" />
        </div>
      </div>
      <div class="service-list border-blue">
        <div>
          <h3 class="body-small-head blue">
            Overview
          </h3>
          <div class="service-item">
            <!-- <p class="bold nomarg scrollMargin">Overview</p> -->
            <p class="lighter nomarg">
              Nimbus Drive is a Google Workspace-based system designed to enable secure file sharing, management, and
              collaboration for government agencies Regulatory Organizations. The system meets the requirements of the
              Israeli Nimbus tender and stores data in the local Google Cloud datacenter within the designated country.
              Key Features: - Secure file sharing: Share files securely with internal and external users, with granular
              permissions control. - File Collaboration: Use the native Google Workspace advanced collaboration ability.
              - Centralized storage and access: Store all your files in a central location, accessible from anywhere in
              the world. - Mobile support: Access Nimbus Drive from your desktop, laptop, tablet, or smartphone. -
              User-friendly interface: Nimbus Drive is easy to use, with a user-friendly interface in Hebrew and
              English. Benefits: - Improved collaboration: Nimbus Drive makes it easy to collaborate on files with
              colleagues, both inside and outside your organization. - Increased productivity: Centralized storage and
              access to files can help you save time and be more productive. - Enhanced security: Nimbus Drive uses
              Google Cloud's security features to protect your data. - Compliance with regulations: Nimbus Drive meets
              the requirements of the Nimbus tender and stores data in a local Google Cloud datacenter within the
              designated country.
            </p>
          </div>
          <div class="service-item">
            <p class="bold nomarg scrollMargin">About managed services</p>
            <p class="lighter nomarg">
              Managed Services are fully hosted, managed, and supported by the service providers. Although you register
              with the service provider to use the service, Google handles all billing
            </p>
          </div>


          <div class="skill-gray-box mb60px">
            <div class="service-item">
              <h3 class="body-small-head blue">Pricing </h3>
              <p class="lighter nomarg">
                <strong>Subscription fee </strong>
                <br>
                This service charges USD 8000.00/year up to 1,000 users
              <ul>
                <li>Up to 1,000 users. Any additional user: $12/year</li>
              </ul>


              <strong>Cancellation and change policy</strong>
              <ul>
                <li>Your subscription fee is billed every month. </li>
                <li>Your subscription will be automatically renewed at the end of the subscription cycle. </li>
                <li>You can cancel your subscription by disabling auto-renewal. </li>
              </ul>
              </p>

              <!-- <a href="/contact" class="btn mt30px lowcase">Contact us to subscribe</a> -->
            </div>
          </div>



          <div class="skill-gray-box mb60px">
            <div class="service-item">
              <h3 class="body-small-head blue">How to purchase</h3>
              <div class="lighter nomarg">
                <strong>Option 1 - GCP Marketplace</strong>
                <br>

                <div class="steps">
                  <span>Step 1 - </span><span>
                    <a target="_blank" class="underline"
                      href="https://console.cloud.google.com/marketplace/product/nimbus-drive-417609/sela-nimbus-drive">Buy Nimbus Drive</a></span>
                </div>
                <div class="steps">
                  <span>Step 2 - </span><span>
                    <a target="_blank" class="underline"
                      href="https://console.cloud.google.com/marketplace/product/nimbus-drive-417609/sela-nimbus-drive-solution-deployment">Download the VM solution deployment</a></span>
                </div>
                <div class="steps">
                  <span>Step 3 - </span><span><a class="underline"
                      href="mailto:gcpsela@sela.co.il?subject=NimbusDrive_marketplace_deployment">Contact us for the
                      deployment</a> (we will provide you with the BYOL - Bring Your own License)
                  </span>
                </div>
                <br />

                <strong>Option 2 - Buy directly from Sela</strong>
                <br />
                <div class="steps">
                  <span>Step 1 - </span><span><a class="underline"
                      href="mailto:gcpsela@sela.co.il?subject=NimbusDrive_buy_direct">Contact us to buy the license and
                      deployment</a>
                  </span>
                </div>
              </div>

            </div>
          </div>

          <div class="mb60px">
            <strong>Terms of Service</strong>
            <br>By using this product you agree to the Sela Cloud <a target="_blank" class="underline"
              href="/legal/privacy-policy">Terms of Service.</a>

          </div>
        </div>

      </div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";

export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: "Nibmus Drive",
        description: "Nimbus Drive: Secure Cloud Collaboration for Government Agencies and Regulatory Organizations",
        image: "https://sfo2.digitaloceanspaces.com/bagel/sela/og.jpg",
      }),
    };
  },
  computed: {

  },
  data: () => ({

  }),
  name: "Nimbus",
};
</script>

<style scoped>
.steps {
  display: flex;
}

.steps span:first-child {
  white-space: nowrap;
  margin-right: 10px;
  font-weight: 300;
}

.cover-little-image {
  width: 280px;
  height: 280px;
  margin: 120px -243px -50vw 40px;
}

.underline:visited,
.underline:active {
  color: var(--black);
}

.underline {
  text-decoration: underline !important;
}

@media screen and (max-width: 1200px) {
  .service-frame {
    margin-right: 100px;
  }

  .cover-little-image {
    margin-right: -200px;
  }
}



@media screen and (max-width: 767px) {

  .service-frame {
    margin-right: 30px;
  }

  .service-list {
    padding-top: 25vw;
  }

  .cover-little-image {
    width: 35vw;
    margin: -8px 0 -40vw;
    height: auto;
    position: relative;
    right: -70%;
    top: -15px;
  }
}
</style>
