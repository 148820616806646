<template>
  <div class="guage-wrap">
    <img
      class="speed-frame"
      src="../../assets/speeder-frame.svg"
    />
    <div class="prog-wrapper">
      <div
        v-for="i in 3"
        :key="i"
        class="prog"
        role="progressbar"
        :aria-valuenow="30 * (i + 1)"
        aria-valuemin="0"
        aria-valuemax="50"
        :style="{
          '--value': (1 / 3) * 50 * i,
          '--fg': colors[i - 1],
          'z-index': 3 - i,
        }"
      ></div>

      <div
        :style="{ '--value': (value / 100) * 180 }"
        class="pointer"
      ></div>
    </div>
    <div class="guage-txt-wrap">
      <p class="guage-txt-small">0</p>
      <p class="guage-txt-big">
        {{ value }}%<br />
        <span>Satisfaction Rating</span>
      </p>
      <p class="guage-txt-small">100</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PieChart",
  props: {
    value: [Array, Number],
  },
  data() {
    return {
      colors: ["#F191A1", "#FFD80B", "#00DD80"],
    };
  },
};
</script>

<style scoped>
.speed-frame {
  position: absolute;
  z-index: 9;
  transform: translate(-6px, -6px);
}

.guage-wrap {
  padding: 30px 10%;
  position: relative;
  margin: auto;
  min-height: 250px;
  overflow: hidden;
  padding-left: calc(50% - 126px);
}

.guage-txt-big {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}

.guage-txt-big span {
  font-size: 12px;
  font-weight: 200;
}

.guage-txt-small {
  width: 35px;
  text-align: center;
}

.guage-txt-wrap {
  display: flex;
  justify-content: space-between;
  max-width: 275px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 120px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  z-index: 9;
}

@property --gPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.pointer {
  width: 6.8rem;
  background: black;
  height: 0.3rem;
  position: absolute;
  left: 1rem;
  bottom: -8rem;
  z-index: 9;
  border-radius: 30px;
  transform-origin: bottom right;
  transition: all ease 0.2s;
  animation-delay: 1s;
  --gPercentage: var(--value);
  animation: moveGuage 2s 1 forwards;
  transform: rotate(calc(var(--gPercentage) * 1deg));
}

@keyframes moveGuage {

  0%,
  33% {
    --gPercentage: 0;
  }

  100% {
    --gPercentage: var(--value);
  }
}

.prog-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.prog {
  position: absolute;
}

@keyframes growProgressBar {

  0%,
  33% {
    --pgPercentage: 0;
  }

  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  transform: rotate(-90deg);
  --size: 16rem;
  --bg: transparent;
  --pgPercentage: var(--value);
  /* animation: growProgressBar 2s 1 forwards; */
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(closest-side,
      white 70%,
      transparent 0 99.9%,
      white 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
}

@media screen and (max-width: 1200px) {
  .guage-wrap {
    padding-left: calc(50% - 130px);
  }

  .pointer {
    width: 6.8rem;
    bottom: -8rem;
  }
}


@media screen and (max-width: 940px) {
  .speed-frame {
    width: 200px;
  }

  div[role="progressbar"] {
    --size: 12rem;
  }

  .pointer {
    width: 5rem;
    bottom: -6rem;
  }

  .guage-wrap {
    padding-left: calc(50% - 95px);
    padding-top: 10px;
    min-height: 200px;
  }

  .guage-txt-wrap {
    padding-top: 90px;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 200px;
  }
}



</style>
