<template >
  <div>
    <topnav :country="country"></topnav>
    <div class="gray-back top">
      <div class="tech-top-wrap">
        <div class="tech-top-img"><img src="../assets/tech8.jpg" /></div>
        <div class="tech-top-frame">
          <h1 class="txt54">Lorem ipsum met ctetur adipiscing elit</h1>
          <p class="cont-sub">Vulputate mi sit amet mauris commodo quis. Amet mauris commodo massa </p>
          <p class="lighter">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu cursus vitae congue mauris rhoncus aenean vel. Mattis enim ut tellu=a massa ultricies mi quis. </p>
        </div>
      </div>
    </div>
    <div class="point-wrap rev">
      <div class="point-img-wrap"><img
          src="../assets/tech2.webp"
          class="point-img"
        />
        <div class="point-frame"></div>
      </div>
      <div class="point-txt-wrap">
        <h3 class="point-head">Lorem ipsum met ctetur adipiscing elit</h3>
        <p class="lighter nomarg">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu cursus vitae congue mauris rhoncus aenean vel. Mattis enim ut tellu=a massa ultricies mi quis. </p>
      </div>
    </div>
    <div class="point-wrap">
      <div class="point-img-wrap"><img
          src="../assets/tech2.webp"
          class="point-img"
        />
        <div class="point-frame"></div>
      </div>
      <div class="point-txt-wrap">
        <h3 class="point-head">Lorem ipsum met ctetur adipiscing elit</h3>
        <p class="lighter nomarg">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu cursus vitae congue mauris rhoncus aenean vel. Mattis enim ut tellu=a massa ultricies mi quis. </p>
      </div>
    </div>

    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Technology"
};
</script>
