<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="guide-top">
      <h1 class="know-page-head">Sela Service Portal
      </h1>
      <h2 class="know-page-sub">Guide</h2>
    </div>
    <div class="guide-sec">
      <div class="guide-btns-wrap">
        <a
          class="guide-btn"
          :href="`#${chapter._id}`"
          v-for="chapter in $local.guides.contents"
          :key="chapter._id"
        >
          {{ chapter.title }}
        </a>
      </div>
      <div class="guide-content">
        <div
          v-for="chapter in $local.guides.contents"
          :key="chapter._id"
          :id="chapter._id"
        >
          <div v-html="chapter.body"></div>
        </div>
      </div>
    </div>

    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Guide",
};
</script>
