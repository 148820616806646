<template>
  <div>
    <topnav :country="country"></topnav>

    <div v-if="isPopupOpen && blog.marketingAdImage.imageURL" dir="ltr" :key="123456" class="popup">
      <div class="popup-content">
        <div @click="closePopup" class="close-icon"></div>
        <div class="popup-wrapper">
          <img :src="blog.marketingAdImage.imageURL" @click="openWhatsapp(blog.marketingAdURL)" class="popup-ad" />
        </div>
      </div>
    </div>

    <div class="know-page-top">

      <img v-if="!blog.video" :src="blog.cover.imageURL" class="know-page-img" />
      <videoEmbed class="know-page-img video" v-else :src="blog.video"></videoEmbed>
      <div class="know-top-txt-wrap">
        <h1 class="know-page-head">{{ blog.title }}</h1>
        <p class="know-page-sub">{{ blog.blurb }}</p>
        <p class="know-page-name">{{ blog.author }}</p>
      </div>
      <div class="know-page-frame"></div>
    </div>
    <div class="know-page-content" v-html="blog.body"></div>

    <botfooter  :country="country"></botfooter>
    <SocialMenu :vendor="``" :title="`${blog.title}`" :color="'white'" :size="20"
            class="social-menu" />        
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import videoEmbed from "@/components/VideoEmbed.vue";
import SocialMenu from "../components/SocialMenu.vue";

export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
    videoEmbed,
    SocialMenu
  },


  methods: {
    startPopupTimer() {
      const showPopupAfterInSeconds = 1;

      setTimeout(() => {
        this.isPopupOpen = true;
      }, showPopupAfterInSeconds * 1000);
    },
    closePopup() {
      this.isPopupOpen = !this.isPopupOpen;
      return true;
    },
    openWhatsapp(url) {
      if (url) {
        window.open(url, '_blank');
      }
    }
  },
  mounted() {
    this.startPopupTimer();
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.blog.metaTitle || this.blog.title,
        description: this.blog.metaDescription || this.blog.blurb,
        image: this.blog.cover.imageURL,
      }),
    };
  },
  computed: {
    blog() {
      const { id } = this.$route.params;
      return this.$local.blogPosts?.find((c) => c.slug == id) || {};
    },
  },
  data: () => ({
    isPopupOpen: false,
  }),
  name: "knowledgePage",
};
</script>

<style scoped>
.popup-wrapper {
  width: auto;
  padding: 0;
}

.close-icon {
  position: absolute;
  top: 50px;
  left: 13px;
}

.popup-content {
  padding: 0;
  position: relative;
}


.popup-ad {
  cursor: pointer;
}

@media screen and (max-width: 479px) {

  .close-icon {
    top: 28px;
    left: 7px;
    width: 1em;
    height: 1em;
  }

}
</style>
