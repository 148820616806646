<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="services-wrap">
      <p class="service-huge-num">01</p>
      <div class="services-top-wrap">
        <div class="service-frame">
          <div class="service-frame-txt-wrap">
             <h1 class="txt54">{{ solutionPage.title }}</h1>
          </div>
          <div class="main-circle">
            <img class="circle" src="../assets/solutionsCover/MainCircle.svg"/>
            <img class="lc yourcloud unclickable" src="../assets/solutionsCover/YourCloud.svg"/>
            <img class="lc devops" src="../assets/solutionsCover/Devops.svg"  @click="$router.push(buildPath('/solutions/devops-as-a-service'))"/>
            <img class="lc finops" src="../assets/solutionsCover/FinOps.svg"  @click="$router.push(buildPath('/solutions/finops-as-a-service'))"/>
            <img class="lc support" src="../assets/solutionsCover/Support.svg" @click="$router.push(buildPath('/solutions/monitoring-support-as-a-service'))"/>
            <img class="lc migration" src="../assets/solutionsCover/Migration.svg" @click="$router.push(buildPath('/solutions/cloud-migration'))"/>
            <img class="lc ai" src="../assets/solutionsCover/AI.svg" @click="$router.push(buildPath('/solutions/cloud-ai'))"/>
            <img class="lc architecture" src="../assets/solutionsCover/Architecture.svg" @click="$router.push(buildPath('/solutions/cloud-architecture'))"/>
            <img class="lc backup" src="../assets/solutionsCover/Backup.svg" @click="$router.push(buildPath('/solutions/backup-as-a-service'))"/>
            <img class="lc security" src="../assets/solutionsCover/Security.svg" @click="$router.push(buildPath('/solutions/security'))"/>
          </div>
        </div>
      </div>
      <div class="service-list for-links">
        <div>
          <p class="service-sub">
              {{ solutionPage.blurb }}
            </p>
        </div>
        <div
          class="service-item big-link"
          v-for="solution of $local.solutions"
          :key="solution._id"
        >
        <router-link :to="'/solutions/' + solution.slug" class="plainlink">
          <img :src="solution.icon.imageURL" :alt="solution.title" />

          <h3 class="bold nomarg">{{ solution.title }}</h3>
          <p class="big-link-small-txt">{{ solution.subtitle }}</p>
        </router-link>
          <router-link :to="buildPath('/solutions/' + solution.slug)" class="big-link-read"
            >Read More</router-link
          >
        </div>
      </div>
    </div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.solutionPage.metaTitle,
        description:
          this.solutionPage.metaDescription || this.solutionPage.blurb,
        image: this.solutionPage.cover.imageURL,
      }),
    };
  },
  methods: {
    buildPath
  },
  computed: {
    solutionPage() {
      return this.$local.solutionPage;
    },
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Services",
};
</script>

<style scoped>

@keyframes fadeInAndScale {
      0% {
        opacity: 0;
        transform: scale(0.4) rotate(-70deg);
      }
      70% {
        opacity: 1;
        transform: scale(1.1);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
}

@keyframes rotateFadeIn {
      0% {
        opacity: 0;
        transform: scale(0.3) rotate(0deg);
      }
      80% {
        opacity: 0.8;
        transform: scale(1.1) rotate(140deg);
      }    
      100% {
        opacity: 1;
        transform: scale(1) rotate(160deg);
      }
}

.main-circle {
  width: 610px;
    height: 610px;
    display: block;
    max-width: 100%;
    position: relative;
    top: 64px;
    left: 10px;
}

.main-circle .circle {
  width: 610px;
  height: 610px;
  max-width: 610px;
  object-fit: cover;
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
  animation: rotateFadeIn 1s ease-out forwards;  
}


/* little circle */
.lc { 
  position: absolute;
  opacity: 0;
  width: 154px;
  height: 154px; 
}

.lc {
  animation: fadeInAndScale 0.5s ease-in-out forwards; 
  transition: all 0.2s;
}

.lc:not(.unclickable) {
  cursor:pointer;
}

.main-circle .lc:hover:not(.unclickable) {
  width: 156px;
  height: 156px;
  filter: drop-shadow(2px 4px 6px gray);
}



.main-circle .yourcloud {
  top: 222px;
  left: 218px;
  animation-delay: 0.4s;
}

.main-circle .devops {
  top: -58px;
  left: 218px;
  animation-delay: 0.6s;
}

.main-circle .finops {
  top: 23px;
  left: 436px;
  animation-delay: 0.8s;
}

.main-circle .support {
  top: 225px;
  left: 500px;
  animation-delay: 1s;
}

.main-circle .migration {
  top: 408px;
  left: 425px;
  animation-delay: 1.1s;
}

.main-circle .ai {
  top: 508px;
  left: 216px;
  animation-delay: 1.2s;
}

.main-circle .architecture {
  top: 408px;
  left: 10px;
  animation-delay: 1.3s;
}

.main-circle .backup {
  top: 225px;
  left: -65px;
  animation-delay: 1.4s;
}

.main-circle .security {
  top: 23px;
  left: 0px;
  animation-delay: 1.5s;
}

a.plainlink, a.plainlink:link, a.plainlink:visited, a.plainlink:active {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.service-frame {
  height: 460px;
}

.service-sub {
  margin-top:270px;
  margin-bottom: 50px;
}


.service-item.big-link {
  position: relative;
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  background: white;
  transition: all 200ms ease;
  cursor: pointer;
  min-height: 70px;
  text-align: center;
  margin: 25px 16px;
  flex-basis: calc(33.33% - 32px);
  padding: 10px;
}

.big-link .bold.nomarg {
  min-height: 52px;
  margin-bottom: -10px;
}

.service-list.for-links {
  max-width: 1100px;
  width: 90%;
  justify-content: center;
}

.service-item.big-link img {
  height: 70px;
  margin: 10px auto;
}

.big-link-small-txt {
  font-size: 18px;
  line-height: 1.5;
}

.big-link-read {
  margin: -5px auto 0;
  position: relative;
}



@media screen and (max-width: 1300px) {

  .service-frame-txt-wrap {
    margin-bottom: 30px;
  }

  .service-sub {
    margin-top: 10px;
  }
  .service-frame {
    flex-direction: column;
    margin-right: auto;
    height: auto;
    padding-bottom: 180px;
  }


  .service-list {
    flex-direction: column;
    padding-top: 15px;
  }  

  .main-circle {
    left: 50%;
    transform: translateX(-50%);
  }
}



@media (max-width: 900px) {

    .service-frame-txt-wrap {
        width:300px;
    }

    .main-circle {
        width: 300px;
        height: 300px;
        display: block;
        max-width: 100%;
        position: relative;
        top: 42px;
        left: 50%;
        transform: translateX(-50%);
    }

    .main-circle .circle {
      width: 234px;
      height: 234px;
      max-width: 300px;
      object-fit: cover;
      position: absolute;
      top: 24px;
      left: 31px;
      opacity: 0;
      animation: rotateFadeIn 1s ease-out forwards;  
    }

    .lc { 
      position: absolute;
      opacity: 0;
      width: 75px;
      height: 75px;  
    }

    .main-circle .yourcloud {
      top: 104px;
      left: 113px;
    }

    .main-circle .devops {
      top: -21px;
      left: 110px;
    }

    .main-circle .finops {
      top: 14px;
      left: 193px;
    }

    .main-circle .support {
      top: 97px;
      left: 223px;
    }

    .main-circle .migration {
      top: 179px;
      left: 191px;
    }

    .main-circle .ai {
      top: 218px;
      left: 110px;
    }

    .main-circle .architecture {
      top: 177px;
      left: 27px;
    }

    .main-circle .backup {
      top: 100px;
      left: -1px;
    }

    .main-circle .security {
      top: 14px;
      left: 27px;
    }

}


@media screen and (max-width: 767px) { 
  .service-list {
    padding-top: 40px;
  }  

  .service-frame {
    padding: 5px 20px 60px;  
  }
}

@media screen and (max-width: 400px) { 
  .service-frame-txt-wrap {
    width: 290px;
  }
}


@media (max-width: 360px) {
  .main-circle {
      left: 46%;
  }
}

</style>
