<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="w1170 pt30px">
      
      <div class="customers-page-side-wrap">
        <img
          :src="customer.logo.imageURL"
          :alt="customer.name"
          class="customers-page-logo"
        />
        <h1 class="customers-page-head">{{ customer.name }}</h1>
        <div
          class="customers-page-side"
          v-html="customer.blurb"
        ></div>
      </div>
      <div class="customers-page-content">
        <div class="bread-crumbs">
          <div>{{ customer.technology && customer.technology.value }}</div>
          <div>/</div>
          <router-link :to="buildPath('/customers')"> Customer Stories</router-link>
          <div>/</div>
          <p>{{ customer.name }}</p>
        </div>
        <div v-html="customer.story"></div>

      </div>
    </div>

    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  components: {
    Topnav,
    Botfooter,
  },
  methods: {
    buildPath
  },
  computed: {
    customer() {
      const { slug } = this.$route.params;
      return this.$local.customers?.find((c) => c.slug == slug) || {};
    },
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.customer.metaTitle || this.customer.name,
        description:
          this.customer.metaDescription,
      }),
    };
  },
  name: "knowledgePage",
};
</script>
