<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="gray-back top">
      <div class="tech-top-wrap">
        <div class="tech-top-img">
          <img
            v-if="deliveryPage.cover && deliveryPage.cover.imageURL"
            :src="deliveryPage.cover.imageURL"
          />
          <img v-else src="../assets/tech8.jpg" />
        </div>
        <div :class="'tech-top-frame blue-line'">
          <h1 class="txt54">{{ deliveryPage.title }}</h1>
          <div class="lighter" v-html="deliveryPage.blurb"></div>
        </div>

        <div class="solution-content-richtxt delivery" v-html="deliveryPage.body"></div>

        <div class="solutions-schedule-call-wrapper">
            <h2 v-if="deliveryPage.footerParagraph" v-html="deliveryPage.footerParagraph"></h2>
            <a v-if="deliveryPage.footerButton" class="btn lowcase" href="contact"> {{ deliveryPage.footerButton }}</a>
        </div>

      </div>
    </div>

    <div class="sol-end-sec">
      <div class="sol-frame border-blue"></div>
      <p class="txt40 bold">Leaders Cloud Better</p>
      <p class="txt40">want to get started?</p>
      <router-link :to="buildPath('contact')" type="button" class="btn mt30px"
        >Talk to an expert
      </router-link>
      <div class="sol-frame border-blue bot"></div>
    </div>

    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";

export default {
  props: {
    country:String
  },
  methods : {
    buildPath
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.deliveryPage.metaTitle || this.deliveryPage.title,
        description: this.deliveryPage.metaDescription || this.deliveryPage.subTitle,
        image: this.deliveryPage.cover.imageURL,
      }),
    };
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Delivery Page",
  computed: {
    deliveryPage() {
      const { slug } = this.$route.params;
      return this.$local.deliveryPages.find((s) => s.slug === slug);
    },
  },
};
</script>

<style scoped>
.solution-content-richtxt {
  max-width: 1000px;
  margin: 50px auto 70px;
  text-align: center;
}

.solution-content-richtxt blockquote h2 {
  max-width: 700px;
  margin: 50px auto 20px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.3;
}

.solution-content-richtxt.delivery h3 {
  text-align: left;
  clip-path: polygon(
    0 100%,
    100% 100%,
    100% 85%,
    95% 85%,
    95% 15%,
    100% 15%,
    100% 0,
    0 0
  ); 
  border: var(--dark-blue) 14px solid;
  padding: 30px;
  font-weight: 600;
  font-size: 40px;
  width: 380px;
  line-height: 1.3;
  float: left;
  margin-top: 0;
}

.solution-content-richtxt h4 {
  text-align: left;
  margin: 0px 0 10px 420px;
  line-height: 1.3;
  font-size: 24px;
}

.solution-content-richtxt blockquote {
  max-width: 700px;
  margin: 0px auto -20px;
  font-weight: 400;
}

.solution-content-richtxt p {
  text-align: left;
  margin: 0 0 0 420px;
  padding-bottom: 34px;
}

.solutions-schedule-call-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 6rem auto;
}

.solutions-schedule-call-wrapper  h2 {
  text-align: center;
}

.solutions-schedule-call-wrapper .btn {
  max-width: 300px;
  margin-top: 20px;
}

@media screen and (max-width: 991px) {
  .solution-content-richtxt blockquote h2 {
    font-size: 30px;
  }

  .solution-content-richtxt h4 {
    margin: 30px 0 10px 0px;
  }

  .solution-content-richtxt h5 {
    margin: 0px auto 50px;
  }

  .solution-content-richtxt.delivery h3 {
    float: unset;
    text-align: center;
    padding: 25px 0;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    clip-path: polygon(
      100% 85%,
      95% 85%,
      95% 15%,
      100% 15%,
      100% 0%,
      0% 0%,
      0% 15%,
      5% 15%,
      5% 85%,
      0% 85%,
      0% 100%,
      100% 100%
    )
  }

  .solution-content-richtxt p {
    margin: 0px;
    display: block;
  }
}
</style>
