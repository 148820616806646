<template>
    <router-link :to="to" v-if="!extLink">
        <slot></slot>
    </router-link>
    <a :href="to" target="_blank" v-else>
        <slot></slot>
    </a>
</template>

<script>
    export default {
        name:'link-path',
        props:{
            to: String
        },
        computed:{
            extLink(){
                return !!this.to.match('^http[s]?:|^//')
            }
        }
    }
</script>